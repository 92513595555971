// Imports
import { useState, useEffect } from 'react';
import { Routes, Route } from 'react-router';
import { BrowserRouter } from 'react-router-dom';
// Components

// Pages
import Home from './pages/Home'
import SignDocument from './pages/SignDocument';
import Document1 from './pages/Document1';
import Upload from './pages/upload';
// Styles
import './App.css';

function App() {

  const [signature, setSignature] = useState('')

  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route exact path='/' element={<Home />} />
          <Route path='/sign-document/:id' element={<SignDocument signature={signature} setSignature={setSignature} />} />
          <Route path='/document' element={<Document1 signature={signature} />} />
          <Route path='/upload' element={<Upload />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App
