import { useState, useEffect } from 'react'
import Header from '../components/Header';
import axios from 'axios'
import jsPdf from 'jspdf'
// styles
import Button from 'react-bootstrap/Button'
import FinancialService from '../../src/images/MF_Financial_Services.jpeg';
import SAArmyLogo from '../assets/images/SA_Army.png'
import ShieldLogo from '../assets/images/shield_life.png'
import FuneralHeader from '../components/funeralHeader';
import { Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const Document1 = ({
  signature
}) => {
  const date = Date.now()
  const d = new Date(parseInt(date, 10))
  const timeStamp = d.toString('MM/dd/yy HH:mm:ss')
  const now = new Date();
  let day = now.getDate();
  let monthNo = now.getMonth() + 1;
  let yearNow = now.getFullYear();
  let navigate = useNavigate();

  const [memberType, setMemberType] = useState('Single')
  const [approved, setApproved] = useState(false)
  const [data, setData] = useState([])
  const [spouceCover, setSpouseCover] = useState([]);
  const [memberCover, setMemberCover] = useState([]);
  const [isLoading, setIsLoading] = useState();
  const [isFuneralData, setIsFuneralData] = useState(false);
  const [funeralData, setFuneralData] = useState();
  const [shieldData, setShieldData] = useState();
  const [base64Data, setBase64Data] = useState();

  const month = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

  useEffect(() => {
    if (localStorage.getItem('funeralUserData')) {
      let funeralData = JSON.parse(localStorage.getItem('funeralUserData'));
      setFuneralData(funeralData);
      setIsFuneralData(true);
      if (funeralData.extendedFamily) {
        let spouseData = funeralData.extendedFamily.filter(item => item.memberRelation === 'spouse');
        let memberData = funeralData.extendedFamily.filter(item => item.memberRelation !== 'spouse');
        setSpouseCover(spouseData); setMemberCover(memberData);
      }
    } else if (localStorage.getItem('shieldUserData')) {
      setIsFuneralData(false);
      setShieldData(JSON.parse(localStorage.getItem('shieldUserData')));
    }
  }, [])

  // type here can either be download or post
  const pdfPayrollDeductionScript = async (type) => {

    var doc = new jsPdf({ compress: true })

    if (isFuneralData && funeralData) {
      var pageline = 20;
      let code;
      if (funeralData.representativeData && funeralData.representativeData.representativeCode) code = funeralData.representativeData.representativeCode.toLowerCase();
      doc.setFont("Helvetica", "normal");
      doc.setTextColor("#444");
      doc.setFontSize(9);
      doc.text('Shield Life - FSP 47477', 5, 5);
      doc.addImage(ShieldLogo, "PNG", 10, 5, 25, 25, "logo", "MEDIUM");
      if (!code || (!code.match('sll') && !code.match('tsll'))) {
        doc.text('Maxi Forces Financial Services - FSP 1966', 145, 5);
        doc.addImage(FinancialService, "JPEG", 170, 8, 30, 20, "finaceLogo", "MEDIUM");
      }
      doc.setFont("Helvetica", "bold");
      doc.setTextColor("#444");
      doc.setFontSize(16);
      doc.text("Individual Funeral Cover", 75, pageline + 15);
      doc.text("Application Form", 85, pageline + 25);
      if (funeralData.representativeData && funeralData.representativeData.representativeCode) {
        doc.setFontSize(10);
        doc.text("Representative Code:", 145, pageline + 25);
        doc.setFont("Helvetica", "normal");
        doc.text(funeralData.representativeData.representativeCode, 182, pageline + 25);
      }
      doc.setFontSize(9);
      doc.setFont("Helvetica", "bold");
      doc.setTextColor("red");
      doc.text("Please Note:", 15, pageline + 35);
      doc.setTextColor("#444");
      doc.text("In terms of the Financial Intelligence Centre Amendment Act 1 of 2017 and to improve the on-boarding process, we", 15, pageline + 40);
      doc.text("require a copy of the following documentation to accompany this application:", 15, pageline + 45);
      doc.setFont("Helvetica", "normal");
      doc.text("Identification of the applicant (ID book/card or Driver License)", 20, pageline + 50);
      // doc.text("Proof of banking details not older than 3 months (if Debit Order)", 20, pageline + 55);
      doc.setFont("Helvetica", "italic", "bold");
      doc.text("To escalate the claim process you can also supply the following documentation (where applicable), however if not", 15, pageline + 60);
      doc.text("available, it must be submitted on claims stage:", 15, pageline + 65);
      doc.setFont("Helvetica", "bold");
      if (funeralData.beneficiaryIDNo) doc.text(funeralData.beneficiaryIDNo, 135, pageline + 70);
      doc.setFont("Helvetica", "normal");
      doc.text("Identification of the nominated beneficiary/ies (ID book/card or Driver License);", 20, pageline + 70);
      doc.text("Identification of each spouse covered under this application (ID book/card or Driver License);", 20, pageline + 75);
      doc.text("Marriage Certificate or equivalent;", 20, pageline + 80);
      doc.text("Identification of each dependent child covered under this application (ID book/card or Birth Certificate);", 20, pageline + 85);
      doc.text("Identification of each extended family member covered under this application (ID book/card; Driver License or Birth", 20, pageline + 90);
      doc.text("Certificate)", 20, pageline + 95);

      pageline = 20;
      //POPI & CONSENT
      doc.setFont("Helvetica", "bold");
      doc.setFontSize(12);
      doc.text("1. Legislation information and requirements:", 15, pageline + 105);
      doc.setFont("Helvetica", "normal");
      doc.setFontSize(9);
      doc.text("This policy is governed by South African Law and any legal action in relation to this policy is subject to the jurisdiction of the ", 15, pageline + 110);
      doc.text("South African Courts.", 15, pageline + 115);

      doc.setTextColor("4068a1")
      doc.setFont("Helvetica", "italic", "bold");
      doc.text("1.1 Personal Information", 15, pageline + 120);
      doc.setTextColor("#444");
      doc.setFont("Helvetica", "normal");
      doc.text("In South Africa, the Protection of Personal Information Act 4 of 2013 (PoPIA) has been enacted to regulate the processing of", 15, pageline + 125);
      doc.text("Personal Information. When entering into this policy you provide us with information that may be protected by legislation includ", 15, pageline + 130);
      doc.text("ing but not limited to PoPIA. We will take all reasonable steps to protect the information you provide us securely and confident", 15, pageline + 135);
      doc.text("ially. Please read more how we use and protect your personal information in our Privacy Notice and PAIA manual available on", 15, pageline + 140);
      doc.text("our website https://www.shieldlife.co.za.", 15, pageline + 145);
      doc.setTextColor("4068a1")
      doc.setFont("Helvetica", "italic", "bold");
      // pageline = pageline - 5;
      doc.text("1.2 Sharing of Insurance Information", 15, pageline + 150);
      doc.setFont("Helvetica", "normal");
      doc.setTextColor("#444");
      doc.text("The South African Regulatory bodies require insurers to share information with the regulatory body regarding policies and claim", 15, pageline + 155);
      doc.text("In addition, insurers share information with each other regarding policies and claims with the view to prevent fraudulent claims", 15, pageline + 160);
      doc.text("and obtain material information regarding the assessment of risks proposed for insurance. By reducing the incidents of fraud", 15, pageline + 165);
      doc.text("and assessing risks fairly, future premium increases may be limited.  This is done in the public interest and in the interest of all", 15, pageline + 170);
      doc.text("current and potential policyholders.", 15, pageline + 175);
      doc.text("By the insurer accepting or renewing this insurance, you or any other person that is represented herein, gives consent to the", 15, pageline + 180);
      doc.text("said information being disclosed to any other insurance company or its agent.You also similarly give consent to the sharing of ", 15, pageline + 185);
      doc.text("information in regards to past insurance policies and claims that you have made.You also acknowledge that information provid", 15, pageline + 190);
      doc.text("ed by yourself or your representative may be verified against any legally recognised sources or databases.", 15, pageline + 195);
      doc.text("By insuring or renewing your insurance you hereby not only consent to such information sharing, but also waive any rights of", 15, pageline + 200);
      doc.text("confidentiality with regards to underwriting or claims information that you have provided or that has been provided by another", 15, pageline + 205);
      doc.text("person on your behalf. In the event of a claim, the information you have supplied with your application, together with the inform", 15, pageline + 210);
      doc.text("ation you supply in relation to the claim, will be made available to other insurers participating in the sharing of insurance inform", 15, pageline + 215);
      doc.text("ation. As a member of Shield Life's funeral plan, you, your dependants and extended family's personal information may be sha", 15, pageline + 220);
      doc.text("red with the employer. This will be limited to information that is relevant to you and/or your dependants’ and/or your extended's", 15, pageline + 225);
      doc.text("application or information that is required for the ongoing servicing of your funeral plan.", 15, pageline + 230);

      pageline = 15;
      doc.setTextColor("4068a1")
      doc.setFont("Helvetica", "italic", "bold");
      doc.text("1.3 Client Due Diligence", 15, pageline + 240);
      doc.setFont("Helvetica", "normal");
      doc.setTextColor("#444");
      doc.text("As part of our responsibilities as an Accountable Institution in terms of the Financial Intelligence Centre Amendment Act 1 of", 15, pageline + 245);
      doc.text("2017, we are required to gather the following member information prior to concluding the transaction.", 15, pageline + 250);
      doc.text("The nature and purpose of this transaction is to secure Group Life and Funeral cover.", 15, pageline + 255);
      doc.text("What is the source of Funds?", 15, pageline + 260);
      doc.setFont("Helvetica", "bold");
      if (funeralData.sourceIncome) doc.text(funeralData.sourceIncome, 70, pageline + 260);
      doc.text("If Other, specify:", 100, pageline + 260);
      doc.setFont("Helvetica", "normal");
      if (funeralData.sourceIncome) doc.text(funeralData.sourceIncomeDetails, 130, pageline + 260);
      doc.text("____________________________", 130, pageline + 262)

      doc.setTextColor("4068a1")
      doc.setFont("Helvetica", "italic", "bold");
      doc.text("1.4 Consent and declaration", 15, pageline + 267);
      doc.setFont("Helvetica", "normal");
      doc.setTextColor("#444");
      doc.setFont("Helvetica", "normal");
      doc.text("I, the Policy Holder of this policy, hereby give consent to the Processing of my Personal Information in terms of POPIA for all", 15, pageline + 272);
      doc.text("purposes of maintaining this insurance product and consent to the terms of the Privacy Notice and the PAIA manual located on", 15, pageline + 277);

      doc.addPage();
      doc.setTextColor("#444");
      doc.setFont("Helvetica", "normal");
      pageline = 10;
      doc.text("our website https://www.shieldlife.co.za. I understand that the supply of this information is mandatory and without the supply of", 15, pageline);
      doc.text("the information this agreement will be will be invalid. Shield Life will process and protect my personal information in terms of the", 15, pageline + 5);
      doc.text("required aspects of POPIA.", 15, pageline + 10);
      pageline = pageline + 5;
      doc.text("I hereby give consent that Shield Life may request further processing of my personal information from a third party (Premium", 15, pageline + 10);
      doc.text("collection agency, Credit bureau etc.) for the purposes of maintaining my insurance product. I hereby agree that I have the appr", 15, pageline + 15);
      doc.text("opriate authorization of supplying special / sensitive / personal information of data subjects other than myself.", 15, pageline + 20);
      doc.text("I hereby agree that all the information supplied in this agreement is accurate and complete and should any of this information", 15, pageline + 25);
      doc.text("change I will contact Shield Life Ltd to update my personal information.", 15, pageline + 30);
      if (!code || (!code.match('sll') && !code.match('tsll'))) {
        doc.text("I hereby consent that my personal information may be used for marketing purposes with the Maxi Forces Financial Services", 15, pageline + 35);
        doc.text("and Shield Life Insurance Product range.", 15, pageline + 40);
      } else {
        doc.text("I hereby consent that my personal information may be used for marketing purposes within the Shield Life Insurance Product", 15, pageline + 35);
        doc.text("range.", 15, pageline + 40);
      }
      doc.setFont("Helvetica", "bold");
      doc.setFillColor("4068a1");
      if ((funeralData.addInfo && funeralData.addInfo.sendInfo === "yes") || funeralData.sendInfo === "yes") doc.rect(160, pageline + 37, 4, 4, "FD");
      else doc.rect(160, pageline + 37, 4, 4);
      doc.text('Yes', 165, pageline + 40);
      doc.setFillColor("4068a1");
      if ((funeralData.addInfo && funeralData.addInfo.sendInfo === "no") || funeralData.sendInfo === "no") doc.rect(175, pageline + 37, 4, 4, "FD");
      else doc.rect(175, pageline + 37, 4, 4);
      doc.text('No', 180, pageline + 40);
      doc.setFont("Helvetica", "normal");
      doc.text("I, the undersigned, understand that without the above consent my policy will not be processed and Shield Life will not be liable", 15, pageline + 45);
      doc.text("for any claim that may arise. I understand that the cover will only start when my application has been accepted and I have paid", 15, pageline + 50);
      doc.text("the first premium. I authorise the Insurer to communicate with me regarding my policy via Short Message System (SMS); Whats", 15, pageline + 55);
      doc.text("App; and/or email. I, the undersigned, declare that the source of the funds that I expect to use in concluding transactions with", 15, pageline + 60);
      doc.text("Shield Life® Ltd originates from the sources indicated above. I further confirm that these funds are derived from legitimate sources.", 15, pageline + 65);

      if (signature) doc.addImage(signature, "JPEG", 25, pageline + 55, 35, 35, "final", "MEDIUM");
      doc.text("____________________________", 20, pageline + 80);
      doc.setFont("Helvetica", "italic", "bold");
      doc.text("Signature of Main Member", 25, pageline + 85);
      if (funeralData.representativeData && funeralData.representativeData.representativeCode) doc.text("Representative Code:" + funeralData.representativeData.representativeCode, 72, pageline + 75);
      doc.text(`Date: ${timeStamp}`, 72, pageline + 80);

      // PERSONAL INFORMATION
      // doc.addPage();
      pageline = 10;
      doc.setFont("Helvetica", "bold");
      doc.setFontSize(12);
      doc.text("2. Compulsory personal information of Main Insured Life:", 15, pageline + 100);
      doc.setFontSize(9);
      doc.setTextColor("4068a1")
      doc.setFont("Helvetica", "italic", "bold");
      doc.text("2.1. General Details:", 15, pageline + 105);
      doc.setTextColor("#444");
      doc.setFont("Helvetica", "bold");
      doc.text("Rank / Title:", 15, pageline + 111);
      doc.rect(45, pageline + 107, 55, 5, "S");
      doc.text("Full Names:", 15, pageline + 116);
      doc.rect(45, pageline + 112, 55, 5, "S");
      doc.text("ID Number:", 102, pageline + 111);
      doc.rect(135, pageline + 107, 60, 5, "S");
      doc.text("Surname:", 102, pageline + 116);
      doc.rect(135, pageline + 112, 60, 5, "S");
      doc.setFont("Helvetica", "normal");
      doc.text(funeralData.rankTitle, 48, pageline + 111);
      doc.text(funeralData.fullNames, 48, pageline + 116);
      doc.text(funeralData.IDNumber, 138, pageline + 111);
      doc.text(funeralData.surname, 138, pageline + 116);

      pageline = pageline - 9;
      doc.setFont("Helvetica", "bold");
      doc.text("Cell Number:", 15, pageline + 130);
      doc.rect(45, pageline + 126, 55, 5, "S");
      doc.text("Email:", 15, pageline + 135);
      doc.rect(45, pageline + 131, 55, 5, "S");

      doc.text("Alternative Number:", 102, pageline + 130);
      doc.rect(135, pageline + 126, 60, 5, "S");
      doc.setFont("Helvetica", "normal");
      doc.text(funeralData.cellNumber, 48, pageline + 130);
      if (funeralData.alternativeNo) doc.text(funeralData.alternativeNo, 138, pageline + 130);
      doc.text(funeralData.emailAddress, 48, pageline + 135);

      pageline = pageline + 10;
      doc.setFont("Helvetica", "bold");
      doc.setFontSize(9);
      doc.setFillColor("4068a1");
      doc.rect(15, pageline + 130, 40, 5, "FD");
      doc.rect(55, pageline + 130, 50, 5, "FD");
      doc.rect(105, pageline + 130, 30, 5, "FD");
      doc.rect(135, pageline + 130, 30, 5, "FD");
      doc.rect(165, pageline + 130, 30, 5, "FD");

      doc.setTextColor(255, 255, 255);
      doc.text("Address", 28, pageline + 134);
      doc.text("Street and/or Number", 65, pageline + 134);
      doc.text("Suburb", 115, pageline + 134);
      doc.text("City", 147, pageline + 134);
      doc.text("Code", 176, pageline + 134);
      doc.setTextColor("#444");
      doc.setFont("Helvetica", "normal");
      doc.rect(15, pageline + 135, 40, 5, "S");
      doc.text("Physical Address", 16, pageline + 139);
      // doc.rect(15, pageline + 140, 40, 5, "S");
      // doc.text("Postal Address", 16, pageline + 144);

      doc.rect(55, pageline + 135, 50, 5, "S");
      doc.rect(105, pageline + 135, 30, 5, "S");
      doc.rect(135, pageline + 135, 30, 5, "S");
      doc.rect(165, pageline + 135, 30, 5, "S");
      if (funeralData.streetName) doc.text(funeralData.streetName, 58, pageline + 139);
      if (funeralData.suburb) doc.text(funeralData.suburb, 108, pageline + 139);
      if (funeralData.town) doc.text(funeralData.town, 138, pageline + 139);
      if (funeralData.postalCode) doc.text(funeralData.postalCode, 168, pageline + 139);

      pageline = pageline + 12;
      doc.setFont("Helvetica", "bold");
      doc.text("Contact method:", 15, pageline + 157);
      doc.setFont("Helvetica", "normal");
      doc.setFillColor("4068a1");
      if (funeralData.contactMethod.email) doc.rect(45, pageline + 154, 4, 4, "FD");
      else doc.rect(45, pageline + 154, 4, 4);
      doc.text('Email', 51, pageline + 157);
      doc.setFillColor("4068a1");
      if (funeralData.contactMethod.sms) doc.rect(75, pageline + 154, 4, 4, "FD");
      else doc.rect(75, pageline + 154, 4, 4);
      doc.text('SMS', 81, pageline + 157);
      doc.setFillColor("4068a1");
      if (funeralData.contactMethod.cellphone) doc.rect(105, pageline + 154, 4, 4, "FD");
      else doc.rect(105, pageline + 154, 4, 4);;
      doc.text('Cellphone', 111, pageline + 157);

      pageline = -10;
      doc.setTextColor("4068a1")
      doc.setFont("Helvetica", "italic", "bold");
      doc.text("2.2. Employer Details:", 15, pageline + 168);
      doc.setTextColor("#444");
      doc.text("Employment sector:", 15, pageline + 173);
      doc.setFont("Helvetica", "normal");
      doc.setFillColor("4068a1");
      if (funeralData.employmentSector && funeralData.employmentSector === 'Private') doc.rect(50, pageline + 170, 4, 4, "FD");
      else doc.rect(50, pageline + 170, 4, 4);
      doc.text('Private', 55, pageline + 173);
      doc.setFillColor("4068a1");
      if (funeralData.employmentSector && funeralData.employmentSector === 'DOD') doc.rect(75, pageline + 170, 4, 4, "FD");
      else doc.rect(75, pageline + 170, 4, 4);
      doc.text('DOD', 81, pageline + 173);
      doc.setFillColor("4068a1");
      if (funeralData.employmentSector && funeralData.employmentSector === 'Government & SOE') doc.rect(105, pageline + 170, 4, 4, "FD");
      else doc.rect(105, pageline + 170, 4, 4);
      doc.text('Government & SOE', 111, pageline + 173);

      doc.setFont("Helvetica", "bold");
      doc.text("Name of Employer:", 15, pageline + 178);
      doc.text("Force / Employee No:", 15, pageline + 183);
      doc.rect(50, pageline + 175, 55, 5, "S");
      doc.rect(50, pageline + 180, 55, 5, "S");
      doc.text("Business Tel:", 107, pageline + 178);
      doc.text("Job Title:", 107, pageline + 183);
      doc.rect(135, pageline + 175, 60, 5, "S");
      doc.rect(135, pageline + 180, 60, 5, "S");

      doc.setFont("Helvetica", "normal");
      if (funeralData.employerName) doc.text(funeralData.employerName, 53, pageline + 179);
      if (funeralData.employeeNo) doc.text(funeralData.employeeNo, 53, pageline + 184);
      if (funeralData.businessTel) doc.text(funeralData.businessTel, 138, pageline + 179);
      if (funeralData.jobTitle) doc.text(funeralData.jobTitle, 138, pageline + 184);

      // BENEFICIARY INFORMATION
      pageline = pageline + 2;
      doc.setTextColor("4068a1")
      doc.setFont("Helvetica", "italic", "bold");
      doc.text("2.3 Details of Beneficiary:", 15, pageline + 195);
      doc.setFont("Helvetica", "bold");
      doc.setTextColor("#444");
      doc.text("A Beneficiary must be an adult over the age of 18. It is the person responsible for the funeral", 15, pageline + 200);
      pageline = pageline - 3;
      doc.setFont("Helvetica", "bold");
      doc.setFillColor("4068a1");
      doc.rect(15, pageline + 205, 40, 5, "FD");
      doc.rect(55, pageline + 205, 50, 5, "FD");
      doc.rect(105, pageline + 205, 30, 5, "FD");
      doc.rect(135, pageline + 205, 30, 5, "FD");
      doc.rect(165, pageline + 205, 30, 5, "FD");

      doc.setTextColor(255, 255, 255);
      doc.text("Beneficiary", 25, pageline + 209);
      doc.text("Full name & surname", 65, pageline + 209);
      doc.text("ID number", 112, pageline + 209);
      doc.text("Contact number", 138, pageline + 209);
      doc.text("Relationship", 170, pageline + 209);

      doc.setFont("Helvetica", "normal");
      doc.setTextColor("#444");
      doc.rect(15, pageline + 210, 40, 5, "S");
      doc.text("Primary", 16, pageline + 214);
      doc.rect(15, pageline + 215, 40, 5, "S");
      doc.text("Secondary", 16, pageline + 219);

      doc.rect(55, pageline + 210, 50, 5, "S");
      doc.rect(105, pageline + 210, 30, 5, "S");
      doc.rect(135, pageline + 210, 30, 5, "S");
      doc.rect(165, pageline + 210, 30, 5, "S");
      doc.rect(55, pageline + 215, 50, 5, "S");
      doc.rect(105, pageline + 215, 30, 5, "S");
      doc.rect(135, pageline + 215, 30, 5, "S");
      doc.rect(165, pageline + 215, 30, 5, "S");
      if (funeralData.beneficiaryName && funeralData.beneficiarySurname) doc.text(funeralData.beneficiaryName + ' ' + funeralData.beneficiarySurname, 58, pageline + 214);
      if (funeralData.beneficiaryIDNo) doc.text(funeralData.beneficiaryIDNo, 108, pageline + 214);
      if (funeralData.beneficiaryCellNumber) doc.text(funeralData.beneficiaryCellNumber, 138, pageline + 214);
      if (funeralData.beneficiaryRelationship) doc.text(funeralData.beneficiaryRelationship, 168, pageline + 214);
      if (funeralData.beneficiarySecondaryName) doc.text(funeralData.beneficiarySecondaryName + ' ' + funeralData.beneficiarySecondarySurname, 58, pageline + 219);
      if (funeralData.beneficiarySecondaryIDNo) doc.text(funeralData.beneficiarySecondaryIDNo, 108, pageline + 219);
      if (funeralData.beneficiarySecondaryCellNumber) doc.text(funeralData.beneficiarySecondaryCellNumber, 138, pageline + 219);
      if (funeralData.beneficiarySecondaryRelationship) doc.text(funeralData.beneficiarySecondaryRelationship, 168, pageline + 219);
      doc.setFont("Helvetica", "italic");
      doc.text("Please note: The next-in-line-beneficiary will only receive the benefit payment if the primary beneficiary predeceased", 15, pageline + 225);

      pageline = pageline + 235;
      doc.setFont("Helvetica", "Bold");
      doc.setFontSize(14);
      doc.text("3. Personal information of Additional Insured Life:", 15, pageline);
      doc.setTextColor("4068a1");
      doc.setFontSize(9);
      doc.setFont("Helvetica", "italic", "bold");
      doc.text("3.1 Details of Immediate Family:", 15, pageline + 5);
      doc.setFont("Helvetica", "normal");
      doc.setTextColor("#444");
      doc.text("You can add Three (3) spouses and up to Five (5) own dependent children under the age of 21 years as immediate family.", 15, pageline + 10);
      doc.text("Spouse means the person to whom you are legally married under the law of South Africa (including a civil, customary or", 15, pageline + 15);
      doc.text("same-sex marriage) or the person you have been living with for at least six (6) months in a relationship that is similar to marriage,", 15, pageline + 20);
      doc.text("and intended to be permanent like a marriage", 15, pageline + 25);
      doc.text("Own dependent children means, biological, stepchildren or legally-adopted children.", 15, pageline + 30);
      pageline = pageline + 25;
      doc.setFillColor("4068a1");
      doc.rect(15, pageline + 8, 30, 5, "FD");
      doc.rect(45, pageline + 8, 50, 5, "FD");
      doc.rect(95, pageline + 8, 30, 5, "FD");
      doc.rect(125, pageline + 8, 40, 5, "FD");
      doc.rect(165, pageline + 8, 30, 5, "FD");

      doc.setFont("Helvetica", "bold");
      doc.setTextColor(255, 255, 255);
      doc.text("Relationship", 20, pageline + 12);
      doc.text("Full name & surname", 55, pageline + 12);
      doc.text("ID no/Date of Birth", 96, pageline + 12);
      doc.text("Gender", 140, pageline + 12);
      doc.text("Age", 175, pageline + 12);
      doc.setTextColor("#444");
      doc.setFont("Helvetica", "normal");

      let rectLine = pageline + 8;
      let textLine = pageline + 12;

      if (spouceCover.length > 0) {
        for (let s = 0; s < spouceCover.length; s++) {
          rectLine = rectLine + 5;
          textLine = textLine + 5;
          doc.rect(15, rectLine, 30, 5, "S");
          doc.text("Spouse", 17, textLine);
          doc.rect(45, rectLine, 50, 5, "S");
          doc.text(spouceCover[s].name + ' ' + spouceCover[s].surname, 47, textLine);
          doc.rect(95, rectLine, 30, 5, "S");
          doc.text(spouceCover[s].dateOfBirth, 100, textLine);
          doc.rect(125, rectLine, 40, 5, "S");
          doc.setFillColor("4068a1");
          if (spouceCover[s].gender === "male") doc.rect(131, rectLine + 1, 3, 3, "FD");
          else doc.rect(131, rectLine + 1, 3, 3);
          doc.text('Male', 135, rectLine + 4);
          doc.setFillColor("4068a1");
          if (spouceCover[s].gender === "female") doc.rect(146, rectLine + 1, 3, 3, "FD");
          else doc.rect(146, rectLine + 1, 3, 3);
          doc.text('Female', 150, rectLine + 4);
          doc.rect(165, rectLine, 30, 5, "S");
          doc.text(String(spouceCover[s].age), 176, textLine);
          pageline = textLine;
        }
      }

      // EXTENDED FAMILY
      doc.addPage();
      pageline = 0;
      doc.setTextColor("4068a1");
      doc.setFont("Helvetica", "italic", "bold");
      doc.text("3.2 Details of Extended Family:", 15, pageline + 10);
      doc.setFont("Helvetica", "normal");
      doc.setTextColor("#444");
      // doc.text("You can add ???? as extended family 6 members under the age of 75 years.", 15, pageline + 15);
      pageline = pageline - 5
      doc.text("8 Extended family means any person in whom you have an insurable interest.", 15, pageline + 20);
      pageline = pageline - 3;
      doc.setFillColor("4068a1");
      doc.rect(15, pageline + 25, 30, 5, "FD");
      doc.rect(45, pageline + 25, 50, 5, "FD");
      doc.rect(95, pageline + 25, 30, 5, "FD");
      doc.rect(125, pageline + 25, 40, 5, "FD");
      doc.rect(165, pageline + 25, 30, 5, "FD");
      doc.setFont("Helvetica", "bold");
      doc.setTextColor(255, 255, 255);
      doc.text("Relationship", 20, pageline + 29);
      doc.text("Full name & surname", 55, pageline + 29);
      doc.text("ID no/Date of Birth", 96, pageline + 29);
      doc.text("Sub Relationship", 132, pageline + 29);
      doc.text("Age", 175, pageline + 29);
      doc.setTextColor("#444");
      doc.setFont("Helvetica", "normal");

      let rectLines = pageline + 25;
      let textLines = pageline + 29;

      if (memberCover.length > 0) {
        for (let s = 0; s < memberCover.length; s++) {
          rectLines = rectLines + 5;
          textLines = textLines + 5;
          doc.rect(15, rectLines, 30, 5, "S");
          if (memberCover[s].memberRelation === 'ownChildren') doc.text("Own Children", 17, textLines);
          if (memberCover[s].memberRelation === 'parents') doc.text("Parents", 17, textLines);
          if (memberCover[s].memberRelation === 'formerSpouse') doc.text("Former Spouse", 17, textLines);
          if (memberCover[s].memberRelation === 'widerFamily') doc.text("Wider Family", 17, textLines);
          doc.rect(45, rectLines, 50, 5, "S");
          doc.text(memberCover[s].name + ' ' + memberCover[s].surname, 47, textLines);
          doc.rect(95, rectLines, 30, 5, "S");
          doc.text(memberCover[s].dateOfBirth, 100, textLines);
          doc.rect(125, rectLines, 40, 5, "S");
          doc.text(memberCover[s].relationship, 130, rectLines + 4);
          doc.rect(165, rectLines, 30, 5, "S");
          doc.text(String(memberCover[s].age), 176, textLines);
        }
      }

      // Benefit & Premium
      pageline = textLines;
      doc.setFont("Helvetica", "bold");
      doc.setFontSize(14);
      doc.text("4. Benefit & Deduction details:", 15, pageline + 15)
      doc.setTextColor("4068a1");
      doc.setFontSize(9);
      doc.setFont("Helvetica", "italic", "bold");
      doc.text("4.1 Calculation of contribution:", 15, pageline + 20);
      doc.setTextColor("#444");
      doc.setFont("Helvetica", "normal");
      pageline = pageline - 38;

      //Main Member Table
      doc.setFillColor("4068a1");
      doc.rect(15, pageline + 60, 40, 5, "FD");
      doc.rect(55, pageline + 60, 40, 5, "FD");
      doc.rect(95, pageline + 60, 40, 5, "FD");
      doc.rect(135, pageline + 60, 30, 5, "FD");
      doc.rect(165, pageline + 60, 30, 5, "FD");

      doc.setFont("Helvetica", "bold");
      doc.setTextColor(255, 255, 255);
      doc.text("Main Member", 25, pageline + 64);
      doc.text("Date of Birth", 65, pageline + 64);
      doc.text("Cover Type", 105, pageline + 64);
      doc.text("Cover Amount", 140, pageline + 64);
      doc.text("Premium", 173, pageline + 64);
      doc.setTextColor("#444");
      doc.setFont("Helvetica", "normal");

      let reactlines = pageline + 60;
      let textline = pageline + 64;
      pageline = textline;

      if (funeralData.funeralCost > 0) {
        doc.rect(15, reactlines + 5, 40, 5, "S");
        doc.text(`${funeralData.fullNames} ${funeralData.surname}`, 18, textline + 5);
        doc.rect(55, reactlines + 5, 40, 5, "S");
        doc.text(`${funeralData.mainDOB}`, 65, textline + 5);
        doc.rect(95, reactlines + 5, 40, 5, "S");
        doc.text('Funeral', 106, textline + 5);
        doc.rect(135, reactlines + 5, 30, 5, "S");
        doc.text(`R${funeralData.funeralAmount}`, 145, textline + 5);
        doc.rect(165, reactlines + 5, 30, 5, "S");
        doc.text(`R${funeralData.funeralCost.toFixed(2)}`, 173, textline + 5);
        pageline = textline + 10;
      }

      if (funeralData.accidentalCost > 0) {
        doc.rect(15, reactlines + 10, 40, 5, "S");
        doc.text(`${funeralData.fullNames} ${funeralData.surname}`, 18, textline + 10);
        doc.rect(55, reactlines + 10, 40, 5, "S");
        doc.text(`${funeralData.mainDOB}`, 65, textline + 10);
        doc.rect(95, reactlines + 10, 40, 5, "S");
        doc.text('Accidental', 106, textline + 10);
        doc.rect(135, reactlines + 10, 30, 5, "S");
        doc.text(`R${funeralData.accidentalAmount}`, 145, textline + 10);
        doc.rect(165, reactlines + 10, 30, 5, "S");
        doc.text(`R${funeralData.accidentalCost.toFixed(2)}`, 173, textline + 10);
        pageline = textline + 10;
      }

      if (funeralData.incomeCost > 0) {
        doc.rect(15, reactlines + 15, 40, 5, "S");
        doc.text(`${funeralData.fullNames} ${funeralData.surname}`, 18, textline + 15);
        doc.rect(55, reactlines + 15, 40, 5, "S");
        doc.text(`${funeralData.mainDOB}`, 65, textline + 15);
        doc.rect(95, reactlines + 15, 40, 5, "S");
        doc.text('Income', 106, textline + 15);
        doc.rect(135, reactlines + 15, 30, 5, "S");
        doc.text(`R${funeralData.incomeAmount}`, 145, textline + 15);
        doc.rect(165, reactlines + 15, 30, 5, "S");
        doc.text(`R${funeralData.incomeCost.toFixed(2)}`, 173, textline + 15);
        pageline = textline + 15;
      }

      //Spouse Member Table
      if (spouceCover.length > 0) {
        doc.setFillColor("4068a1");
        doc.rect(15, pageline + 5, 40, 5, "FD");
        doc.rect(55, pageline + 5, 40, 5, "FD");
        doc.rect(95, pageline + 5, 40, 5, "FD");
        doc.rect(135, pageline + 5, 30, 5, "FD");
        doc.rect(165, pageline + 5, 30, 5, "FD");

        doc.setFont("Helvetica", "bold");
        doc.setTextColor(255, 255, 255);
        doc.text("Spouse Member", 22, pageline + 9);
        doc.text("Date of Birth", 65, pageline + 9);
        doc.text("Cover Type", 105, pageline + 9);
        doc.text("Cover Amount", 140, pageline + 9);
        doc.text("Premium", 173, pageline + 9);
        doc.setTextColor("#444");
        doc.setFont("Helvetica", "normal");

        rectLine = pageline + 5;
        textLine = pageline + 9;

        for (let s = 0; s < spouceCover.length; s++) {
          rectLine = rectLine + 5;
          textLine = textLine + 5;
          doc.rect(15, rectLine, 40, 5, "S");
          doc.text(spouceCover[s].name + ' ' + spouceCover[s].surname, 17, textLine);
          doc.rect(55, rectLine, 40, 5, "S");
          doc.text(spouceCover[s].dateOfBirth, 65, textLine);
          doc.rect(95, rectLine, 40, 5, "S");
          doc.text('Funeral', 106, textLine);
          doc.rect(135, rectLine, 30, 5, "S");
          doc.text(`R${spouceCover[s].benefit}`, 145, textLine);
          doc.rect(165, rectLine, 30, 5, "S");
          doc.text(`R${spouceCover[s].premium.toFixed(2)}`, 173, textLine);
          if (spouceCover[s].spouseAccidentalAmount > 0) {
            rectLine = rectLine + 5;
            textLine = textLine + 5;
            doc.rect(15, rectLine, 40, 5, "S");
            doc.text(spouceCover[s].name + ' ' + spouceCover[s].surname, 17, textLine);
            doc.rect(55, rectLine, 40, 5, "S");
            doc.text(spouceCover[s].dateOfBirth, 65, textLine);
            doc.rect(95, rectLine, 40, 5, "S");
            doc.text('Accidental', 106, textLine);
            doc.rect(135, rectLine, 30, 5, "S");
            doc.text(`R${spouceCover[s].spouseAccidentalAmount}`, 145, textLine);
            doc.rect(165, rectLine, 30, 5, "S");
            doc.text(`R${spouceCover[s].spouseAccidentalCost.toFixed(2)}`, 173, textLine);
          }
          if (spouceCover[s].spouseIncomeAmount > 0) {
            rectLine = rectLine + 5;
            textLine = textLine + 5;
            doc.rect(15, rectLine, 40, 5, "S");
            doc.text(spouceCover[s].name + ' ' + spouceCover[s].surname, 17, textLine);
            doc.rect(55, rectLine, 40, 5, "S");
            doc.text(spouceCover[s].dateOfBirth, 65, textLine);
            doc.rect(95, rectLine, 40, 5, "S");
            doc.text('Income', 106, textLine);
            doc.rect(135, rectLine, 30, 5, "S");
            doc.text(`R${spouceCover[s].spouseIncomeAmount}`, 145, textLine);
            doc.rect(165, rectLine, 30, 5, "S");
            doc.text(`R${spouceCover[s].spouseIncomeCost.toFixed(2)}`, 173, textLine);
          }
          pageline = textLine;
        }
      }

      //Covered Member Table
      if (memberCover.length > 0) {
        doc.setFillColor("4068a1");
        doc.rect(15, pageline + 5, 30, 5, "FD");
        doc.rect(45, pageline + 5, 30, 5, "FD");
        doc.rect(75, pageline + 5, 30, 5, "FD");
        doc.rect(105, pageline + 5, 25, 5, "FD");
        doc.rect(130, pageline + 5, 25, 5, "FD");
        doc.rect(155, pageline + 5, 20, 5, "FD");
        doc.rect(175, pageline + 5, 20, 5, "FD");

        doc.setFont("Helvetica", "bold");
        doc.setTextColor(255, 255, 255);
        doc.text("Covered Member", 17, pageline + 9);
        doc.text("Relationship", 51, pageline + 9);
        doc.text("Sub Relationship", 77, pageline + 9);
        doc.text("Date of Birth", 108, pageline + 9);
        doc.text("Cover Type", 134, pageline + 9);
        doc.text("Benefit", 159, pageline + 9);
        doc.text("Premium", 178, pageline + 9);
        doc.setTextColor("#444");
        doc.setFont("Helvetica", "normal");

        let rectPosition = pageline + 5
        let textPosition = pageline + 9

        for (let s = 0; s < memberCover.length; s++) {
          rectPosition = rectPosition + 5;
          textPosition = textPosition + 5;
          doc.rect(15, rectPosition, 30, 5, "S");
          doc.text(memberCover[s].name + ' ' + memberCover[s].surname, 17, textPosition);
          doc.rect(45, rectPosition, 30, 5, "S");
          if (memberCover[s].memberRelation === 'ownChildren') doc.text("Own Children", 47, textPosition);
          if (memberCover[s].memberRelation === 'parents') doc.text("Parents", 47, textPosition);
          if (memberCover[s].memberRelation === 'formerSpouse') doc.text("Former Spouse", 47, textPosition);
          if (memberCover[s].memberRelation === 'widerFamily') doc.text("Wider Family", 47, textPosition);
          doc.rect(75, rectPosition, 30, 5, "S");
          doc.text(String(memberCover[s].relationship), 77, textPosition);
          doc.rect(105, rectPosition, 25, 5, "S");
          doc.text(memberCover[s].dateOfBirth, 109, textPosition);
          doc.rect(130, rectPosition, 25, 5, "S");
          doc.text("Funeral", 137, textPosition);
          doc.rect(155, rectPosition, 20, 5, "S");
          doc.text("R" + memberCover[s].benefit, 160, textPosition);
          doc.rect(175, rectPosition, 20, 5, "S");
          doc.text("R" + memberCover[s].premium.toFixed(2), 180, textPosition);
          pageline = textPosition;
        }
      }

      if (funeralData.funeralCost > 0 || funeralData.accidentalCost > 0 || funeralData.incomeCost > 0 || spouceCover.length > 0 || memberCover.length > 0) {
        doc.setFont("Helvetica", "bold");
        doc.text("Premium Waiver:", 146, pageline + 5);
        doc.text("Premium Holiday:", 145, pageline + 10);
        doc.setFont("Helvetica", "normal");
        doc.rect(175, pageline + 1, 20, 5, "S");
        doc.rect(175, pageline + 6, 20, 5, "S");
        doc.rect(175, pageline + 11, 20, 5, "S");
        doc.rect(175, pageline + 16, 20, 5, "S");
        if (funeralData.premiumWaiver) doc.text("R" + funeralData.premiumWaiverAmount, 180, pageline + 5); else doc.text("R0.00", 180, pageline + 5);
        if (funeralData.premiumHoliday) doc.text("R" + funeralData.premiumHolidayAmount, 180, pageline + 10); else doc.text("R0.00", 180, pageline + 10);
        pageline = pageline + 10;
        doc.setFont("Helvetica", "bold");
        doc.text("Policy Fee:", 155, pageline + 5);
        doc.text("Total monthly premium:", 136, pageline + 10);
        doc.setFont("Helvetica", "normal");
        doc.text("R10.00", 180, pageline + 5);
        if (funeralData.finalTotal) doc.text("R" + funeralData.finalTotal, 180, pageline + 10);
        else doc.text("R0.00", 180, pageline + 10);
        doc.setFont("Helvetica", "bold");
        doc.text("*An automatic annual benefit increase of 3% on the anniversary of the policy on all benefits selected on all lives insured", 15, pageline + 15);
        doc.text("is applicable on this policy.", 15, pageline + 20)
        doc.text("*An automatic annual premium increase of 5% on the anniversary of the policy on the monthly premium of all lives insur", 15, pageline + 25);
        doc.text("ed is applicable on this policy.", 15, pageline + 30);
        pageline = pageline + 38;
      }

      doc.setFont("Helvetica", "italic", "bold");
      doc.setTextColor("4068a1");
      doc.text("4.2 Deduction details:", 15, pageline);
      doc.setTextColor("#444");
      doc.setFont("Helvetica", "bold");
      doc.text("Predferred monthly deduction method:", 15, pageline + 6);
      doc.setFont("Helvetica", "normal");
      doc.setFillColor("4068a1");
      if (funeralData.bankingDetailsDO.paymentMethod === 'Payroll') doc.rect(78, pageline + 3, 4, 4, "FD");
      else doc.rect(78, pageline + 3, 4, 4);
      doc.text('Payroll deduction (complete section 4.2.1)', 83, pageline + 6);
      doc.setFillColor("4068a1");
      if (funeralData.bankingDetailsDO.paymentMethod === 'DebitOrder') doc.rect(145, pageline + 3, 4, 4, "FD");
      else doc.rect(145, pageline + 3, 4, 4);
      doc.text('Debit order(complete section 4.2.2)', 150, pageline + 6);

      pageline = pageline + 8;
      // PAYROLL METHOD
      if (funeralData.bankingDetailsDO.paymentMethod === 'Payroll') {
        doc.setTextColor("4068a1");
        doc.setFont("Helvetica", "italic", "bold");
        doc.text("4.2.1 Payroll deduction Mandate & Authority:", 15, pageline + 5);
        doc.setTextColor("#444");
        doc.setFont("Helvetica", "normal");
        doc.text("I, the undersigned:", 15, pageline + 10)
        pageline = pageline + 5;
        doc.setFont("Helvetica", "bold");
        doc.text("Name & Surname:", 15, pageline + 11);
        doc.rect(46, pageline + 7, 55, 5, "S");
        doc.text("ID Number.:", 15, pageline + 16);
        doc.rect(46, pageline + 12, 55, 5, "S");
        doc.text("Rank / Title:", 15, pageline + 21);
        doc.rect(46, pageline + 17, 55, 5, "S");
        doc.text("Start Date", 15, pageline + 26);
        doc.rect(46, pageline + 22, 55, 5, "S");
        doc.text("Force/Salary No.:", 105, pageline + 11);
        doc.rect(136, pageline + 7, 62, 5, "S");
        doc.text("Department/Unit:", 105, pageline + 16);
        doc.rect(136, pageline + 12, 62, 5, "S");
        doc.text("Payslip reference:", 105, pageline + 21);
        // doc.rect(136, pageline + 17, 62, 5, "S");
        doc.text("End date:", 105, pageline + 26);
        // doc.rect(136, pageline + 22, 62, 5, "S");

        doc.setFont("Helvetica", "normal");
        doc.text(funeralData.fullNames + ' ' + funeralData.surname, 48, pageline + 11);
        if (funeralData.IDNumber) doc.text(funeralData.IDNumber, 48, pageline + 16);
        if (funeralData.rankTitle) doc.text(funeralData.rankTitle, 48, pageline + 21);
        if (day <= 15) doc.text(`1st ${month[monthNo]} ${yearNow}`, 48, pageline + 26);
        else doc.text(`1st ${month[monthNo + 1]} ${yearNow}`, 48, pageline + 26);
        doc.text(funeralData.employeeNo, 138, pageline + 11);
        doc.text(funeralData.departmentUnit, 138, pageline + 16);
        doc.setFont("Helvetica", "Bold");
        doc.text("SHIELD LIFE LTD (Deduction code 3294)", 136, pageline + 21);
        doc.setFont("Helvetica", "normal");
        doc.text("monthly until policy lapses/cancelled", 136, pageline + 26);

        pageline = pageline - 3;
        doc.text(`I authorise the Accountant of the Employer Group ${funeralData.employerName} to deduct the monthly payments from my salary as per`, 15, pageline + 35);
        doc.text("the above authority provided and remit it to Shield Life of which I am a member / Insured, each and every month and continuing ", 15, pageline + 40);
        doc.text("until cancelled by me in writing or until I substitute it with a new authorisation. I have read and accepted the terms and conditions", 15, pageline + 45);
        doc.text("as set out below.", 15, pageline + 50);
      }

      //DEBIT ORDER METHOD
      if (funeralData.bankingDetailsDO.paymentMethod === 'DebitOrder') {
        doc.setTextColor("4068a1");
        doc.setFont("Helvetica", "italic", "bold");
        doc.text("4.2.2 Debit order Mandate & Authority:", 15, pageline + 5);
        doc.setTextColor("#444");
        doc.setFont("Helvetica", "bold");
        pageline = pageline + 3;
        doc.text("Account Holder Name", 15, pageline + 9);
        doc.text("Account Number:", 106, pageline + 9);
        doc.rect(50, pageline + 5, 55, 5, "S");
        doc.rect(136, pageline + 5, 62, 5, "S");
        doc.setFont("Helvetica", "normal");
        doc.text(funeralData.fullNames + ' ' + funeralData.surname, 52, pageline + 9);
        doc.text(funeralData.bankingDetailsDO.AccountNumber, 138, pageline + 9);
        pageline = pageline - 2;
        doc.setFont("Helvetica", "bold");
        doc.text("Bank & Branch code:", 15, pageline + 16);
        doc.rect(50, pageline + 12, 55, 5, "S");
        doc.text("Type of account:", 15, pageline + 21);
        doc.rect(50, pageline + 17, 55, 5, "S");
        doc.text("Debit order date:", 15, pageline + 26);
        doc.rect(50, pageline + 22, 55, 5, "S");
        doc.text("First instalment date:", 15, pageline + 31);
        doc.text("Beneficiary:", 15, pageline + 36);
        doc.text("Other:", 106, pageline + 16);
        doc.rect(136, pageline + 12, 62, 5, "S");
        doc.text("Other:", 106, pageline + 21);
        doc.rect(136, pageline + 17, 62, 5, "S");
        doc.text("Bank statement ref:", 106, pageline + 26);
        doc.text("Repayment term:", 106, pageline + 31);
        doc.text("Payment streem:", 106, pageline + 42);
        doc.setFont("Helvetica", "normal");
        doc.text(funeralData.bankingDetailsDO.Bank + '  ' + funeralData.bankingDetailsDO.BranchCode, 52, pageline + 16);
        doc.text(funeralData.bankingDetailsDO.AccountType, 52, pageline + 21);
        doc.text(funeralData.bankingDetailsDO.debitOrderDate, 52, pageline + 26);
        if (day <= 15) doc.text(`1st ${month[monthNo]} ${yearNow}`, 52, pageline + 31);
        else doc.text(`1st ${month[monthNo + 1]} ${yearNow}`, 52, pageline + 31);
        doc.text("Shield Life Ltd", 52, pageline + 36);
        doc.text("SLL FUNERAL", 136, pageline + 26);
        doc.setFont("Helvetica", "normal");
        doc.text("above frequency until policy lapses/policy", 136, pageline + 31);
        doc.text("cancelled", 136, pageline + 36)
        doc.text("Debicheck", 136, pageline + 42);
      }

      if (pageline > 230) {
        doc.addPage();
        pageline = -50;
      }

      if (funeralData.bankingDetailsDO.paymentMethod === 'Payroll') {
        doc.setTextColor("4068a1");
        doc.setFont("Helvetica", "italic", "bold");
        doc.text("4.2.2 Alterations to method of payment in case of unavailability of payroll deduction", 15, pageline + 60);
        doc.setFont("Helvetica", "normal");
        doc.setTextColor("#444");
        doc.text("I hereby authorise the method of payment to be altered to a Debit Order in the event of me not qualifying for payroll", 15, pageline + 65);
        doc.text("deduction.", 15, pageline + 70);
        doc.setFont("Helvetica", "bold");
        doc.setFillColor("4068a1");
        if (funeralData.bankingDetailsDO.alternativeMethod === "yes") doc.rect(160, pageline + 67, 4, 4, "FD");
        else doc.rect(160, pageline + 67, 4, 4);
        doc.text('Yes', 165, pageline + 70);
        doc.setFillColor("4068a1");
        if (funeralData.bankingDetailsDO.alternativeMethod === "no") doc.rect(175, pageline + 67, 4, 4, "FD");
        else doc.rect(175, pageline + 67, 4, 4);
        doc.text('No', 180, pageline + 70);

        if (funeralData.bankingDetailsDO.alternativeMethod === "yes") {
          if (pageline !== -50) {
            doc.addPage();
            pageline = 5;
          } else pageline = 25;
          doc.setTextColor("4068a1");
          doc.setFont("Helvetica", "italic", "bold");
          doc.text("4.2.3 Debit order Mandate & Authority:", 15, pageline + 5);
          doc.setTextColor("#444");
          doc.setFont("Helvetica", "bold");
          pageline = pageline + 3;
          doc.text("Account Holder Name", 15, pageline + 9);
          doc.text("Account Number:", 106, pageline + 9);
          doc.rect(50, pageline + 5, 55, 5, "S");
          doc.rect(136, pageline + 5, 62, 5, "S");
          doc.setFont("Helvetica", "normal");
          doc.text(funeralData.fullNames + ' ' + funeralData.surname, 52, pageline + 9);
          if (funeralData.bankingDetailsDO.AccountNumber) doc.text(funeralData.bankingDetailsDO.AccountNumber, 138, pageline + 9);
          pageline = pageline - 2;
          doc.setFont("Helvetica", "bold");
          doc.text("Bank & Branch code:", 15, pageline + 16);
          doc.rect(50, pageline + 12, 55, 5, "S");
          doc.text("Type of account:", 15, pageline + 21);
          doc.rect(50, pageline + 17, 55, 5, "S");
          doc.text("Debit order date:", 15, pageline + 26);
          doc.rect(50, pageline + 22, 55, 5, "S");
          doc.text("First instalment date:", 15, pageline + 31);
          doc.text("Beneficiary:", 15, pageline + 36);
          doc.text("Other:", 106, pageline + 16);
          doc.rect(136, pageline + 12, 62, 5, "S");
          doc.text("Other:", 106, pageline + 21);
          doc.rect(136, pageline + 17, 62, 5, "S");
          doc.text("Bank statement ref:", 106, pageline + 26);
          doc.text("Repayment term:", 106, pageline + 31);
          doc.text("Payment streem:", 106, pageline + 42);
          doc.setFont("Helvetica", "normal");
          if (funeralData.bankingDetailsDO.Bank) doc.text(funeralData.bankingDetailsDO.Bank + '  ' + funeralData.bankingDetailsDO.BranchCode, 52, pageline + 16);
          if (funeralData.bankingDetailsDO.AccountType) doc.text(funeralData.bankingDetailsDO.AccountType, 52, pageline + 21);
          if (funeralData.bankingDetailsDO.debitOrderDate) doc.text(funeralData.bankingDetailsDO.debitOrderDate, 52, pageline + 26);
          if (day <= 15) doc.text(`1st ${month[monthNo]} ${yearNow}`, 52, pageline + 31);
          else doc.text(`1st ${month[monthNo + 1]} ${yearNow}`, 52, pageline + 31);
          doc.text("Shield Life Ltd", 52, pageline + 36);
          doc.text("SLL FUNERAL", 136, pageline + 26);
          doc.setFont("Helvetica", "normal");
          doc.text("above frequency until policy lapses/policy", 136, pageline + 31);
          doc.text("cancelled", 136, pageline + 36)
          doc.text("Debicheck", 136, pageline + 42);
          pageline = pageline + 3;
          doc.text("I authorise Shield Life to issue and deliver debit order payment instructions to your banker for collection against my Account and", 15, pageline + 45);
          doc.text("Bank used by my employer to deposit my salaries / wages, which account may differ from the account specified above on condit", 15, pageline + 50);
          doc.text("ion that the sum of each payment instruction and frequency of payment requests will never exceed the obligations as agreed and", 15, pageline + 55);
          doc.text("defined in the Agreement specified.", 15, pageline + 60);
          doc.text("My bank’s participation in the relevant payment stream, the authentication mechanism used by me and the relevant rules applica", 15, pageline + 65);
          doc.text("ble to the payment streams will determine per repayment cycle the most suitable payment stream in order to fulfil the obligations", 15, pageline + 70);
          doc.text("as defined in the agreement. My authorisation is subject thereto that the same payment instruction may never be presented simu", 15, pageline + 75);
          doc.text("ltaneously in more than one payment stream during the same repayment cycle or, if unpaid in a payment stream selected be rep", 15, pageline + 80);
          doc.text("resented in the same cycle in another payment stream. I can only service the obligations defined in here if the payment instruction", 15, pageline + 85);
          doc.text("are executed as close as possible to when I receive my salary or wages which dates vary from month to month, especially during", 15, pageline + 90);
          doc.text("December of each year. To curb against (1) unpaid bank charges (2) losing the benefits described in the agreement quoted (3)", 15, pageline + 95);
          doc.text("incurring penalties due to non payment; I explicitly authorise Shield Life to utilise the functionality of Tracking supported on the", 15, pageline + 100);
          doc.text("Debicheck Payment Streams especially after unsuccessful attempts on the Debicheck Payment Stream.", 15, pageline + 105);
          doc.text("Tracking supported on the Debicheck Payment Streams has been explained to me and I acknowledge that my above mentioned", 15, pageline + 110);
          doc.text("account will be interrogated for a defined period until this period has lapsed or until payment has been received. I hereby agree", 15, pageline + 115);
          doc.text("that subsequent payment instructions will continue to be delivered in terms of the authority until all obligations have been paid.", 15, pageline + 120);
          doc.text("This authorisation will remain in force until cancelled by me in writing. I hereby acknowledge that my bank will charge fees to my", 15, pageline + 125);
          doc.text("account as agreed with them once they process this instruction. I foresee that I may change my bank and bank account particular", 15, pageline + 130);
          doc.text("reflected in here, in which instance I will notify the beneficiaries specified, or any new beneficiary, should the agreement benefici", 15, pageline + 135);
          doc.text("ary or the ceded or assigned to any third party. Should I however forget to notify the beneficiary or the assigned third party and if", 15, pageline + 140);
          doc.text("the benef assigned third party obtain my new bank particulars, on own account, this mandate will not lapse. This issued mandate", 15, pageline + 145);
          doc.text("will cover the obtained bank information and the beneficiary and the assigned third party may attach such new information, to this", 15, pageline + 150);
          doc.text("signed document as annexure, and the attached annexure must be read together with this mandate, by my new bank. Date chan", 15, pageline + 155);
          doc.text("ges agreed to align my salary and wage expected day of payment in accordance with the Agreement concluded with Legal Entity.", 15, pageline + 160);
          doc.text("I understand that the withdrawals hereby authorised will be processed through a computerised system provided by the South Afric", 15, pageline + 165);
          doc.text("an Banks I also understand that details of each withdrawal will be printed on my/our bank statement. Such must contain the agree", 15, pageline + 170);
          doc.text("ment reference number as stipulated above which number shall enable me to match the withdrawals with this agreement. I ackno", 15, pageline + 175);
          doc.text("wledge that this Authority may be ceded or assigned to a third party but only in the event that this agreement is also ceded or assi", 15, pageline + 180);
          doc.text("gned to that third party.", 15, pageline + 185);
          doc.setFont("Helvetica", "Bold");
          pageline = pageline - 5;
          doc.text("Terms & Conditions", 15, pageline + 195);
          doc.setFont("Helvetica", "normal");
          doc.text("I instruct and authorise Shield Life to draw against my nominated bank account (or any other bank or branch to which I may trans", 15, pageline + 200);
          doc.text("fer my account) the amount necessary for the payment of the monthly premium due in respect of my insurance, on the day nomin", 15, pageline + 205);
          doc.text("ated by me each and every month and continuing until cancelled by me in writing or until I substitute it with a new authorisation.", 15, pageline + 210);
          doc.text("I accept that the authority may be cancelled by me by giving 31 (thirty one) days notice in writing.", 15, pageline + 215);
          doc.text("I understand that should I cancel the authority after the 31-day period, I shall not be entitled to any refund of amounts which have", 15, pageline + 220);
          doc.text("been withdrawn while this authority was in force, if such amounts were legally owing to you.", 15, pageline + 225);
          doc.text("I agree that in the event that the payment date falls on a Saturday, Sunday or recognised South African public holiday, the payme", 15, pageline + 230);
          doc.text("nt day will automatically be the first preceding business day.", 15, pageline + 235);
          doc.text("I agree that should there be insufficient funds in my account to meet the obligation, Shield Life is entitled  to track my account and", 15, pageline + 240);
          doc.text("re-present the instruction for payment as soon as sufficient funds are available in my account. Such will contain Shield Life Limited", 15, pageline + 245);
          doc.text("as reference which shall enable me to match the withdrawals with this agreement.", 15, pageline + 250);
          doc.text("I agree to pay any bank charges relating to this debit order instruction. ", 15, pageline + 255);
          doc.text("Should the premium rate be adjusted by Shield Life as a result of a general decrease/increase in subscription or should I request", 15, pageline + 260);
          doc.text("Shield Life to decrease/increase the premium for certain reason, I confirm that the adjusted premium may be deducted from my", 15, pageline + 265);
          doc.text("bank account. I acknowledge that this Authority may be ceded or assigned to a third party for collection purposes.", 15, pageline + 270);
          if (pageline > 20) {
            doc.addPage();
            pageline = -260;
          }
          doc.addImage(signature, "JPEG", 25, pageline + 265, 35, 35, "final", "MEDIUM");
          doc.text("____________________________", 20, pageline + 285);
          doc.setFont("Helvetica", "italic", "bold");
          doc.text("Signature of Premium Payer", 25, pageline + 290);
          if (funeralData.representativeData && funeralData.representativeData.representativeCode) doc.text("Representative Code: " + funeralData.representativeData.representativeCode, 100, pageline + 285);
          doc.text("Date:", 100, pageline + 278);
          doc.setFont("Helvetica", "normal");
          doc.text(timeStamp, 110, pageline + 278);
          doc.text("__________________________________________________", 110, pageline + 280);
          pageline = pageline + 300;
        } else {
          doc.addPage();
          if (pageline > 100) {
            pageline = -50;
          }
          doc.setFont("Helvetica", "Bold");
          doc.text("Terms & Conditions", 15, pageline + 60);
          doc.setFont("Helvetica", "normal");
          doc.text("I accept that the authority may be cancelled by me by giving 31 (thirty one) days notice in writing.", 15, pageline + 65);
          doc.text("I understand that should I cancel the authority after the 31 - day period, I shall not be entitled to any refund of amounts which", 15, pageline + 70);
          doc.text("have been withdrawn while this authority was in force, if such amounts were legally owing to you.", 15, pageline + 75);
          pageline = pageline + 10;
          doc.text("Should the premium rate be adjusted by Shield Life as a result of a general decrease / increase in subscription or should I request ", 15, pageline + 70);
          doc.text("Shield Life to decrease / increase the premium for certain reason, I confirm that the adjusted premium may be deducted.", 15, pageline + 75);
          doc.text("I acknowledge that this Authority may be ceded or assigned to a third party for collection purposes.", 15, pageline + 80);
          pageline = pageline - 15;

          doc.addImage(signature, "JPEG", 25, pageline + 85, 35, 35, "final", "MEDIUM");
          doc.text("____________________________", 20, pageline + 110);
          doc.setFont("Helvetica", "italic", "bold");
          doc.text("Signature of Premium Payer", 25, pageline + 115);
          if (funeralData.representativeData && funeralData.representativeData.representativeCode) doc.text("Representative Code: " + funeralData.representativeData.representativeCode, 100, pageline + 105);
          doc.text("Date:", 100, pageline + 110);
          doc.setFont("Helvetica", "normal");
          doc.text(timeStamp, 110, pageline + 110);
          doc.text("__________________________________________________", 110, pageline + 113);
          pageline = pageline + 125;
        }
      }

      //DEBIT ORDER METHOD
      if (funeralData.bankingDetailsDO.paymentMethod === 'DebitOrder') {
        doc.text("I authorise Shield Life to issue and deliver debit order payment instructions to your banker for collection against my Account and", 15, pageline + 45);
        doc.text("Bank used by my employer to deposit my salaries / wages, which account may differ from the account specified above on condit", 15, pageline + 50);
        doc.text("ion that the sum of each payment instruction and frequency of payment requests will never exceed the obligations as agreed and", 15, pageline + 55);
        doc.text("defined in the Agreement specified.", 15, pageline + 60);
        doc.text("My bank’s participation in the relevant payment stream, the authentication mechanism used by me and the relevant rules applica", 15, pageline + 65);
        doc.text("ble to the payment streams will determine per repayment cycle the most suitable payment stream in order to fulfil the obligations", 15, pageline + 70);
        doc.text("as defined in the agreement. My authorisation is subject thereto that the same payment instruction may never be presented simu", 15, pageline + 75);
        doc.text("ltaneously in more than one payment stream during the same repayment cycle or, if unpaid in a payment stream selected be rep", 15, pageline + 80);
        doc.text("resented in the same cycle in another payment stream. I can only service the obligations defined in here if the payment instruction", 15, pageline + 85);
        doc.text("are executed as close as possible to when I receive my salary or wages which dates vary from month to month, especially during", 15, pageline + 90);
        doc.text("December of each year. To curb against (1) unpaid bank charges (2) losing the benefits described in the agreement quoted (3)", 15, pageline + 95);
        doc.text("incurring penalties due to non payment; I explicitly authorise Shield Life to utilise the functionality of Tracking supported on the", 15, pageline + 100);
        doc.text("Debicheck Payment Streams especially after unsuccessful attempts on the Debicheck Payment Stream.", 15, pageline + 105);
        doc.text("Tracking supported on the Debicheck Payment Streams has been explained to me and I acknowledge that my above mentioned", 15, pageline + 110);
        doc.text("account will be interrogated for a defined period until this period has lapsed or until payment has been received. I hereby agree", 15, pageline + 115);
        doc.text("that subsequent payment instructions will continue to be delivered in terms of the authority until all obligations have been paid.", 15, pageline + 120);
        doc.text("This authorisation will remain in force until cancelled by me in writing. I hereby acknowledge that my bank will charge fees to my", 15, pageline + 125);
        doc.text("account as agreed with them once they process this instruction. I foresee that I may change my bank and bank account particular", 15, pageline + 130);
        doc.text("reflected in here, in which instance I will notify the beneficiaries specified, or any new beneficiary, should the agreement benefici", 15, pageline + 135);
        doc.text("ary or the ceded or assigned to any third party. Should I however forget to notify the beneficiary or the assigned third party and if", 15, pageline + 140);
        doc.text("the benef assigned third party obtain my new bank particulars, on own account, this mandate will not lapse. This issued mandate", 15, pageline + 145);
        doc.text("will cover the obtained bank information and the beneficiary and the assigned third party may attach such new information, to this", 15, pageline + 150);
        doc.text("signed document as annexure, and the attached annexure must be read together with this mandate, by my new bank. Date chan", 15, pageline + 155);
        doc.text("ges agreed to align my salary and wage expected day of payment in accordance with the Agreement concluded with Legal Entity.", 15, pageline + 160);
        doc.text("I understand that the withdrawals hereby authorised will be processed through a computerised system provided by the South Afric", 15, pageline + 165);
        if (pageline > 15) {
          doc.addPage();
          pageline = -160;
        }
        doc.text("an Banks I also understand that details of each withdrawal will be printed on my/our bank statement. Such must contain the agree", 15, pageline + 170);
        doc.text("ment reference number as stipulated above which number shall enable me to match the withdrawals with this agreement. I ackno", 15, pageline + 175);
        doc.text("wledge that this Authority may be ceded or assigned to a third party but only in the event that this agreement is also ceded or assi", 15, pageline + 180);
        doc.text("gned to that third party.", 15, pageline + 185);
        doc.setFont("Helvetica", "Bold");
        doc.text("Terms & Conditions", 15, pageline + 195);
        doc.setFont("Helvetica", "normal");
        doc.text("I instruct and authorise Shield Life to draw against my nominated bank account (or any other bank or branch to which I may trans", 15, pageline + 200);
        doc.text("fer my account) the amount necessary for the payment of the monthly premium due in respect of my insurance, on the day nomin", 15, pageline + 205);
        doc.text("ated by me each and every month and continuing until cancelled by me in writing or until I substitute it with a new authorisation.", 15, pageline + 210);
        doc.text("I accept that the authority may be cancelled by me by giving 31 (thirty one) days notice in writing.", 15, pageline + 215);
        doc.text("I understand that should I cancel the authority after the 31-day period, I shall not be entitled to any refund of amounts which have", 15, pageline + 220);
        doc.text("been withdrawn while this authority was in force, if such amounts were legally owing to you.", 15, pageline + 225);
        doc.text("I agree that in the event that the payment date falls on a Saturday, Sunday or recognised South African public holiday, the payme", 15, pageline + 230);
        doc.text("nt day will automatically be the first preceding business day.", 15, pageline + 235);
        doc.text("I agree that should there be insufficient funds in my account to meet the obligation, Shield Life is entitled  to track my account and", 15, pageline + 240);
        doc.text("re-present the instruction for payment as soon as sufficient funds are available in my account. Such will contain Shield Life Limited", 15, pageline + 245);
        doc.text("as reference which shall enable me to match the withdrawals with this agreement.", 15, pageline + 250);
        doc.text("I agree to pay any bank charges relating to this debit order instruction. ", 15, pageline + 255);
        doc.text("Should the premium rate be adjusted by Shield Life as a result of a general decrease/increase in subscription or should I request", 15, pageline + 260);
        doc.text("Shield Life to decrease/increase the premium for certain reason, I confirm that the adjusted premium may be deducted from my", 15, pageline + 265);
        doc.text("bank account. I acknowledge that this Authority may be ceded or assigned to a third party for collection purposes.", 15, pageline + 270);

        doc.addImage(signature, "JPEG", 25, pageline + 260, 35, 35, "final", "MEDIUM");
        doc.text("____________________________", 20, pageline + 285);
        doc.setFont("Helvetica", "italic", "bold");
        doc.text("Signature of Premium Payer", 25, pageline + 290);
        if (funeralData.representativeData && funeralData.representativeData.representativeCode) doc.text("Representative Code: " + funeralData.representativeData.representativeCode, 100, pageline + 280);
        doc.text("Date:", 100, pageline + 285);
        doc.setFont("Helvetica", "normal");
        doc.text(timeStamp, 110, pageline + 285);
        doc.text("________________________________________________", 110, pageline + 287);
        doc.addPage();
        pageline = 5;
      }

      if (pageline > 250) {
        doc.addPage();
        pageline = 5;
      }
      doc.setFont("Helvetica", "bold");
      doc.setFontSize(14);
      doc.text("Representative confirmation and declaration:", 15, pageline + 5);
      doc.setFont("Helvetica", "normal");
      doc.setFontSize(9);
      pageline = pageline - 7;
      doc.text("Are you a Domestic Politically Exposed Person (DPEP)?", 15, pageline + 20);
      doc.setFillColor("4068a1");
      if (funeralData.dpep && funeralData.dpep === 'Yes') doc.rect(135, pageline + 17, 4, 4, "FD");
      else doc.rect(135, pageline + 17, 4, 4);
      doc.text('Yes', 141, pageline + 20);
      doc.setFillColor("4068a1");
      if (funeralData.dpep && funeralData.dpep === 'No') doc.rect(165, pageline + 17, 4, 4, "FD");
      else doc.rect(165, pageline + 17, 4, 4);
      doc.text('No', 171, pageline + 20);
      doc.text("If yes, please provide details:", 15, pageline + 25);
      if (funeralData.dpepDetails) doc.text(funeralData.dpepDetails, 80, pageline + 25);
      doc.text("___________________________________________", 80, pageline + 27);

      doc.text("Are you a Foreign Politically Exposed Person (FPEP)?", 15, pageline + 32);
      doc.setFillColor("4068a1");
      if (funeralData.fpep && funeralData.fpep === 'Yes') doc.rect(135, pageline + 29, 4, 4, "FD");
      else doc.rect(135, pageline + 29, 4, 4);
      doc.text('Yes', 141, pageline + 32);
      doc.setFillColor("4068a1");
      if (funeralData.fpep && funeralData.fpep === 'No') doc.rect(165, pageline + 29, 4, 4, "FD");
      else doc.rect(165, pageline + 29, 4, 4);

      doc.text('No', 171, pageline + 32);
      doc.text("If yes, please provide details:", 15, pageline + 37);
      if (funeralData.fpepDetails) doc.text(funeralData.fpepDetails, 80, pageline + 37);
      doc.text("___________________________________________", 80, pageline + 39);

      doc.text("Are you a Prominent Influential Person(PIP)?", 15, pageline + 45);
      doc.setFillColor("4068a1");
      if (funeralData.pip && funeralData.pip === 'Yes') doc.rect(135, pageline + 42, 4, 4, "FD");
      else doc.rect(135, pageline + 42, 4, 4);
      doc.text('Yes', 141, pageline + 45);
      doc.setFillColor("4068a1");
      if (funeralData.pip && funeralData.pip === 'No') doc.rect(165, pageline + 42, 4, 4, "FD");
      else doc.rect(165, pageline + 42, 4, 4);
      doc.text('No', 171, pageline + 45);
      doc.text("If yes, please provide details:", 15, pageline + 50);
      if (funeralData.pipDetails) doc.text(funeralData.pipDetails, 80, pageline + 50);
      doc.text("___________________________________________", 80, pageline + 52);

      pageline = pageline + 12;
      doc.text("Is a close family member or any associates of yours a DPEP or FPEP or PIP?", 15, pageline + 45);
      doc.setFillColor("4068a1");
      if (funeralData.closeDpepFppo && funeralData.closeDpepFppo === 'Yes') doc.rect(135, pageline + 42, 4, 4, "FD");
      else doc.rect(135, pageline + 42, 4, 4);
      doc.text('Yes', 141, pageline + 45);
      doc.setFillColor("4068a1");
      if (funeralData.closeDpepFppo && funeralData.closeDpepFppo === 'No') doc.rect(165, pageline + 42, 4, 4, "FD");
      else doc.rect(165, pageline + 42, 4, 4);
      doc.text('No', 171, pageline + 45);
      doc.text("If yes, please provide details:", 15, pageline + 50);
      if (funeralData.closeDpepFppoDetails) doc.text(funeralData.closeDpepFppoDetails, 80, pageline + 50);
      doc.text("___________________________________________", 80, pageline + 52);
      doc.text("I, the undersigned hereby certify as follow:", 15, pageline + 60);
      doc.text("1. I have personally scanned in the normal course of business the documentation on the date of signature hereof by means", 15, pageline + 65);
      doc.text("of digital image capturing.", 19, pageline + 70);
      doc.text("2. All the documentations scanned were original documents and each scan resulted in a true, complete and unaltered image", 15, pageline + 75);
      doc.text("of the original document.", 19, pageline + 80);

      doc.setFont("Helvetica", "bold");
      if (funeralData.representativeData && funeralData.representativeData.representativeName) {
        doc.text("Representative First Name:", 15, pageline + 90);
        doc.text("Representative Last Name:", 15, pageline + 100);
        if (funeralData.representativeData && funeralData.representativeData.representativeCode) doc.text("Representative Code: " + funeralData.representativeData.representativeCode, 15, pageline + 110);
        doc.text("Branch:", 100, pageline + 90);
        doc.text("Date:", 100, pageline + 100);
        doc.setFont("Helvetica", "normal");
        doc.text(funeralData.representativeData.representativeName, 62, pageline + 90);
        doc.text("_____________________", 60, pageline + 92);
        doc.text(funeralData.representativeData.representativeSurname, 62, pageline + 100);
        doc.text("_______________________", 60, pageline + 102);
        doc.text("_______________________", 112, pageline + 92);
        doc.text(timeStamp, 110, pageline + 100);
        doc.text("______________________________________________", 112, pageline + 102);
      } else {
        doc.text("Full name & surname:", 15, pageline + 90);
        doc.text("Signature of representative:", 15, pageline + 100);
        if (funeralData.representativeData && funeralData.representativeData.representativeCode) doc.text("Representative Code: " + funeralData.representativeData.representativeCode, 15, pageline + 110);
        doc.text("Branch:", 100, pageline + 90);
        doc.text("Date:", 100, pageline + 100);
        doc.setFont("Helvetica", "normal");
        doc.text(funeralData.fullNames + ' ' + funeralData.surname, 62, pageline + 90);
        doc.text("_____________________", 60, pageline + 92);
        if (signature) doc.addImage(signature, "JPEG", 62, pageline + 80, 35, 35, "final", "MEDIUM");
        doc.text("_______________________", 60, pageline + 102);
        doc.text("_______________________", 112, pageline + 92);
        doc.text(timeStamp, 110, pageline + 100);
        doc.text("______________________________________________", 112, pageline + 102);
      }

      doc.setFont("Helvetica", "bold");
      doc.setFontSize(14);
      doc.text("Reason for Choosing Plan:", 15, pageline + 120);
      doc.setFont("Helvetica", "normal");
      doc.setFontSize(9);
      let line = pageline + 127;
      let i = 0;

      if (funeralData.addInfo && funeralData.addInfo?.affordableFunerral) {
        i = i + 1;
        doc.text(i + ". Affordable Funeral Cover", 15, line);
        line = line + 5
      }

      if (funeralData.addInfo && funeralData.addInfo?.funeralMyself) {
        i = i + 1;
        doc.text(i + '. Funeral Cover for Myself', 15, line);
        line = line + 5;
      }

      if (funeralData.addInfo && funeralData.addInfo?.funeralMyFamily) {
        i = i + 1;
        doc.text(i + '. Funeral Cover for my Family', 15, line);
        line = line + 5;
      }

      if (funeralData.addInfo && funeralData.addInfo?.funeralExtendedFamily) {
        i = i + 1;
        doc.text(i + '. Funeral Cover for my Extended Family', 15, line);
        line = line + 5;
      }

      if (funeralData.addInfo && funeralData.addInfo?.additionalBenifits) {
        i = i + 1;
        doc.text(i + '. Additional Benifits', 15, line);
        line = line + 5;
      }

      if (funeralData.addInfo && funeralData.addInfo?.other) {
        i = i + 1;
        doc.text(i + '. I am confident that the fair treatment of policy holders is central to the insurer’s culture', 15, line);
      }

      doc.addPage();
      pageline = 10;
      doc.setTextColor("red");
      doc.setFont("Helvetica", "bold");
      doc.text("The following does not form part of the application form but are information that needs to be communicated to clients.", 15, pageline + 5);
      doc.setFontSize(16);
      doc.setTextColor("#444");
      doc.text("Statutory Notice", 90, pageline + 15);
      doc.setFontSize(9);
      doc.setFont("Helvetica", "normal");
      doc.text("The notice does not form part of the Insurance Contract nor any other document, but you have the right to the", 25, pageline + 20);
      doc.text("following information.", 90, pageline + 25);
      doc.setTextColor("4068a1");
      doc.setFont("Helvetica", "italic", "bold");
      doc.setFontSize(11);
      doc.text("Details about other parties involved:", 15, pageline + 35);
      doc.setFont("Helvetica", "bold");
      if (!code || (!code.match('sll') && !code.match('tsll'))) {
        doc.text("Broker details:", 90, pageline + 40);
        doc.text("Insurer details:", 155, pageline + 40);
      } else {
        doc.text("The Insurer and Broker", 110, pageline + 40);
      }
      doc.setFontSize(9);
      doc.setTextColor("#444");

      //first
      doc.text("Name:", 15, pageline + 45);
      doc.text("Authorised FSP no:", 15, pageline + 50);
      doc.text("Registration no:", 15, pageline + 55);
      doc.text("Tel no:", 15, pageline + 60);
      doc.text("Email:", 15, pageline + 65);
      doc.text("Website:", 15, pageline + 70);
      doc.text("Physical Address:", 15, pageline + 75);
      doc.text("Postal Address:", 15, pageline + 95);

      doc.setFont("Helvetica", "normal");
      //middle
      if (!code || (!code.match('sll') && !code.match('tsll'))) {
        doc.text("Maxi Forces Financial Services (Pty) Ltd", 70, pageline + 45);
        doc.text("1966", 70, pageline + 50);
        doc.text("1995/004244/07", 70, pageline + 55);
        if (code && code.match('scs')) doc.text('0861 113 112', 70, pageline + 60); else doc.text('0861 222 303', 70, pageline + 60);
        doc.text("info@maxiforces.co.za", 70, pageline + 65);
        doc.text("1293 South Street,", 70, pageline + 75);
        doc.text("Maxi Building,", 70, pageline + 80);
        doc.text("Centurion,", 70, pageline + 85);
        doc.text("0157", 70, pageline + 90);
      }

      //last
      if (!code || (!code.match('sll') && !code.match('tsll'))) {
        doc.text("Shield Life® Limited", 140, pageline + 45);
        doc.text("47477", 140, pageline + 50);
        doc.text("2016/249174/06", 140, pageline + 55);
        doc.text("0861 777 353", 140, pageline + 60);
        doc.text("css@shieldlife.co.za", 140, pageline + 65);
        doc.text("www.shieldlife.co.za", 140, pageline + 70);
        doc.text("1293 South Street,", 140, pageline + 75);
        doc.text("Maxi Building,", 140, pageline + 80);
        doc.text("Centurion,", 140, pageline + 85);
        doc.text("0157", 140, pageline + 90);
        doc.text("PO Box 7309,", 140, pageline + 95);
        doc.text("Centurion,", 140, pageline + 100);
        doc.text("0157", 140, pageline + 105);
      } else {
        doc.text("Shield Life® Limited", 90, pageline + 45);
        doc.text("47477", 90, pageline + 50);
        doc.text("2016/249174/06", 90, pageline + 55);
        doc.text("0861 777 353", 90, pageline + 60);
        doc.text("css@shieldlife.co.za", 90, pageline + 65);
        doc.text("www.shieldlife.co.za", 90, pageline + 70);
        doc.text("1293 South Street,", 90, pageline + 75);
        doc.text("Maxi Building,", 90, pageline + 80);
        doc.text("Centurion,", 90, pageline + 85);
        doc.text("0157", 90, pageline + 90);
        doc.text("PO Box 7309,", 90, pageline + 95);
        doc.text("Centurion,", 90, pageline + 100);
        doc.text("0157", 90, pageline + 105);
      }

      pageline = pageline + 15;
      //first
      doc.setFont("Helvetica", "bold");
      doc.text("Authorised Category I", 15, pageline + 95);
      doc.text("products:", 15, pageline + 100);
      doc.text("Compliance officer:", 15, pageline + 115);
      doc.text("Complaints:", 15, pageline + 145);
      if (!code || (!code.match('sll') && !code.match('tsll'))) doc.text("Legal & contractual relationship:", 15, pageline + 195);
      else doc.text("FAIS Compliance officer:", 15, pageline + 165);
      doc.text("Professional Indemnity Insurance:", 15, pageline + 200);
      doc.text("Conflict of interest:", 15, pageline + 210);
      doc.text("Life insurer aprroved class and", 15, pageline + 225);
      doc.text("sub-classes of business:", 15, pageline + 230);
      doc.text("Claims procedure:", 15, pageline + 245);

      doc.setFont("Helvetica", "normal");
      //middle
      if (!code || (!code.match('sll') && !code.match('tsll'))) {
        doc.text("Long-term Insurance: A, B1, B1-A, B2, B2-A", 70, pageline + 95);
        doc.text("Short-term Insurance: Personal Lines (PL), PL", 70, pageline + 100);
        doc.text("A1", 70, pageline + 105);
        doc.text("Moonstone Compliance (Pty) Ltd represented by", 70, pageline + 115);
        doc.text("Ms Géta Hancke", 70, pageline + 120);
        doc.text("Tel: (021) 883 8000", 70, pageline + 125);
        doc.text("Fax: (021) 880 0688", 70, pageline + 130);
        doc.text("E-mail: ghancke@moonstone.co.za", 70, pageline + 135);
        doc.text("PO Box 12662, Die Board, Stellenbosch,7613", 70, pageline + 140);
        doc.text("Must be in writing and will be attended to", 70, pageline + 145);
        doc.text("within 10 business days. We may request", 70, pageline + 150);
        doc.text("additional information, if needed and will", 70, pageline + 155);
        doc.text("keep the complainant up to date of the progress", 70, pageline + 160);
        doc.text("made. If the complaint is not resolved to the", 70, pageline + 165);
        doc.text("satisfaction of the complainant, they may approach", 70, pageline + 170);
        doc.text("the FAIS Ombud after expiry of the 90 day", 70, pageline + 175);
        doc.text("period. For further details, our Complaints", 70, pageline + 180);
        doc.text("Management Policy is available upon request:", 70, pageline + 185);
        doc.text("complaints@maxiforces.co.za", 70, pageline + 190);
        doc.text("Intermediary Service Agreement", 70, pageline + 195);
        doc.text("Professional Indemnity Cover is in place.", 70, pageline + 200);
        doc.text("The Conflict of Interest Management Policy", 70, pageline + 210);
        doc.text("can be obtained upon written request: ", 70, pageline + 215);
        doc.text("compliance@maxiforces.co.za", 70, pageline + 220);
      }
      //last
      if (!code || (!code.match('sll') && !code.match('tsll'))) {
        doc.text("Long-term Insurance: A, B1, B1-A", 140, pageline + 95);
        doc.text("Short-term Insurance: Personal Lines", 140, pageline + 100);
        doc.text("(PL), PL A1 Participatory interest in a", 140, pageline + 105);
        doc.text("collective investment scheme", 140, pageline + 110);
        doc.text("The Compliance Department can be", 140, pageline + 115);
        doc.text("access through our website", 140, pageline + 120);
        doc.text("www.shieldlife.co.za or e-mail", 140, pageline + 125);
        doc.text("compliance@shieldlife.co.za", 140, pageline + 130);
        doc.text("The Compliance Policy can be access", 140, pageline + 145);
        doc.text("through our website", 140, pageline + 150);
        doc.text("www.shieldlife.co.za or e-mail ", 140, pageline + 155);
        doc.text("complaints@shieldlife.co.za", 140, pageline + 160);
        doc.text("Professional Indemnity Cover & Fidelity", 140, pageline + 200);
        doc.text("Cover are in place.", 140, pageline + 205);
        doc.text("The Conflict of Interest Policy can be", 140, pageline + 210);
        doc.text("obtained upon written request from the", 140, pageline + 215);
        doc.text("compliance officer.", 140, pageline + 220);
        doc.text("Risk: Individual and Group Death,", 140, pageline + 225);
        doc.text("Individual and Group Disability-Lump", 140, pageline + 230);
        doc.text("sum Funeral: Individual, Group", 140, pageline + 235);
        doc.text("Credit Life", 140, pageline + 240);
        doc.text("All claims enquiries should be address", 140, pageline + 245);
        doc.text("to: The Claims Department: Shield Life", 140, pageline + 250);
        doc.text("PO Box 7309, Centurion, 0046", 140, pageline + 255);
        doc.text("Tel: 0861 277 873", 140, pageline + 260);
        doc.text("Email: claims@shieldlife.co.za", 140, pageline + 265);
      } else {
        doc.text("Long-term Insurance: A, B1, B1-A", 90, pageline + 95);
        doc.text("Short-term Insurance: Personal Lines", 90, pageline + 100);
        doc.text("(PL), PL A1 Participatory interest in a", 90, pageline + 105);
        doc.text("collective investment scheme", 90, pageline + 110);
        doc.text("The Compliance Department can be", 90, pageline + 115);
        doc.text("access through our website", 90, pageline + 120);
        doc.text("www.shieldlife.co.za or e-mail", 90, pageline + 125);
        doc.text("compliance@shieldlife.co.za", 90, pageline + 130);
        doc.text("The Compliance Policy can be access", 90, pageline + 145);
        doc.text("through our website", 90, pageline + 150);
        doc.text("www.shieldlife.co.za or e-mail ", 90, pageline + 155);
        doc.text("complaints@shieldlife.co.za", 90, pageline + 160);
        doc.text("Syncerus Business Solutions (Pty) Ltd", 90, pageline + 165);
        doc.text("represented by: Ms Riana Steyn", 90, pageline + 170);
        doc.text("Tel: (082) 307 2287", 90, pageline + 175);
        doc.text("E-mail: riana@syncerus.co.za", 90, pageline + 180);
        doc.text("PO Box 2583, Brooklyn Square, 0075", 90, pageline + 185);
        doc.text("Professional Indemnity Cover & Fidelity", 90, pageline + 200);
        doc.text("Cover are in place.", 90, pageline + 205);
        doc.text("The Conflict of Interest Policy can be", 90, pageline + 210);
        doc.text("obtained upon written request from the", 90, pageline + 215);
        doc.text("compliance officer.", 90, pageline + 220);
        doc.text("Risk: Individual and Group Death,", 90, pageline + 225);
        doc.text("Individual and Group Disability-Lump", 90, pageline + 230);
        doc.text("sum Funeral: Individual, Group", 90, pageline + 235);
        doc.text("Credit Life", 90, pageline + 240);
        doc.text("All claims enquiries should be address", 90, pageline + 245);
        doc.text("to: The Claims Department: Shield Life", 90, pageline + 250);
        doc.text("PO Box 7309, Centurion, 0046", 90, pageline + 255);
        doc.text("Tel: 0861 277 873", 90, pageline + 260);
        doc.text("Email: claims@shieldlife.co.za", 90, pageline + 265);
      }

      doc.addPage();
      pageline = 10;
      doc.setTextColor("4068a1");
      doc.setFont("Helvetica", "italic", "bold");
      doc.setFontSize(11);
      doc.text("Other important contact details:", 15, pageline + 15);
      doc.setFontSize(9);
      doc.setTextColor("#444");
      doc.setFont("Helvetica", "bold");
      doc.text("The FAIS Ombud", 15, pageline + 20);
      doc.text("The Long-term Insurance Ombud", 110, pageline + 20);
      doc.setFont("Helvetica", "normal");
      doc.text("(For advice/policy related matters):", 15, pageline + 25);
      doc.text("The FAIS Ombud", 20, pageline + 30);
      doc.text("PO Box 74571, Lynnwood Ridge, 0040", 20, pageline + 35);
      doc.text("Tel: 086 066 3247 or Tel: (012) 762 5000", 20, pageline + 40);
      doc.text("Fax: (012) 348 3447", 20, pageline + 45);
      doc.text("Email: info@faisombud.co.za", 20, pageline + 50);
      doc.text("www.faisombud.co.za", 20, pageline + 55);
      doc.text("(For Claims/service related matters):", 110, pageline + 25);
      doc.text("Ombudsman for Long-term Insurance", 115, pageline + 30);
      doc.text("Claremont Central Building, 6th Floor, 6", 115, pageline + 35);
      doc.text("Vineyard Road, Claremont, 7700", 115, pageline + 40);
      doc.text("Tel: 0860 103 236 or (021) 657 5000", 115, pageline + 45);
      doc.text("Fax: (021) 674 0951", 115, pageline + 50);
      doc.text("Email: info@ombud.co.za", 115, pageline + 55);
      doc.text("www.ombud.co.za", 115, pageline + 60);

      pageline = 60;
      doc.setFont("Helvetica", "bold");
      doc.text("The Authority ", 15, pageline + 20);
      doc.text("The Information Regulator (South Africa)", 110, pageline + 20);
      doc.setFont("Helvetica", "normal");
      doc.text("(For market conduct matters):", 15, pageline + 25);
      doc.text("For Complaints on the Insurer that are not resolved to", 20, pageline + 30);
      doc.text("your satisfaction by the product supplier, please", 20, pageline + 35);
      doc.text("contact: The Financial Sector Conduct Authority", 20, pageline + 40);
      doc.text("PO Box 35655, Menlo Park, 0102", 20, pageline + 45);
      doc.text("Tel: (012) 428 8000, Fax: (012) 347 0221", 20, pageline + 50);
      doc.text("Email: info@fsca.co.za", 20, pageline + 55);
      doc.text("(For POPI matters):", 110, pageline + 25);
      doc.text("PO Box 31533, Braamfontein, Johannesburg, 2017", 115, pageline + 30);
      doc.text("Email: inforeg@justice.gov.za", 115, pageline + 35);
      doc.text("www.justice.gov.za/inforeg/docs1-gn.html#gn-paia", 115, pageline + 40);

      doc.setTextColor("4068a1");
      doc.setFont("Helvetica", "italic", "bold");
      doc.setFontSize(11);
      doc.text("Important information:", 15, pageline + 70);
      doc.setTextColor("#444");
      doc.setFont("Helvetica", "bold");
      doc.setFontSize(9);
      doc.text("Premium obligations:", 15, pageline + 75);
      doc.text("Premium payments:", 15, pageline + 90);
      doc.text("Commencement of Insurance:", 15, pageline + 105);
      doc.text("Cooling of Rights:", 15, pageline + 125);
      doc.text("Expiry of Insurance cover:", 15, pageline + 150);
      doc.text("Cancellation by Insurer:", 15, pageline + 165);
      doc.text("Premium & Benefit increase:", 15, pageline + 190);
      doc.text("Remuneration:", 15, pageline + 200);
      doc.text("Information provided:", 15, pageline + 210);
      doc.text("Insurance Contract understanding:", 15, pageline + 225);

      doc.setFont("Helvetica", "normal");
      doc.text("The obligations that you assume as premium payer is indicated in the Insurance Contract.", 70, pageline + 75);
      doc.text("Ensure that you are fully aware of these obligations.", 70, pageline + 80);
      doc.text("The conditions of the Insurance Contract set out the due date of premiums and the", 70, pageline + 90);
      doc.text("consequences of non-payment of such premiums.", 70, pageline + 95);
      doc.text("Cover will be active from the first day of the month in which we receive the first premium.", 70, pageline + 105);
      doc.text("Premiums are payable monthly. A period of thirty (30) days grace is allowed for payment", 70, pageline + 110);
      doc.text("of each premium due and payable.", 70, pageline + 115);
      doc.text("Cooling off rights may be exercised within 31 days after inception of the membership,", 70, pageline + 125);
      doc.text("where no benefit has been paid or claimed. A written cancellation form must be completed", 70, pageline + 130);
      doc.text("and send to Shield Life® Ltd. Cooling off rights can not be exercised if other legislation", 70, pageline + 135);
      doc.text("prohibit it.", 70, pageline + 140);
      doc.text("All benefits and premiums will cease to be payable upon the events as described in the", 70, pageline + 150);
      doc.text("policy wording.", 70, pageline + 155);
      doc.text("If payment of premium is unsuccessful, the policy benefits are at risk and it is the", 70, pageline + 165);
      doc.text("responsibility of the Insured to notify the Insurer as to how to collect arrear premiums.", 70, pageline + 170);
      doc.text("If the Insured don’t, the Insurer may cancel the policy and the Insured will lose the", 70, pageline + 175);
      doc.text("cover and all premiums.", 70, pageline + 180);
      doc.text("Policy & Benefit increase are subject to decisions made by Shield Life® Limited.", 70, pageline + 190);
      doc.text("Commission will not exceed the statutory commission as defined by the Life Insurance Act.", 70, pageline + 200);
      doc.text("Decisions made by Shield Life® Ltd as the insurer, in respect of insurance or claim, may", 70, pageline + 210);
      doc.text("be influenced by the failure to provide correct or full information by the insured.", 70, pageline + 215);
      doc.text("Ensure you understand what benefits and exclusions are applicable to your Insurance", 70, pageline + 225);
      doc.text("Contract. Make sure you are acquainted with:", 70, pageline + 230);
      doc.text("what is not covered,", 80, pageline + 235);

      doc.addPage();
      pageline = 5;
      doc.text("what is restricted and", 80, pageline + 5);
      doc.text("If there are any special clauses.", 80, pageline + 10);
      doc.setFont("Helvetica", "bold");
      doc.text("Important information:", 15, pageline + 20);
      doc.setFont("Helvetica", "normal");
      doc.text("You will be informed of any material changes to the above mentioned information.", 70, pageline + 20);
      doc.text("This disclosure notice serves to provide you with the information in writing if any", 70, pageline + 25);
      doc.text("information was given to you orally.", 70, pageline + 30);
      doc.text("The reason for your claim to be repudiated will always be communicated to you in writing.", 70, pageline + 35);
      doc.text("In case of the insurer cancelling your policy, this will be done in writing to your last known", 70, pageline + 40);
      doc.text("address You will always be entitled to a copy of your policy, free of charge.", 70, pageline + 45);
      pageline = pageline - 10;
      doc.text("For instructions to access, verify, or object to personal information processed by Shield", 70, pageline + 65);
      doc.text("Life, please view our privacy notice on www.shieldlife.co.za", 70, pageline + 70);
      doc.text("Should any of my dependants or I have any concerns about the processing of our personal", 70, pageline + 75);
      doc.text("information, we may raise the matter with Shield Life's Information Officer at", 70, pageline + 80);
      doc.text("privacy@shieldlife.co.za", 70, pageline + 85);
      if (!code || (!code.match('sll') && !code.match('tsll'))) {
        doc.setTextColor("4068a1");
        doc.setFont("Helvetica", "italic", "bold");
        doc.setFontSize(11);
        doc.text("Treating Customers Fairly (TCF) Principles", 15, pageline + 95);
        doc.setFont("Helvetica", "bold");
        doc.setFontSize(9);
        doc.setTextColor("#444");
        doc.text("Shield Life® Ltd subscribe to the following 6 TCF principles during product creation to meet your requirements and", 15, pageline + 100);
        doc.text("expectation:", 15, pageline + 105);
        pageline = pageline + 5;
        doc.text("Culture", 25, pageline + 105);
        doc.text("Right Product", 25, pageline + 110);
        doc.text("Informed", 25, pageline + 115);
        doc.text("Suitable Advice", 25, pageline + 120);
        doc.text("Meet Expectations", 25, pageline + 125);
        doc.text("Excellent Service", 25, pageline + 130);
        doc.setFont("Helvetica", "normal");
        doc.text("Consumers are confident that fair treatment is central to our Company's culture", 60, pageline + 105);
        doc.text("Consumers have Products & Services that meet their needs", 60, pageline + 110);
        doc.text("Consumers received communication that is clear and in understandable language", 60, pageline + 115);
        doc.text("Consumers receive suitable advice aimed at their circumstances", 60, pageline + 120);
        doc.text("Consumers receive Products & Services that will meet their expectations", 60, pageline + 125);
        doc.text("Consumers experience no post-sale barriers", 60, pageline + 130);
        textline = pageline + 135;
        pageline = textline;
      } else pageline = pageline + 90;
    } else if (shieldData) {
      // *********************************ShildAPP
      // *******************************************Payroll Deduction Member Application Form
      doc.setFont('Helvetica', 'bold')
      doc.setTextColor('#444')
      doc.setFontSize(16)
      doc.text('SA Army Foundation', 75, 21)
      if (shieldData.agentCode) doc.text(shieldData.agentCode, 160, 21)
      doc.text('Member Application', 75, 31)
      doc.setFontSize(9)
      doc.setFont('Helvetica', 'normal')
      doc.setTextColor('red')
      doc.text('Please Note:', 15, 40)
      doc.setTextColor('#444')
      doc.text('In terms of the Financial Intelligence Centre Amendment Act 1 of 2017 and to enhance the on-boarding and claims', 15, 45)
      doc.text('processes, we will require a copy of the following documentation to accompany this application:', 15, 50)
      doc.text('1. Identification of the person apply (ID book/card or Driver License)', 15, 55)
      doc.text('2. Identification of the nominated beneficiary , if available (ID book/card or Driver License)', 15, 60)
      doc.setFont('Helvetica', 'italic')
      doc.text('if applicable;', 15, 65)
      doc.setFont('Helvetica', 'normal')
      doc.text('3. Identification of each spouse covered under this application (ID book/card or Driver License)', 15, 70)
      doc.text('4. Marriage Certificate or equivalent', 15, 75)
      doc.text('5. Identification of each dependent child covered under this application (ID book/card or Birth Certificate)', 15, 80)
      doc.setFont('Helvetica', 'bold')
      doc.setFontSize(11)
      doc.text('1. Legislation information and requirements:', 15, 90)
      doc.setFont('Helvetica', 'normal')
      doc.setFontSize(9)
      doc.text('This policy is governed by South African Law and any legal action in relation to this policy is subject to the', 15, 95)
      doc.text('jurisdiction of the South African Courts.', 15, 100)
      doc.setFillColor('#FFEFED')
      doc.rect(15, 105, 166, 5, 'FD')
      doc.setFont('Helvetica', 'bold')
      doc.text('Personal Information', 85, 108.5)
      doc.rect(15, 110, 166, 53, 'S')
      doc.setFont('Helvetica', 'normal')
      doc.text('In South Africa, the Protection of Personal Information Act 4 of 2013 (PoPIA) has been enacted to regulate the', 17, 115)
      doc.text('processing of Personal Information. When entering into this policy you provide us with information that may be', 17, 120)
      doc.text('protected by legislation including but not limited to PoPIA.  We will take all reasonable steps to protect the', 17, 125)
      doc.text('information you provide us securely and confidentially.', 17, 130)
      doc.text('I hereby confirm that my information and all information supplied by me may be processed for the following reasons.', 17, 135)
      doc.text('I hereby confirm that I have the legal right to supply the necessary information in this application and the legal', 17, 140)
      doc.text('right to give consent to process the personal / sensitive  / special information for the following reasons:', 17, 145)
      doc.text('ü Army Foundation membership', 17, 150)
      doc.text('ü All Insurance cover being applied for', 17, 155)
      doc.text('ü Any processing required for the Insurance product being applied for', 17, 160)
      doc.setFillColor('#FFEFED')
      doc.rect(15, 165, 166, 5, 'FD')
      doc.setFont('Helvetica', 'bold')
      doc.text('Sharing of Insurance Information', 75, 168.5)
      doc.rect(15, 170, 166, 100, 'S')
      doc.setFont('Helvetica', 'normal')
      doc.text('The South African Regulatory bodies require insurers to share information with the regulatory body regarding', 17, 175)
      doc.text('policies and claims. In addition, insurers share information with each other regarding policies and claims with the', 17, 180)
      doc.text('view to prevent fraudulent claims and obtain material information regarding the assessment of risks proposed', 17, 185)
      doc.text('for insurance. By reducing the incidents of fraud and assessing risks fairly, future premium increases may be limited.', 17, 190)
      doc.text('This is done in the public interest and in the interest of all current and potential policyholders.', 17, 195)
      doc.text('By the insurer accepting or renewing this insurance, you or any other person that is represented', 17, 200)
      doc.text('herein, gives consent to the said information being disclosed to any other insurance company or its agent.', 17, 205)
      doc.text('You also similarly give consent to the sharing of information in regards to past insurance policies and', 17, 210)
      doc.text('claims that you have made. You also acknowledge that information provided by yourself or your', 17, 215)
      doc.text('representative may be verified against any legally recognised sources or databases.', 17, 220)
      doc.text('By insuring or renewing your insurance you hereby not only consent to such information sharing, but', 17, 225)
      doc.text('also waive any rights of confidentiality with regards to underwriting or claims information that you', 17, 230)
      doc.text('have provided or that has been provided by another person on your behalf.', 17, 235)
      doc.text('In the event of a claim, the information you have supplied with your application, together with the', 17, 240)
      doc.text('information you supply in relation to the claim, will be made available to other insurers participating', 17, 245)
      doc.text('in the sharing of insurance information.', 17, 250)
      doc.text('As a member of the SA Army Foundation, you and your dependants personal information may be shared with the', 17, 255)
      doc.text('employer. This will be limited to information that is relevant to you and/or your dependants’ application or', 17, 260)
      doc.text('information that is required for the ongoing servicing of your membership.', 17, 265)
      doc.addPage()
      doc.setFillColor('#FFEFED')
      doc.rect(15, 15, 166, 5, 'FD')
      doc.setFont('Helvetica', 'bold')
      // Member due diligence 1
      doc.text('Member Due Diligence', 75, 18.5)
      doc.rect(15, 20, 166, 60, 'S')
      doc.setFont('Helvetica', 'normal')
      doc.text('As part of our responsibilities as an Accountable Institution in terms of the Financial Intelligence Centre', 17, 25)
      doc.text('Amendment Act 1 of 2017, we are required to gather the following member information prior to concluding', 17, 30)
      doc.text('the transaction. The nature and purpose of this transaction is to secure Group Life and Funeral cover.', 17, 35)
      doc.text('What is the source of Funds?', 17, 40)
      doc.setFont('Helvetica', 'bold')
      if (shieldData.sourceIncome) doc.text(shieldData.sourceIncome, 70, 40)
      if (shieldData.sourceIncome && shieldData.sourceIncome === 'Other') { doc.text('Other: _____________', 140, 40) }
      doc.setFont('Helvetica', 'normal')
      doc.text('I do hereby declare that the source of the funds that I expect to use in concluding transactions with', 17, 45)
      doc.text('Shield Life® Ltd originates from the sources indicated above. I further confirm that these funds are', 17, 50)
      doc.text('derived from legitimate sources.', 17, 55)
      doc.addImage(signature, 'JPEG', 25, 55, 35, 35, 'final', 'MEDIUM')
      doc.text('____________________________', 20, 75)
      doc.text('Signature of Main Member', 25, 79)
      doc.text(`Date: ${timeStamp}`, 72, 77)
      doc.setFont('Helvetica', 'bold')
      doc.setFontSize(11)
      doc.text('2. Compulsory personal information:', 15, 87)
      // General Details
      doc.setFontSize(9)
      doc.setFillColor('#FFEFED')
      doc.rect(15, 90, 166, 5, 'FD')
      doc.setFont('Helvetica', 'bold')
      doc.text('General Details', 85, 93.5)
      doc.setFont('Helvetica', 'normal')
      doc.rect(15, 95, 166, 5, 'S')
      doc.text('Force No:', 17.5, 99)
      if (shieldData.forceNumber) doc.text(shieldData.forceNumber, 44, 99)
      doc.rect(15, 95, 24, 5, 'S')
      doc.text('Surname:', 93, 99)
      if (shieldData.surname) doc.text(shieldData.surname, 119, 99)
      doc.rect(90, 95, 24, 5, 'S')
      doc.rect(15, 100, 166, 5, 'S')
      doc.text('Rank/Title:', 17.5, 104)
      if (shieldData.rankTitle) doc.text(shieldData.rankTitle, 44, 104)
      doc.rect(15, 100, 24, 5, 'S')
      doc.text('ID Number:', 93, 104)
      if (shieldData.IDNumber) doc.text(shieldData.IDNumber, 119, 104)
      doc.rect(90, 100, 24, 5, 'S')
      doc.rect(15, 105, 166, 5, 'S')
      doc.text('Full Names:', 17.5, 109)
      if (shieldData.fullNames) doc.text(shieldData.fullNames, 44, 109)
      doc.rect(15, 105, 24, 5, 'S')
      doc.text('Marital Status:', 93, 109)
      if (shieldData.maritalStatus) doc.text(shieldData.maritalStatus, 119, 109)
      doc.rect(90, 105, 24, 5, 'S')
      // electronic Contact details
      doc.setFillColor('#FFEFED')
      doc.rect(15, 110, 166, 5, 'FD')
      doc.setFont('Helvetica', 'bold')
      doc.text('Electronic Contact Details', 78, 113.5)
      doc.setFont('Helvetica', 'normal')
      doc.rect(15, 115, 166, 5, 'S')
      doc.text('Cell Number:', 17.5, 119)
      if (shieldData.cellNumber) doc.text(shieldData.cellNumber, 44, 119)
      doc.rect(15, 115, 24, 5, 'S')
      doc.text('Home Email Address:', 93, 119)
      if (shieldData.emailAddress) doc.text(shieldData.emailAddress, 129, 119)
      doc.rect(90, 115, 35, 5, 'S')
      doc.rect(15, 120, 166, 5, 'S')
      doc.text('Landline Number:', 17.5, 124)
      if (shieldData.landlineNumber) doc.text(shieldData.landlineNumber, 55, 124)
      doc.rect(15, 120, 35, 5, 'S')
      doc.text('Email Address:', 93, 124)
      if (shieldData.forceNumber) doc.text(`${shieldData.forceNumber}@myarmyfoundation.co.za`, 129, 124)
      doc.rect(90, 120, 35, 5, 'S')
      // arms of service
      doc.setFillColor('#FFEFED')
      doc.rect(15, 125, 166, 5, 'FD')
      doc.setFont('Helvetica', 'bold')
      doc.text('Arms of Service', 85, 128.5)
      doc.setFont('Helvetica', 'normal')
      doc.rect(15, 130, 166, 5, 'S')
      doc.text('Unit:', 17.5, 134)
      if (shieldData.unitName) doc.text(shieldData.unitName, 44, 134)
      doc.rect(15, 130, 24, 5, 'S')
      doc.text('Unit Switchboard No:', 93, 134)
      if (shieldData.unitSwitchBoardNumber) doc.text(shieldData.unitSwitchBoardNumber, 129, 134)
      doc.rect(90, 130, 35, 5, 'S')
      // Physical Address
      doc.setFillColor('#FFEFED')
      doc.rect(15, 135, 166, 5, 'FD')
      doc.setFont('Helvetica', 'bold')
      doc.text('Physical Address', 85, 138.5)
      doc.setFont('Helvetica', 'normal')
      doc.rect(15, 140, 166, 5, 'S')
      doc.text('Street and Numbers:', 17.5, 144)
      if (shieldData.streetName) doc.text(`${shieldData.streetNumber} ${shieldData.streetName}`, 55, 144)
      doc.rect(15, 140, 35, 5, 'S')
      doc.text('Suburb:', 93, 144)
      doc.text(shieldData.suburb, 129, 144)
      doc.rect(90, 140, 24, 5, 'S')
      doc.rect(15, 145, 166, 5, 'S')
      doc.rect(90, 145, 24, 5, 'S')
      doc.text('Complex:', 93, 149)
      if (shieldData.complexName) doc.text(shieldData.complexName, 120, 149)
      doc.text('Street Code:', 17.5, 149)
      if (shieldData.postalCode) doc.text(shieldData.postalCode, 55, 149)
      doc.rect(15, 150, 166, 5, 'S')
      doc.text('Is the postal address the same as the Residential address?  If no, please complete:', 17.5, 154)
      doc.setFont('Helvetica', 'bold')
      doc.text('Yes', 150, 154)
      doc.setFont('Helvetica', 'normal')
      // Postal Address
      doc.setFillColor('#FFEFED')
      doc.rect(15, 155, 166, 5, 'FD')
      doc.setFont('Helvetica', 'bold')
      doc.text('Postal Address', 85, 158.5)
      doc.setFont('Helvetica', 'normal')
      doc.rect(15, 160, 166, 5, 'S')
      doc.text('Postal Number:', 17.5, 164)
      if (shieldData.streetName) doc.text(`${shieldData.streetNumber} ${shieldData.streetName}`, 55, 164)
      doc.rect(15, 160, 35, 5, 'S')
      doc.text('Suburb:', 93, 164)
      doc.text(shieldData.suburb, 129, 164)
      doc.rect(90, 160, 24, 5, 'S')
      doc.rect(15, 165, 166, 5, 'S')
      if (shieldData.postalCode) doc.text(`Postal Code: ${shieldData.postalCode}`, 17.5, 169)
      // 3. Compulsory Beneficiary Information
      doc.setFont('Helvetica', 'bold')
      doc.setFontSize(11)
      doc.text('3. Compulsory Beneficiary Information', 15, 177)
      // header block
      doc.setFontSize(9)
      doc.setFillColor('#FFEFED')
      doc.rect(15, 180, 166, 5, 'FD')
      doc.setFont('Helvetica', 'bold')
      doc.text('Details of Primary Beneficiary (adult over the age of 18  - person responsible for funeral)', 30, 183.5)
      doc.setFont('Helvetica', 'normal')
      // blocks see through
      doc.rect(15, 185, 166, 5, 'S')
      doc.rect(15, 185, 35, 5, 'S')
      doc.rect(90, 185, 24, 5, 'S')
      doc.rect(15, 190, 166, 5, 'S')
      doc.rect(15, 190, 35, 5, 'S')
      doc.rect(90, 190, 24, 5, 'S')
      doc.rect(15, 195, 166, 5, 'S')
      doc.rect(15, 195, 35, 5, 'S')
      doc.rect(90, 195, 24, 5, 'S')
      // text
      doc.text('ID Number:', 17.5, 189)
      if (shieldData.pbID) doc.text(shieldData.pbID, 55, 189)
      doc.text('Cell Number', 93, 189)
      if (shieldData.pbCellNumber) doc.text(shieldData.pbCellNumber, 129, 189)
      doc.text('Full Names:', 17.5, 194)
      if (shieldData.pbFullNames) doc.text(shieldData.pbFullNames, 55, 194)
      doc.text('Landline No:', 93, 194)
      if (shieldData.pbLandline) doc.text(shieldData.pbLandline, 129, 194)
      doc.text('Surname:', 17.5, 199)
      if (shieldData.pbSurname) doc.text(shieldData.pbSurname, 55, 199)
      doc.text('Relationship', 93, 199)
      if (shieldData.pbRelationship) doc.text(shieldData.pbRelationship, 129, 199)
      // Details of Next-in-line Beneficiary (adult over the age of 18) *
      // header block
      doc.setFontSize(9)
      doc.setFillColor('#FFEFED')
      doc.rect(15, 200, 166, 5, 'FD')
      doc.setFont('Helvetica', 'bold')
      doc.text('Details of Next-in-line Beneficiary (adult over the age of 18)', 50, 203.5)
      doc.setFont('Helvetica', 'normal')
      // blocks see through
      doc.rect(15, 205, 166, 5, 'S')
      doc.rect(15, 205, 35, 5, 'S')
      doc.rect(90, 205, 24, 5, 'S')
      doc.rect(15, 210, 166, 5, 'S')
      doc.rect(15, 210, 35, 5, 'S')
      doc.rect(90, 210, 24, 5, 'S')
      doc.rect(15, 215, 166, 5, 'S')
      doc.rect(15, 215, 35, 5, 'S')
      doc.rect(90, 215, 24, 5, 'S')
      // text
      doc.text('ID Number:', 17.5, 209)
      if (shieldData.nlIDNumber) doc.text(shieldData.nlIDNumber, 55, 209)
      doc.text('Cell Number', 93, 209)
      if (shieldData.nlCellNumber) doc.text(shieldData.nlCellNumber, 129, 209)
      doc.text('Full Names:', 17.5, 214)
      if (shieldData.nlFullNames) doc.text(shieldData.nlFullNames, 55, 214)
      doc.text('Landline No:', 93, 214)
      if (shieldData.nlLandline) doc.text(shieldData.nlLandline, 129, 214)
      doc.text('Surname:', 17.5, 219)
      if (shieldData.nlSurname) doc.text(shieldData.nlSurname, 55, 219)
      doc.text('Relationship', 93, 219)
      if (shieldData.nlRelationship) doc.text(shieldData.nlRelationship, 129, 219)
      doc.setFontSize(7)
      doc.setFont('Helvetica', 'italic')
      doc.text('* Please note:  The next-in-line beneficiary will only receive the benefit payment if the primary beneficiary is predeceased', 15, 223)
      doc.setFont('Helvetica', 'normal')
      // 4. Main member immediate family members information, if applicable:
      doc.setFont('Helvetica', 'bold')
      doc.setFontSize(11)
      doc.text('4. Main member immediate family members information, if applicable:', 15, 233)
      // Details of Spouse
      // header block
      doc.setFontSize(9)
      doc.setFillColor('#FFEFED')
      doc.rect(15, 235, 166, 5, 'FD')
      doc.setFont('Helvetica', 'bold')
      doc.text('Details of Spouse', 40, 238.5)
      doc.text('Details of Additional Spouse', 110, 238.5)
      doc.setFont('Helvetica', 'normal')
      // blocks see through
      doc.rect(15, 240, 166, 5, 'S')
      doc.rect(15, 240, 35, 5, 'S')
      doc.rect(90, 240, 35, 5, 'S')
      doc.rect(15, 245, 166, 5, 'S')
      doc.rect(15, 245, 35, 5, 'S')
      doc.rect(90, 245, 35, 5, 'S')
      doc.rect(15, 250, 166, 5, 'S')
      doc.rect(15, 250, 35, 5, 'S')
      doc.rect(90, 250, 35, 5, 'S')
      // text
      doc.text('Name & Surname:', 17.5, 244)
      doc.text('ID Number:', 17.5, 249)
      doc.text('Contact Number', 17.5, 254)
      if (shieldData.spouseFullNames) doc.text(`${shieldData.spouseFullNames} ${shieldData.spouseSurname}`, 55, 244)
      if (shieldData.spouseID) doc.text(shieldData.spouseID, 55, 249)
      if (shieldData.spouseCell) doc.text(shieldData.spouseCell, 55, 254)
      doc.text('Name & Surname:', 93, 244)
      doc.text('ID Number:', 93, 249)
      doc.text('Contact Number', 93, 254)
      // Details of Children (under the age of 21 years; 26 years if full time student)
      doc.addPage()
      // header block
      doc.setFontSize(9)
      doc.setFillColor('#FFEFED')
      doc.rect(15, 15, 166, 5, 'FD')
      doc.setFont('Helvetica', 'bold')
      doc.text('Details of Children (under the age of 21 years; 26 years if full time student)', 43, 18.5)
      doc.setFont('Helvetica', 'normal')
      // blocks see through
      doc.rect(15, 20, 166, 5, 'S')
      doc.rect(15, 25, 166, 5, 'S')
      doc.rect(15, 25, 35, 5, 'S')
      doc.rect(90, 25, 35, 5, 'S')
      doc.rect(15, 30, 166, 5, 'S')
      doc.rect(15, 30, 35, 5, 'S')
      doc.rect(90, 30, 35, 5, 'S')
      doc.rect(15, 35, 166, 5, 'S')
      doc.rect(15, 35, 35, 5, 'S')
      doc.rect(90, 35, 35, 5, 'S')
      doc.rect(15, 40, 166, 5, 'S')
      doc.rect(15, 40, 35, 5, 'S')
      doc.rect(90, 40, 35, 5, 'S')
      doc.rect(15, 45, 166, 5, 'S')
      doc.rect(15, 45, 35, 5, 'S')
      doc.rect(90, 45, 35, 5, 'S')
      doc.rect(15, 50, 166, 5, 'S')
      doc.rect(15, 50, 35, 5, 'S')
      doc.rect(90, 50, 35, 5, 'S')
      doc.rect(15, 55, 166, 5, 'S')
      doc.rect(15, 55, 35, 5, 'S')
      doc.rect(90, 55, 35, 5, 'S')
      doc.rect(15, 60, 166, 5, 'S')
      doc.rect(15, 60, 35, 5, 'S')
      doc.rect(90, 60, 35, 5, 'S')
      doc.rect(15, 65, 166, 5, 'S')
      doc.rect(15, 65, 35, 5, 'S')
      doc.rect(90, 65, 35, 5, 'S')
      doc.rect(15, 70, 166, 5, 'S')
      doc.rect(15, 70, 35, 5, 'S')
      doc.rect(90, 70, 35, 5, 'S')
      doc.rect(15, 75, 166, 5, 'S')
      doc.rect(15, 75, 35, 5, 'S')
      doc.rect(90, 75, 35, 5, 'S')
      doc.rect(15, 80, 166, 5, 'S')
      doc.rect(15, 80, 35, 5, 'S')
      doc.rect(90, 80, 35, 5, 'S')
      // text
      doc.setFont('Helvetica', 'italic')
      doc.text('Legitimate children of the member can be included as indicated / noted on the persol system.', 35, 23.5)
      doc.setFont('Helvetica', 'normal')
      // left col
      doc.text('Name & Surname:', 17.5, 29)
      doc.text('Date of Birth:', 17.5, 34)
      doc.text('Gender:', 17.5, 39)
      doc.text('Age:', 17.5, 44)
      doc.text('Name & Surname:', 17.5, 49)
      doc.text('Date of Birth:', 17.5, 54)
      doc.text('Gender:', 17.5, 59)
      doc.text('Age:', 17.5, 64)
      doc.text('Name & Surname:', 17.5, 69)
      doc.text('Date of Birth:', 17.5, 74)
      doc.text('Gender:', 17.5, 79)
      doc.text('Age:', 17.5, 84)
      // left col state
      if (shieldData.childFullNames) doc.text(`${shieldData.childFullNames} ${shieldData.childSurname}`, 55, 29)
      if (shieldData.childID) doc.text(shieldData.childID, 55, 34)
      if (shieldData.childTitle) doc.text(shieldData.childTitle, 55, 39)
      if (shieldData.childID) doc.text(shieldData.childID, 55, 44)
      if (shieldData.childFullNames2) doc.text(`${shieldData.childFullNames2} ${shieldData.childSurname2}`, 55, 49)
      if (shieldData.childID2) doc.text(shieldData.childID2, 55, 54)
      if (shieldData.childTitle2) doc.text(shieldData.childTitle2, 55, 59)
      if (shieldData.childID2) doc.text(shieldData.childID2, 55, 64)
      if (shieldData.childFullNames3) doc.text(`${shieldData.childFullNames3} ${shieldData.childSurname3}`, 55, 69)
      if (shieldData.childID3) doc.text(shieldData.childID3, 55, 74)
      if (shieldData.childTitle3) doc.text(shieldData.childTitle3, 55, 79)
      if (shieldData.childID3) doc.text(shieldData.childID3, 55, 84)
      // right col
      doc.text('Name & Surname:', 93, 29)
      doc.text('Date of Birth:', 93, 34)
      doc.text('Gender:', 93, 39)
      doc.text('Age:', 93, 44)
      doc.text('Name & Surname:', 93, 49)
      doc.text('Date of Birth:', 93, 54)
      doc.text('Gender:', 93, 59)
      doc.text('Age:', 93, 64)
      doc.text('Name & Surname:', 93, 69)
      doc.text('Date of Birth:', 93, 74)
      doc.text('Gender:', 93, 79)
      doc.text('Age:', 93, 84)
      // right col state
      if (shieldData.childFullNames4) doc.text(`${shieldData.childFullNames4} ${shieldData.childSurname4}`, 129, 29)
      if (shieldData.childID4) doc.text(shieldData.childID4, 129, 34)
      if (shieldData.childTitle4) doc.text(shieldData.childTitle4, 129, 39)
      if (shieldData.childID4) doc.text(shieldData.childID4, 129, 44)
      if (shieldData.childFullNames5) doc.text(`${shieldData.childFullNames5} ${shieldData.childSurname5}`, 129, 49)
      if (shieldData.childID5) doc.text(shieldData.childID5, 129, 54)
      if (shieldData.childTitle5) doc.text(shieldData.childTitle5, 129, 59)
      if (shieldData.childID5) doc.text(shieldData.childID5, 129, 64)
      if (shieldData.childFullNames6) doc.text(`${shieldData.childFullNames6} ${shieldData.childSurname6}`, 129, 69)
      if (shieldData.childID6) doc.text(shieldData.childID6, 129, 74)
      if (shieldData.childTitle6) doc.text(shieldData.childTitle6, 129, 79)
      if (shieldData.childID6) doc.text(shieldData.childID6, 129, 84)
      // 5. Compulsory membership details:
      doc.setFont('Helvetica', 'bold')
      doc.setFontSize(11)
      doc.text('5. Compulsory membership details:', 15, 93)
      doc.setFontSize(9)
      // Membership Details
      doc.setFillColor('#FFEFED')
      doc.rect(15, 95, 166, 5, 'FD')
      doc.setFont('Helvetica', 'bold')
      doc.text('Membership Details', 77, 99)
      doc.setFont('Helvetica', 'normal')
      doc.rect(15, 100, 166, 15, 'S')
      // text
      doc.text('Membership type', 78, 105)
      if (shieldData.childrenAmount) doc.text(`${memberType} ${shieldData.childrenAmount > 0 ? shieldData.childrenAmount : ''}`, 78, 110)
      if (shieldData.monthlyPremium) doc.text(`R${shieldData.monthlyPremium}`, 78, 114)
      // 6. Deduction details:
      if (shieldData.paymentMethod === 'Payroll') {
        doc.setFont('Helvetica', 'bold')
        doc.setFontSize(11)
        doc.text('6. Deduction details:', 15, 123)
        doc.setFontSize(9)
        // Stop Order (Persol deduction) Mandate
        doc.setFillColor('#FFEFED')
        doc.rect(15, 125, 166, 5, 'FD')
        doc.setFont('Helvetica', 'bold')
        doc.text('Stop Order (Persol deduction) Mandate', 60, 129)
        doc.setFont('Helvetica', 'normal')
        doc.rect(15, 130, 83, 5, 'S')
        doc.rect(15, 135, 83, 5, 'S')
        doc.rect(15, 140, 83, 5, 'S')
        doc.rect(15, 145, 83, 5, 'S')
        doc.rect(98, 130, 83, 5, 'S')
        doc.rect(98, 135, 83, 5, 'S')
        doc.rect(98, 140, 83, 5, 'S')
        doc.rect(98, 145, 83, 5, 'S')
        // text
        doc.text('Full Names & Surname', 16.5, 134)
        doc.text('ID Number:', 16.5, 139)
        doc.text('Rank/Title', 16.5, 144)
        doc.text('Start date:', 16.5, 149)
        doc.text('Force No:', 100, 134)
        doc.text('Unit', 100, 139)
        doc.text('Payslip reference:', 100, 144)
        doc.text('End date:', 100, 149)
        doc.text(`- ${shieldData.fullNames} ${shieldData.surname}`, 50, 134)
        doc.text(`- ${shieldData.IDNumber}`, 33, 139)
        doc.text(`- ${shieldData.rankTitle}`, 33, 144)
        doc.setFontSize(9)
        doc.text('1st of May 2022', 33, 149)
        doc.setFontSize(9)
        if (shieldData.forceNumber) doc.text(shieldData.forceNumber, 116.5, 134)
        if (shieldData.unitName) doc.text(shieldData.unitName, 116.5, 139)
        doc.text('ARMY FND: FUNDS', 126.5, 144)
        doc.text('until I formally cancel this Policy', 116.5, 149)
        doc.rect(15, 150, 166, 23, 'S')
        doc.text('I authorise the Accountant of the Department of Defence to deduct the monthly payments from my salary as per', 16, 155)
        doc.text('the above authority provided and remit it to the SA Army Foundation of which I am a member,  each and every', 16, 160)
        doc.text('month and continuing until cancelled by me in writing or until I substitute it with a new authorisation.', 16, 165)
        doc.text('I have read and accepted the terms and conditions as set out below.', 16, 170)
        // Terms & Conditions
        doc.rect(15, 175, 166, 65, 'S')
        doc.setFont('Helvetica', 'bold')
        doc.text('Terms & Conditions', 16.5, 180)
        doc.setFont('Helvetica', 'normal')
        doc.text('I accept that the authority may be cancelled by me by giving 31 (thirty one) days notice in writing.', 16.5, 185)
        doc.text('I understand that should I cancel the authority after the 31-day period, I shall not be entitled to any', 16.5, 190)
        doc.text('refund of amounts which have been withdrawn while this authority was in force, if such amounts were legally', 16.5, 195)
        doc.text('owing to you.Should the premium rate be adjusted by the SA Army Foundation as a result of a general', 16.5, 200)
        doc.text('decrease/increase in subscription or should I request the SA Army Foundation to decrease/increase the', 16.5, 205)
        doc.text('premium for certain reason, I confirm that the adjusted premium may be deducted.I acknowledge that', 16.5, 210)
        doc.text('this Authority may be ceded or assigned to a third party for collection purposes.', 16.5, 215)
        // signature
        doc.addImage(signature, 'JPEG', 25, 210, 35, 35, 'final', 'MEDIUM')
        doc.text('____________________________', 20, 230)
        doc.text('Signature of Main Member', 25, 235)
        doc.text(`Date: ${timeStamp}`, 72, 235)
      } else {
        doc.setFontSize(11)
        doc.setFont('Helvetica', 'bold')
        doc.text('6. Deduction details:', 15, 123)
        doc.setFontSize(9)
        // Stop Order (Persol deduction) Mandate
        doc.setFillColor('#FFEFED')
        doc.rect(15, 125, 166, 5, 'FD')
        doc.setFont('Helvetica', 'bold')
        doc.text('Debit Order Mandate', 77, 129)
        doc.setFont('Helvetica', 'normal')
        doc.rect(15, 130, 83, 5, 'S')
        doc.rect(15, 135, 83, 5, 'S')
        doc.rect(15, 140, 83, 5, 'S')
        doc.rect(15, 145, 83, 5, 'S')
        doc.rect(98, 130, 83, 5, 'S')
        doc.rect(98, 135, 83, 5, 'S')
        doc.rect(98, 140, 83, 5, 'S')
        doc.rect(98, 145, 83, 5, 'S')
        // text
        doc.text('Bank:', 16.5, 134)
        doc.text('Account Type:', 16.5, 139)
        doc.text('Account No', 16.5, 144)
        doc.text('Branch Code:', 16.5, 149)
        doc.text('Acount Holder Name:', 100, 134)
        doc.text('Start Date', 100, 139)
        doc.text('Bank Statement ref:', 100, 144)
        doc.text('End date:', 100, 149)
        if (shieldData.bankingDetailsDO && shieldData.bankingDetailsDO.length > 0) {
          doc.text(`${shieldData.bankingDetailsDO[0].Bank}`, 40, 134)
          doc.text(`${shieldData.bankingDetailsDO[1]['Account Type']}`, 40, 139)
          doc.text(`${shieldData.bankingDetailsDO[2]['Account Number']}`, 40, 144)
          doc.text(`${shieldData.bankingDetailsDO[3]['Branch Code']}`, 40, 149)
        }

        doc.setFontSize(9)
        doc.setFontSize(9)
        doc.text(`-${shieldData.fullNames} ${shieldData.surname}`, 132, 134)
        doc.text('1st of May 2022', 132, 139)
        doc.text('ARMYFUND', 132, 144)
        doc.text('Until I formally cancel this Policy', 132, 149)
        doc.rect(15, 150, 166, 30, 'S')
        doc.text('I authorise the SA Army Foundation to deduct the monthly payments from this account as per the above authority', 16, 155)
        doc.text('provided.  I have read and accepted the debit order terms and conditions as set out below.', 16, 160)
        doc.text('I instruct and authorise the SA Army Foundation to draw against my nominated bank account (or any other bank ', 16, 165)
        doc.text('or branch to which I may transfer my account) the amount necessary for the payment of the monthly premium', 16, 170)
        // Terms & Conditions
        doc.rect(15, 175, 166, 115, 'S')
        doc.setFont('Helvetica', 'bold')
        doc.text('Terms & Conditions', 16.5, 178.5)
        doc.setFont('Helvetica', 'normal')
        doc.text('due in respect of my membership, on the day nominated by me each and every month and continuing until', 16.5, 185)
        doc.text('cancelled by me in writing or until I substitute it with a new authorisation. I accept that the authority', 16.5, 190)
        doc.text('may be cancelled by me by giving 31 (thirty one) days notice in writing.', 16.5, 195)
        doc.text('I understand that should I cancel the authority after the 31-day period, I shall not be entitled to any', 16.5, 200)
        doc.text('refund of amounts which have been withdrawn while this authority was in force, if such amounts were legally', 16.5, 205)
        doc.text('owing to you. I understand that the withdrawals hereby authorised will be processed through a', 16.5, 210)
        doc.text('computerised system provided by the South African Banking Industry and that the details of each withdrawal', 16.5, 215)
        doc.text('will be printed on my bank statement. I agree that in the event that the payment date falls on a Saturday,', 16.5, 220)
        doc.text('Sunday or recognised South African public holiday, the payment day will automatically be the first preceding', 16.5, 225)
        doc.text('business day.I agree that should there be insufficient funds in my account to meet the obligation, the SA Army', 16.5, 230)
        doc.text('Foundation  are entitled  to track my account and re-present the instruction for payment as soon as sufficient', 16.5, 235)
        doc.text('funds are available in my account. Such will contain the SA Army Foundation as reference which shall enable me', 16.5, 240)
        doc.text('to match the withdrawals with this Membership agreement. I agree to pay any bank charges relating to this debit', 16.5, 245)
        doc.text('order instruction. Should the premium rate be adjusted by the SA Army Foundation as a result of a general', 16.5, 250)
        doc.text('decrease/increase in subscription or should I request the SA Army Foundation to decrease/increase the premium', 16.5, 255)
        doc.text('for certain reason, I confirm that the adjusted premium may be deducted from my bank account. I acknowledge that', 16.5, 260)
        doc.text('this Authority may be ceded or assigned to a third party for collection purposes.', 16.5, 265)
        // signature
        doc.addImage(signature, 'JPEG', 25, 255, 35, 35, 'final', 'MEDIUM')
        doc.text('____________________________', 20, 280)
        doc.text('Signature of Main Member', 25, 285)
        doc.text(`Date: ${timeStamp}`, 72, 285)
      }
      doc.addPage()
      // 7. Declaration
      doc.setFont('Helvetica', 'bold')
      doc.setFontSize(11)
      doc.text('7. Declaration', 15, 15)
      doc.setFontSize(8)
      doc.rect(15, 18, 166, 135, 'S')
      doc.text('By signing this declaration I, the undersigned, consent that my personal information may be transmitted to any third-party', 16, 22)
      doc.text('service provider (Premium Collection Agencies; Consumer Profile Bureau, etc.) that may be appointed to perform ', 16, 27)
      doc.text('functions relating to my policy on behalf of the SA Army Foundation and the Insurer.', 16, 32)
      doc.text('I hereby consent that my personal information may be used for marketing purposes *:', 16, 37)
      doc.text("* Marketing means the marketing of SA Army Foundation's new or enhance offerings promoted by the SA Army", 16, 42)
      doc.text("Foundation; Maxi Forces Financial Services (Pty) Ltd (the broker) and/or Shield Life® Ltd (the Insurer).", 16, 47)
      doc.text('I understand that without the above consent my policy will not be processed and the SA Army Foundation', 16, 52)
      doc.text('will not be liable for any claim that may arise. I understand that the cover will only start when my', 16, 57)
      doc.text('application has been accepted and I have paid the first premium.I have given all information correctly', 16, 62)
      doc.text('and understand that if any information is found to be untrue, my benefit may not be paid or my policy', 16, 67)
      doc.text('could be cancelled. I also agree to notify the Insurer in writing on the applicable form, of any changes', 16, 72)
      doc.text('to the information I have provided. I authorise the Insurer to communicate with me regarding my policy', 16, 77)
      doc.text('via Short Message System ("SMS"); WhatsApp; and/or email.', 16, 82)
      //
      doc.text('It is your responsibility as owner of the policy to make sure that Shield Life® Ltd always has up-to-date', 16, 87)
      doc.text('contact information for you and anyone that can benefit on this contract.  Where Shield Life® Ltd becomes', 16, 92)
      doc.text('aware that there are benefits due to be paid out on the policy, we will always first try to contact you', 16, 97)
      doc.text('or your beneficiaries at the last address provided to us.  If we are not able to contact you at this address,', 16, 102)
      doc.text('we have to take other reasonable steps to try find the person that is entitled to the policy benefits.', 16, 107)
      doc.text('In order to do this, we may have to appoint external tracing agents.  By signing this application, you agree', 16, 112)
      doc.text('that Shield Life® Ltd can give the external tracing agents access to your personal information in order to be', 16, 117)
      doc.text('able to do any tracing.  It is also important to note that, if we have to appoint tracing agents,', 16, 122)
      doc.text('a tracing and management fee may be deducted by us form the benefit payable.', 16, 127)
      // signature
      doc.addImage(signature, 'JPEG', 25, 127, 35, 35, 'final', 'MEDIUM')
      doc.text('____________________________', 20, 145)
      doc.text('Signature of Main Member', 25, 150)
      doc.text(`Date: ${timeStamp}`, 72, 150)

      // Extended Family
      doc.addPage()
      var page4line = 0;
      var logospace = 25;
      page4line = page4line + 12;
      doc.addImage(SAArmyLogo, 'SVG', 90, page4line, 15, 15)
      doc.addImage(ShieldLogo, 'SVG', 110, page4line, 20, 20)
      doc.setFont('Helvetica', 'bold')
      doc.setFontSize(11)
      page4line = page4line + logospace;
      doc.text(`8. Family member information that need to reflect on the Membership's My Family Product:`, 15, page4line)
      doc.setFontSize(9)
      doc.setFillColor('#FFEFED')
      page4line = page4line + 3;
      doc.rect(15, page4line, 173, 5, 'FD')
      doc.setFont('Helvetica', 'bold')
      page4line = page4line + 3.5;
      var table01 = page4line;
      doc.text('Type/Relation', 17, page4line)
      doc.text('Full Name & Surname', 44, page4line)
      doc.text('Date of Birth', 108, page4line)
      //doc.text('Realtionship', 110, page4line)
      doc.text('Benefit', 140, page4line)
      doc.text('Monthly Premium*', 158, page4line)
      page4line = page4line - 3.5;
      doc.rect(15, page4line, 140, 5, 'S')
      doc.rect(15, page4line, 25, 5, 'S')
      doc.rect(105, page4line, 25, 5, 'S')
      //doc.rect(105, page4line, 30, 5, 'S')
      page4line = page4line + 5;
      doc.rect(15, page4line, 140, 10, 'S')
      doc.rect(15, page4line, 25, 10, 'S')
      doc.rect(105, page4line, 25, 10, 'S')
      //doc.rect(105, page4line, 30, 10, 'S')
      doc.rect(155, page4line, 33, 10, 'S')
      page4line = page4line + 10;
      doc.rect(15, page4line, 140, 10, 'S')
      doc.rect(15, page4line, 25, 10, 'S')
      doc.rect(105, page4line, 25, 10, 'S')
      //doc.rect(105, page4line, 30, 10, 'S')
      doc.rect(155, page4line, 33, 10, 'S')
      page4line = page4line + 10;
      doc.rect(15, page4line, 140, 10, 'S')
      doc.rect(15, page4line, 25, 10, 'S')
      doc.rect(105, page4line, 25, 10, 'S')
      //doc.rect(105, page4line, 30, 10, 'S')
      doc.rect(155, page4line, 33, 10, 'S')
      page4line = page4line + 10;
      doc.rect(15, page4line, 140, 10, 'S')
      doc.rect(15, page4line, 25, 10, 'S')
      doc.rect(105, page4line, 25, 10, 'S')
      //doc.rect(105, page4line, 30, 10, 'S')
      doc.rect(155, page4line, 33, 10, 'S')
      page4line = page4line + 10;
      doc.rect(15, page4line, 140, 10, 'S')
      doc.rect(15, page4line, 25, 10, 'S')
      doc.rect(105, page4line, 25, 10, 'S')
      //doc.rect(105, page4line, 30, 10, 'S')
      doc.rect(155, page4line, 33, 10, 'S')
      page4line = page4line + 10;
      doc.rect(15, page4line, 140, 10, 'S')
      doc.rect(15, page4line, 25, 10, 'S')
      doc.rect(105, page4line, 25, 10, 'S')
      //doc.rect(105, page4line, 30, 10, 'S')
      doc.rect(155, page4line, 33, 10, 'S')
      page4line = page4line + 10;
      doc.rect(15, page4line, 140, 10, 'S')
      doc.rect(15, page4line, 25, 10, 'S')
      doc.rect(105, page4line, 25, 10, 'S')
      //doc.rect(105, page4line, 30, 10, 'S')
      doc.rect(155, page4line, 33, 10, 'S')
      page4line = page4line + 10;
      doc.rect(15, page4line, 140, 10, 'S')
      doc.rect(15, page4line, 25, 10, 'S')
      doc.rect(105, page4line, 25, 10, 'S')
      //doc.rect(105, page4line, 30, 10, 'S')
      doc.rect(155, page4line, 33, 10, 'S')
      page4line = page4line + 10;

      // doc.rect(90, 63, 57, 5, 'S')
      doc.rect(155, page4line, 33, 5, 'S')
      // doc.rect(90, 68, 57, 5, 'S')
      page4line = page4line + 5;
      doc.rect(155, page4line, 33, 5, 'S') //63

      if (shieldData.memberRelation) doc.text(`${shieldData.memberRelation}`, 16, table01 + 5)
      if (shieldData.memberRelation2) doc.text(`${shieldData.memberRelation2}`, 16, table01 + 15)
      if (shieldData.memberRelation3) doc.text(`${shieldData.memberRelation3}`, 16, table01 + 25)
      if (shieldData.memberRelation4) doc.text(`${shieldData.memberRelation4}`, 16, table01 + 35)
      if (shieldData.memberRelation5) doc.text(`${shieldData.memberRelation5}`, 16, table01 + 45)
      if (shieldData.memberRelation6) doc.text(`${shieldData.memberRelation6}`, 16, table01 + 55)
      if (shieldData.memberRelation7) doc.text(`${shieldData.memberRelation7}`, 16, table01 + 65)
      if (shieldData.memberRelation8) doc.text(`${shieldData.memberRelation8}`, 16, table01 + 75)

      if (shieldData.name) doc.text(`${shieldData.name} ${shieldData.relationSurname}`, 42, table01 + 5)
      if (shieldData.name2) doc.text(`${shieldData.name2} ${shieldData.relationSurname2}`, 42, table01 + 15)
      if (shieldData.name3) doc.text(`${shieldData.name3} ${shieldData.relationSurname3}`, 42, table01 + 25)
      if (shieldData.name4) doc.text(`${shieldData.name4} ${shieldData.relationSurname4}`, 42, table01 + 35)
      if (shieldData.name5) doc.text(`${shieldData.name5} ${shieldData.relationSurname5}`, 42, table01 + 45)
      if (shieldData.name6) doc.text(`${shieldData.name6} ${shieldData.relationSurname6}`, 42, table01 + 55)
      if (shieldData.name7) doc.text(`${shieldData.name7} ${shieldData.relationSurname7}`, 42, table01 + 65)
      if (shieldData.name8) doc.text(`${shieldData.name8} ${shieldData.relationSurname8}`, 42, table01 + 75)

      if (shieldData.dateOfBirth) doc.text(`${shieldData.dateOfBirth}`, 109, table01 + 5)
      if (shieldData.dateOfBirth2) doc.text(`${shieldData.dateOfBirth2}`, 109, table01 + 15)
      if (shieldData.dateOfBirth3) doc.text(`${shieldData.dateOfBirth3}`, 109, table01 + 25)
      if (shieldData.dateOfBirth4) doc.text(`${shieldData.dateOfBirth4}`, 109, table01 + 35)
      if (shieldData.dateOfBirth5) doc.text(`${shieldData.dateOfBirth5}`, 109, table01 + 45)
      if (shieldData.dateOfBirth6) doc.text(`${shieldData.dateOfBirth6}`, 109, table01 + 55)
      if (shieldData.dateOfBirth7) doc.text(`${shieldData.dateOfBirth7}`, 109, table01 + 65)
      if (shieldData.dateOfBirth8) doc.text(`${shieldData.dateOfBirth8}`, 109, table01 + 75)

      if (shieldData.relationship) doc.text(`${shieldData.relationship}`, 16, table01 + 10)
      if (shieldData.relationship2) doc.text(`${shieldData.relationship2}`, 16, table01 + 20)
      if (shieldData.relationship3) doc.text(`${shieldData.relationship3}`, 16, table01 + 30)
      if (shieldData.relationship4) doc.text(`${shieldData.relationship4}`, 16, table01 + 40)
      if (shieldData.relationship5) doc.text(`${shieldData.relationship5}`, 16, table01 + 50)
      if (shieldData.relationship6) doc.text(`${shieldData.relationship6}`, 16, table01 + 60)
      if (shieldData.relationship7) doc.text(`${shieldData.relationship7}`, 16, table01 + 70)
      if (shieldData.relationship8) doc.text(`${shieldData.relationship8}`, 16, table01 + 80)

      if (shieldData.benefit) doc.text(`${shieldData.benefit}`, 140, table01 + 5)
      if (shieldData.benefit2) doc.text(`${shieldData.benefit2}`, 140, table01 + 15)
      if (shieldData.benefit3) doc.text(`${shieldData.benefit3}`, 140, table01 + 25)
      if (shieldData.benefit4) doc.text(`${shieldData.benefit4}`, 140, table01 + 35)
      if (shieldData.benefit5) doc.text(`${shieldData.benefit5}`, 140, table01 + 45)
      if (shieldData.benefit6) doc.text(`${shieldData.benefit6}`, 140, table01 + 55)
      if (shieldData.benefit7) doc.text(`${shieldData.benefit7}`, 140, table01 + 65)
      if (shieldData.benefit8) doc.text(`${shieldData.benefit8}`, 140, table01 + 75)

      if (shieldData.premium) doc.text(`R${shieldData.premium}.00`, 170, table01 + 5)
      if (shieldData.premium2) doc.text(`R${shieldData.premium2}.00`, 170, table01 + 15)
      if (shieldData.premium3) doc.text(`R${shieldData.premium3}.00`, 170, table01 + 25)
      if (shieldData.premium4) doc.text(`R${shieldData.premium4}.00`, 170, table01 + 35)
      if (shieldData.premium5) doc.text(`R${shieldData.premium5}.00`, 170, table01 + 45)
      if (shieldData.premium6) doc.text(`R${shieldData.premium6}.00`, 170, table01 + 55)
      if (shieldData.premium7) doc.text(`R${shieldData.premium7}.00`, 170, table01 + 65)
      if (shieldData.premium8) doc.text(`R${shieldData.premium8}.00`, 170, table01 + 75) //62
      table01 = table01 + 40;
      doc.text("Monthly Policy Fee", 125, table01 + 45) //67
      doc.text("Total Monthly premium", 119, table01 + 50)

      doc.text("R10.00", 170, table01 + 45)
      if (shieldData.extendedMonthlyPremium && shieldData.extendedMonthlyPremium !== 0) { doc.text(`R${shieldData.extendedMonthlyPremium}.00`, 170, table01 + 50) }
      else { doc.text(`R0.00`, 170, table01 + 50) }

      var table02else = page4line;

      //9. Extended family deduction details:
      if (shieldData.extendedPaymentMethod === 'Payroll') {
        doc.setFont('Helvetica', 'bold')
        doc.setFontSize(11)
        page4line = page4line + 15;
        doc.text('9. Deduction details:', 15, page4line) //92
        doc.setFontSize(9)
        // Stop Order (Persol deduction) Mandate
        doc.setFillColor('#FFEFED')
        page4line = page4line + 3;
        doc.rect(15, page4line, 173, 5, 'FD') //95
        doc.setFont('Helvetica', 'bold')
        page4line = page4line + 3.5;
        doc.text('Stop Order (Persol deduction) Mandate', 80, page4line)//98.5
        doc.setFont('Helvetica', 'normal')
        page4line = page4line + 1.5;
        var table02 = page4line;
        doc.rect(15, table02, 83, 5, 'S') //100
        doc.rect(15, table02 + 5, 83, 5, 'S')
        doc.rect(15, table02 + 10, 83, 5, 'S')
        doc.rect(15, table02 + 15, 83, 5, 'S')

        doc.rect(98, table02, 90, 5, 'S')
        doc.rect(98, table02 + 5, 90, 5, 'S')
        doc.rect(98, table02 + 10, 90, 5, 'S')
        doc.rect(98, table02 + 15, 90, 5, 'S')
        // text
        doc.text('Full Names & Surname:', 16.5, table02 + 4) //104
        doc.text('ID Number:', 16.5, table02 + 9)
        doc.text('Rank/Title', 16.5, table02 + 14)
        doc.text('Start date:', 16.5, table02 + 19)
        doc.text('Force No:', 100, table02 + 4)
        doc.text('Unit:', 100, table02 + 9)
        doc.text('Payslip reference:', 100, table02 + 14)
        doc.text('End date:', 100, table02 + 19)
        doc.text(`- ${shieldData.fullNames} ${shieldData.surname}`, 50, table02 + 4)
        doc.text(`- ${shieldData.IDNumber}`, 33, table02 + 9)
        doc.text(`- ${shieldData.rankTitle}`, 33, table02 + 14)
        doc.setFontSize(9)
        doc.setFont('Helvetica', 'bold')
        doc.text(`1st ${month[monthNo]} ${yearNow}`, 33, table02 + 19)
        doc.setFontSize(9)
        if (shieldData.forceNumber) doc.text(shieldData.forceNumber, 116.5, table02 + 4)
        if (shieldData.unitName) doc.text(shieldData.unitName, 116.5, table02 + 9)
        doc.text('SHIELD LIFE LTD(Deduction code 3294)', 126.5, table02 + 14)
        doc.text('until I formally cancel this Policy', 116.5, table02 + 19)

        doc.rect(15, table02 + 20, 173, 18, 'S')
        doc.setFont('Helvetica', 'normal')
        doc.text('I authorise the Accountant of the Department of Defence to deduct the monthly payments from my salary as per the above', 16, table02 + 25)
        doc.text('authority provided and remit it to Shield Life Limited, each and every month and continuing until cancelled by me in writing', 16, table02 + 30)
        doc.text('or until I substitute it with a new authorisation. I have read and accepted the terms and conditions as set out below.', 16, table02 + 35)
        // Terms & Conditions
        doc.rect(15, table02 + 40, 173, 70, 'S')
        doc.setFont('Helvetica', 'bold')
        doc.text('Terms & Conditions', 16.5, table02 + 45)
        doc.setFont('Helvetica', 'normal')
        doc.text('I accept that the authority may be cancelled by me by giving 31 (thirty one) days notice in writing.', 16.5, table02 + 50)
        doc.text('I understand that should I cancel the authority after the 31-day period, I shall not be entitled to any refund of amounts', 16.5, table02 + 55)
        doc.text('which have been withdrawn while this authority was in force, if such amounts were legally owing to you.', 16.5, table02 + 60)
        doc.text('Should the premium rate be adjusted by the Shield Life Limited as a result of a general decrease/increase in', 16.5, table02 + 65)
        doc.text('subscription or should I request the Shild Life Limited to decrease/increase the premium for certain reason, I confirm', 16.5, table02 + 70)
        doc.text('that the adjusted premium may be deducted.', 16.5, table02 + 75)
        // doc.text('I acknowledge that this Authority may be ceded or assigned to a third party for collection purposes.', 16.5, table02 + 80)
        // signature
        doc.text('I acknowledge that this Authority may be ceded or assigned to a third party for collection purposes.', 16.5, table02 + 85)
        doc.addImage(signature, 'JPEG', 25, table02 + 80, 35, 35, 'final', 'MEDIUM')
        doc.text('____________________________', 20, table02 + 100)
        doc.text('Signature of Premium Payer', 25, table02 + 105)
        doc.text(`Date: ${timeStamp}`, 72, table02 + 105)
      } else {
        table02else = table02else + 23;
        doc.setFontSize(11)
        doc.text('9. Deduction details:', 15, table02else)//92
        doc.setFontSize(9)
        // Stop Order (Persol deduction) Mandate
        doc.setFillColor('#FFEFED')
        doc.rect(15, table02else + 3, 173, 5, 'FD') //95
        doc.setFont('Helvetica', 'bold')
        doc.text('Debit order Mandate', 80, table02else + 7)//98.5
        doc.setFont('Helvetica', 'normal')
        var table02el = table02else + 8.1;
        doc.rect(15, table02el, 83, 5, 'S')//100
        doc.rect(15, table02el + 5, 83, 5, 'S')
        doc.rect(15, table02el + 10, 83, 5, 'S')
        doc.rect(15, table02el + 15, 83, 5, 'S')
        doc.rect(15, table02el + 20, 83, 5, 'S')
        doc.rect(15, table02el + 25, 83, 5, 'S')

        doc.rect(98, table02el, 90, 5, 'S')
        doc.rect(98, table02el + 5, 90, 5, 'S')
        doc.rect(98, table02el + 10, 90, 5, 'S')
        doc.rect(98, table02el + 15, 90, 5, 'S')
        doc.rect(98, table02el + 20, 90, 5, 'S')
        doc.rect(98, table02el + 25, 90, 5, 'S')
        // text
        doc.text('Acount Holder Name:', 16.5, table02el + 4)
        doc.text('Bank & branch code:', 16.5, table02el + 9)
        doc.text('Account Type:', 16.5, table02el + 14)
        doc.text('Debit order date:', 16.5, table02el + 19)
        doc.text('First instalment date:', 16.5, table02el + 24)
        doc.text('Beneficiary:', 16.5, table02el + 29)

        doc.text('Account No:', 100, table02el + 4)
        doc.text('Other:', 100, table02el + 9)
        doc.text('Other:', 100, table02el + 14)
        doc.text('Bank Statement ref:', 100, table02el + 19)
        doc.text('Repayment term:', 100, table02el + 24)
        doc.text('Payment streem:', 100, table02el + 29)

        doc.setFont('Helvetica', 'bold')

        doc.text(`${shieldData.fullNames} ${shieldData.surname}`, 47, table02el + 4)
        if (shieldData.extendedBankingDetailsDO[0].Bank && shieldData.extendedBankingDetailsDO[3]['Branch Code']) doc.text(`${shieldData.extendedBankingDetailsDO[0].Bank} ${shieldData.extendedBankingDetailsDO[3]['Branch Code']}`, 47, table02el + 9)
        if (shieldData.extendedBankingDetailsDO[1]['Account Type']) doc.text(`${shieldData.extendedBankingDetailsDO[1]['Account Type']}`, 47, table02el + 14)
        if (shieldData.extendedBankingDetailsDO[4]['Debit Order Date'] && shieldData.extendedBankingDetailsDO[4]['Debit Order Date'] === 1) doc.text(`${shieldData.extendedBankingDetailsDO[4]['Debit Order Date']}st of each month`, 47, table02el + 19)
        else if (shieldData.extendedBankingDetailsDO[4]['Debit Order Date'] && shieldData.extendedBankingDetailsDO[4]['Debit Order Date'] === 2) doc.text(`${shieldData.extendedBankingDetailsDO[4]['Debit Order Date']}nd of each month`, 47, table02el + 19)
        else if (shieldData.extendedBankingDetailsDO[4]['Debit Order Date'] && shieldData.extendedBankingDetailsDO[4]['Debit Order Date'] === 3) doc.text(`${shieldData.extendedBankingDetailsDO[4]['Debit Order Date']}rd of each month`, 47, table02el + 19)
        else if (shieldData.extendedBankingDetailsDO[4]['Debit Order Date']) doc.text(`${shieldData.extendedBankingDetailsDO[4]['Debit Order Date']}th of each month`, 47, table02el + 19)
        doc.text(`1st ${month[monthNo]} ${yearNow}`, 47, table02el + 24)
        doc.text('ShieldLife', 47, table02el + 29)

        if (shieldData.extendedBankingDetailsDO[2]['Account Number']) doc.text(`${shieldData.extendedBankingDetailsDO[2]['Account Number']}`, 132, table02el + 4)
        doc.text('-', 132, table02el + 9)
        doc.text('-', 132, table02el + 14)
        doc.text('SHIELDLIFE', 132, table02el + 19)
        doc.setFontSize(8)
        doc.text('above frequency until lapses/policy cancelled', 125, table02el + 24)
        doc.setFontSize(9)
        doc.text('Debicheck', 132, table02el + 29)

        doc.setFont('Helvetica', 'normal')

        doc.rect(15, table02el + 30, 173, 85, 'S')
        doc.text('I authorise Shield Life Limited to issue and deliver debit order payment instructions to your banker for collection against', 16, table02el + 35)
        doc.text('my Account and bank used by my employer to deposit my salaries/wages, which account may differ from the account', 16, table02el + 40)
        doc.text('specified above on condition that the sum of each payment instruction and frequency of payment requests will never ', 16, table02el + 45)
        doc.text('exceed the obligations as agreed and defined in the Agreement specified.', 16, table02el + 50)
        doc.text(`My bank's participation in the relevant payment stream, the authentication mechanism used by me and the relevant rules`, 16, table02el + 55)
        doc.text('applicable to the payment streams will determine per repayment cycle the most suitable payment stream in order to fulfil', 16, table02el + 60)
        doc.text('the obligations as defined in the agreement. My authorisation is subject thereto that the same payment instruction may', 16, table02el + 65)
        doc.text('never be presented simulataneously in more than one payment stream during the same repayment cycle or, if unpaid in a', 16, table02el + 70)
        doc.text('payment stream selected be represented in the same cycle in another payment stream. I can only service the obligations', 16, table02el + 75)
        doc.text('defined in here if the payment instructions are excuted as close as possible to when I receive my salary or wages which', 16, table02el + 80)
        doc.text('dates vary from month to month, especially during December of each year. To curb against (1) unpaid bank charges', 16, table02el + 85)
        doc.text('(2) losing the benefits described in the agreement quotes (3) incurring penalties die to non payment; I explicity authorise', 16, table02el + 90)
        doc.text('Shield Life Limited to utilise the functionality of Tracking supported on the debicheck Payment Streams especially after', 16, table02el + 95)
        doc.text('unsuccessful attempts on the Debicheck Payment Stream.', 16, table02el + 100)
        doc.text('Tracking supported on the Debicheck Payment Streams has been explained to me and I acknowledge that my above', 16, table02el + 105)
        doc.text('mentioned account will be interrogated for a defined period until this period has lapsed or until payment has been received.', 16, table02el + 110)

        // Terms & Conditions
        doc.addPage()
        var page42line = 25;
        doc.rect(15, page42line, 173, 80, 'S')
        //doc.rect(15, table02el+30, 173, 165, 'S')
        page42line = page42line + 5;
        doc.text('I hereby agree that subsequent payment instructions will continue to be delivered in terms of the authority until all', 16, page42line)
        doc.text('obligations have been paid. This authorisation will remain in force until cancelled by me in writing.  I hereby acknowledge', 16, page42line + 5)
        page42line = page42line + 10;
        doc.text('that my bank will charge fees to my account as agreed with them once they process this instruction.  I foresee that I may', 16, page42line)
        page42line = page42line + 5;
        doc.text('change my bank and bank account particulars reflected in here, in which instance I will notify the beneficiaries', 16, page42line)
        doc.text('specified, or any new beneficiary, should the agreement be ceded or assigned to any third party.  Should I however', 16, page42line + 5)
        doc.text('forget to notify the beneficiary or the assigned third party and if the beneficiary or the assigned third party obtain my new', 16, page42line + 10)
        doc.text('bank particulars, on own account, this mandate will not lapse.  This issued mandate will cover the obtained bank', 16, page42line + 15)
        doc.text('information and the beneficiary and the assigned third party may attach such new information, to this signed document', 16, page42line + 20)
        doc.text('as annexure, and the attached annexure must be read together with this mandate, by my new bank. Date changes', 16, page42line + 25)
        doc.text('agreed to align my salary and wage expected day of payment in accordance with the Agreement concluded with Legal', 16, page42line + 30)
        doc.text('Entity.', 16, table02el + 165)
        doc.text('I understand that the withdrawals hereby authorised will be processed through a computerised system provided by', 16, page42line + 35)
        doc.text('the South African Banks. I also understand that details of each withdrawal will be printed on my/our bank statement.', 16, page42line + 40)
        doc.text('Such must contain the agreement reference number as stipulated above which number shall enable me to match the', 16, page42line + 45)
        doc.text('withdrawals with this agreement. I acknowledge that this Authority may be ceded or assigned to a third party but only', 16, page42line + 50)
        doc.text('in the event that this agreement is also ceded or assigned to that third party.', 16, page42line + 55)

        page42line = page42line + 60;
        doc.rect(15, page42line + 15, 173, 120, 'S')

        doc.setFont('Helvetica', 'bold')
        doc.text('Terms & Conditions', 16.5, page42line + 18.5)
        doc.setFont('Helvetica', 'normal')
        doc.text('I instruct and authorise Shield Life Limited to draw against my nominated bank account (or any other bank or branch', 16.5, page42line + 25)
        doc.text('to which I may transfer my account) the amount necessary for the payment of the monthly premium due in respect of ', 16.5, page42line + 30)
        doc.text('my membership, on the day nominated by me each and every month and continuing until cancelled by me in writing', 16.5, page42line + 35)
        doc.text('or until I substitute it with a new authorisation.', 16.5, page42line + 40)
        doc.text(`I accept that the authority may be cancelled by me by giving 31 (thirty one) days notice in writing. I understand`, 16.5, page42line + 45)
        doc.text('that should I cancel the authority after the 31-day period, I shall not be entitled to any refund of amounts which', 16.5, page42line + 50)
        doc.text('have been withdrawn while this authority was in force, if such amounts were legally owing to you.', 16.5, page42line + 55)
        doc.text('I agree that in the event that the payment date falls on a Saturday,Sunday or recognised South African public holiday,', 16.5, page42line + 60)
        doc.text('the payment day will automatically be the first preceding business day.', 16.5, page42line + 65)
        doc.text('I agree that should there be insufficient funds in my account to meet the obligation, Shield Life Limited are entitled', 16.5, page42line + 70)
        doc.text('to track my account and re-present the instruction for payment as soon as sufficient funds are available in my', 16.5, page42line + 75)
        doc.text('account. Such will contain Shield Life Limited as reference which shall enable me to match the withdrawals with', 16.5, page42line + 80)
        doc.text('this Membership agreement.', 16.5, page42line + 85)
        doc.text('I agree to pay any bank charges relating to this debit order instruction.', 16.5, page42line + 90)
        doc.text('Should the premium rate be adjusted by Shield Life Limited as a result of a general decrease/increase in subscription', 16.5, page42line + 95)
        doc.text('or should I request Shield Life Limited to decrease/increase the premium for certain reason, I confirm that the', 16.5, page42line + 100)
        doc.text('adjusted premium may be deducted from my bank account.', 16.5, page42line + 105)
        doc.text('I acknowledge that this Authority may be ceded or assigned to a third party for collection purposes.', 16.5, page42line + 110)
        // signature
        doc.addImage(signature, 'JPEG', 25, page42line + 105, 35, 35, 'final', 'MEDIUM')
        doc.text('____________________________', 20, page42line + 125)
        doc.text('Signature of Main Member', 25, page42line + 130)
        doc.text(`Date: ${timeStamp}`, 72, page42line + 130)
      }

      //***************// member due diligence
      /*
      Are you a Domestic Prominent Influential Persons (DPIP)?
      dpip
      if yes, please provide details:
      dpipDetails

      Are you a Foreign Prominent Public Officials (FPPO)?
      fppo
      if yes, please provide details:
      fppoDetails

      Is a close family member or any associates of yours a DPIP or FPPO?
      closeDpipFppo
      closeDpipFppoDetails
      if yes, please provide details:
      */
      // *********************************************** doc.addImage(dueDilligence,5,5,180,150)
      // DPIP
      doc.addPage()
      doc.setFont('Helvetica', 'bold')
      doc.text('DPIP refers to a Domestic Prominent Influential Person that is an individual who holds, including in an acting', 28, 15)
      doc.text('position for a period exceeding 6 months, or who has held at any time in the proceeding 12 months in', 30, 20)
      doc.text('the Republic the following positions:', 70, 25)
      doc.setFont('Helvetica', 'normal')
      // left col
      doc.text('* President or Deputy President', 25, 35)
      doc.text('* Government minister ', 25, 40)
      doc.text('  or Deputy', 25, 45)
      doc.text('* Member of a royal family or', 25, 50)
      doc.text('  senior traditional leader', 25, 55)
      doc.text('* Premier of a Province', 25, 60)
      doc.text('* Executive Council member', 25, 65)
      // middle  col
      doc.text('* Leader of a political party', 70, 35)
      doc.text('* Executive mayor of a municipality', 70, 40)
      doc.text('* Municipal manager or CFO of', 70, 45)
      doc.text('  a municipality', 70, 50)
      doc.text('* Head, accounting officer of CFO', 70, 55)
      doc.text(' of a national / provincial department or', 70, 60)
      doc.text(' component', 70, 65)
      // right col
      doc.text('* Chairperson of the board of directors', 130, 35)
      doc.text('* Chairperson of the audit committee', 130, 40)
      doc.text('* Executive Officer', 130, 45)
      doc.text('* Chief Financial Officer', 130, 50)
      // FPPO
      doc.setFont('Helvetica', 'bold')
      doc.text('FPPO refers ot a Foreign Prominent Influenctial Person that is an individual who holds, or who has held at', 30, 75)
      doc.text('any time in the proceedings 12 months, in a foreign country, a prominent public function', 32, 80)
      doc.setFont('Helvetica', 'normal')
      // left col
      doc.text('* Head of State / Head of', 25, 90)
      doc.text('  a Country or Government', 25, 95)
      doc.text('* Member of a foreign royal', 25, 100)
      doc.text('  family', 25, 105)
      // doc.text('* Premier of a Province',25,105)
      // doc.text('* Executive Council member',25,110)
      // middle  col
      doc.text('* Government Minister or equivalent senior', 65, 90)
      doc.text('* politician or leader of a political party', 65, 95)
      doc.text('* Senior executive of a state-owned', 65, 100)
      doc.text('  corporation', 65, 105)
      // doc.text('* Head, accounting officer of CFO',70,100)
      // doc.text('* of a national / provincial department or',70,105)
      // doc.text('* component',70,110)
      // right col
      doc.text('* High ranking member of the military', 130, 90)
      doc.text('* (General; Lieutenant General;', 130, 95)
      doc.text('* Admiral or Vice Admiral)', 130, 100)
      doc.text('* Senior judicial official', 130, 105)
      // form fields to be populated
      doc.setFont('Helvetica', 'bold')
      doc.text('Are you a Domestic Prominent Influential Persons (DPIP)?', 25, 115)
      doc.rect(115, 112, 15, 5, 'S')
      doc.text('If yes, please provide details:', 25, 120)
      doc.rect(25, 122, 150, 10, 'S')
      //
      doc.text('Are you a Foreign Prominent Public Officials (FPPO)?', 25, 145)
      doc.rect(115, 142, 15, 5, 'S')
      doc.text('If yes, please provide details:', 25, 150)
      doc.rect(25, 152, 150, 10, 'S')
      //
      doc.text('Is a close family member or any associates of yours a DPIP or FPPO?', 25, 175)
      doc.rect(140, 172, 15, 5, 'S')
      doc.text('If yes, please provide details:', 25, 180)
      doc.rect(25, 182, 150, 10, 'S')
      // signature
      doc.setTextColor('red')
      if (shieldData.dpip) doc.text(shieldData.dpip, 119.5, 115.5)
      if (shieldData.dpipDetails) doc.text(shieldData.dpipDetails, 28, 126)
      if (shieldData.fppo) doc.text(shieldData.fppo, 119.5, 145.5)
      if (shieldData.fppoDetails) doc.text(shieldData.fppoDetails, 28, 156)
      if (shieldData.closeDpipFppo) doc.text(shieldData.closeDpipFppo, 144.5, 175.5)
      if (shieldData.closeDpipFppoDetails) doc.text(shieldData.closeDpipFppoDetails, 28, 186)
      doc.setTextColor('black')
      if (signature) doc.addImage(signature, 33, 210, 35, 35)
      //  doc.text(`Date: ${timeStamp}`,72,155)
      doc.text('___________________________', 32, 235)
      doc.text('Signature of Main Member', 35, 240)
      doc.addPage()
      doc.setFont('Helvetica', 'normal')
      //  // Maxi Forces Financial Service (Pty) Ltd
      // Record of Information
      doc.setFontSize(11)
      doc.setTextColor('red')
      doc.text('The following does not form part of the application form but are information that needs to be', 20, 15)
      doc.text('communicated to new members.', 77, 20)
      doc.setTextColor('#444')
      doc.setFontSize(16)
      doc.setFont('Helvetica', 'bold')
      doc.text('Maxi Forces Financial Service (Pty) Ltd', 55, 30)
      doc.text('Record of Information', 77, 37)
      doc.setFont('Helvetica', 'normal')
      doc.setFontSize(9)
      doc.text('Only information, and not advice, is provided in respect of the SA Army Foundation Group Scheme policy.', 15, 45)
      doc.text('Should you require advice in any way, please contact the Insurer for assistance 0861 777 353.', 15, 50)
      doc.text('You have chosen to apply for the SA Army Foundation membership that includes the Group Scheme benefits', 15, 55)
      doc.text('underwritten by Shield Life® Ltd as stated in the script.', 15, 60)
      doc.setFontSize(11)
      doc.text('To be completed by main member:', 15, 70)
      doc.setFillColor('#FFEFED')
      doc.rect(15, 75, 166, 5, 'FD')
      doc.setFont('Helvetica', 'bold')
      doc.setFontSize(9)
      doc.text('I have selected this plan for the following reasons:', 60, 78.5)
      doc.setFont('Helvetica', 'normal')
      doc.rect(15, 80, 166, 20, 'S')
      doc.setFontSize(8)
      if (shieldData.agsb) doc.text(shieldData.agsb, 20, 85)
      if (shieldData.rhcb) doc.text(shieldData.rhcb, 20, 90)
      if (shieldData.cgsb) doc.text(shieldData.cgsb, 100, 85)
      if (shieldData.asb) doc.text(shieldData.asb, 100, 90)
      if (shieldData.other) doc.text(shieldData.other, 80, 93)
      if (shieldData.otherDetails) doc.text(shieldData.otherDetails, 90, 93)
      if (shieldData.agsbwide) doc.text(shieldData.agsbwide, 20, 95)
      /*doc.text(rhcbwide, 20, 110)
      doc.text(cgsbwide, 100, 105)
      doc.text(asbwide, 100, 110)
      doc.text(otherwide, 80, 113)
      doc.text(otherDetailswide, 97, 113)*/

      // pre-existing funeral cover
      //doc.rect(15, 110, 166, 30, 'S')
      //doc.rect(15, 120, 166, 30, 'S')
      doc.setFillColor('#FFEFED')
      doc.rect(15, 100, 166, 5, 'FD')
      doc.setFont('Helvetica', 'bold')
      doc.setFontSize(9)
      doc.text('Pre-existing funeral cover', 75, 103.5)
      doc.setFont('Helvetica', 'normal')
      doc.rect(15, 100, 166, 40, 'S')
      doc.text('In order for the Insurer to determine whether a waiting period is applicable, you the potential member', 20, 115)
      doc.text('must confirm the following: Have you recently, more than 31 days before signature date, cancelled a', 20, 120)
      doc.text(`funeral policy with another Insurer?`, 20, 125)
      if (shieldData.prevCancelation) doc.text(shieldData.prevCancelation, 20, 120)
      if (shieldData.prevWaiting) doc.text(shieldData.prevWaiting, 20, 125)
      doc.setFillColor('#FFEFED')
      doc.rect(15, 135, 166, 5, 'FD')
      doc.setFont('Helvetica', 'bold')
      doc.setFontSize(9)
      // additional information
      doc.text('Additional Information', 77, 138.5)
      doc.setFont('Helvetica', 'normal')
      doc.rect(15, 140, 166, 145, 'S')
      doc.text('Without in any way limiting and subject to the other provisions of the Services Agreement/Mandate,', 20, 145)
      doc.text('Maxi Forces Financial Services (Pty) Ltd accepts responsibility for the lawful actions of their', 20, 150)
      doc.text('representatives (as defined in the Financial Advisory and Intermediary Service Act) in rendering', 20, 155)
      doc.text('financial services within the course and scope of their employment.  All our agents are authorised', 20, 160)
      doc.text('representatives of Maxi Forces Financial Services (Pty) Ltd and some agents may be rendering services', 20, 165)
      doc.text('under supervision and will inform you accordingly.Benefits:The main insured and spouse will be covered', 20, 170)
      doc.text('in the event of Death and Disability, where applicable.  Children will be covered in the event of Death,', 20, 175)
      doc.text('and Disability, where applicable.  Children will be covered in the event of Death, where applicable.In', 20, 180)
      doc.text('return for payment of a monthly or annual premium, your cover amount will be paid based on the following:', 20, 185)
      doc.text('#  The insured event occurs within the period of membership;', 20, 190)
      //
      doc.text('# The event giving rise to a claim is covered in terms of the exclusions and/or the terms and conditions', 20, 195)
      doc.text('of the policy wording;#  The truth and accuracy of the information at the time of application;', 20, 200)
      doc.text('# You provide us with all the required documents as set on the claim form provided; and ', 20, 205)
      doc.text('# The claim is reported within the prescribed periods stipulated in your policy wording.', 20, 210)
      //
      doc.text('The benefit payable will be based on the following:#  In the event of Death, due to accidental, violent,', 20, 215)
      doc.text('external and visible means, the benefit will be payable to the nominated beneficiary.', 20, 220)
      doc.text('#  In the event of Death, due to natural causes, after the expiry of the initial waiting period,', 20, 225)
      doc.text('the benefit will be payable to the main member or nominated beneficiary.', 20, 230)
      //
      doc.text('Surrender Value:The Membership and all benefits that is included in the membership has no surrender, loan', 20, 235)
      doc.text('or paid up values.Exclusions:Claims will not be paid if: Any loss or expense of whatsoever nature directly', 20, 240)
      doc.text('caused by, resulting from, or in connection with the willing participation by the Principal Member or and', 20, 245)
      doc.text('Dependant in any of the following against the lawful military or police forces of South Africa: Civil War;', 20, 250)
      doc.text('Revolution; Insurrection; Invasion; Overthrow of the legally constituted government; Military. or usurped', 20, 255)
      doc.text('power and Civil commotion assuming the proportions of, or amounting to, an uprising against an established', 20, 260)
      //
      doc.text('government. This Group Scheme benefits also excludes any loss or expense of whatsoever nature directly caused', 20, 265)
      doc.text('by, resulting from in connection with any of the following:1. Use of nuclear, biological or chemical weapons,', 20, 270)
      doc.text('or any radioactive contamination;2. Attacks on or sabotage of facilities;3. The member committing any act', 20, 275)
      doc.text('or deed in violation of criminal or military law; 4. Martial law or loot, sack or pillage therewith;', 20, 280)
      //
      doc.addPage()
      doc.rect(15, 10, 166, 35, 'S')
      doc.text('5. Confiscation or nationalisation or requisition or destruction of or damage to property by or under the', 20, 15)
      doc.text('order of any government or public or local authority. 6. Intentional self – inflicted injury, suicide or a', 20, 20)
      doc.text('suicide attempt (whether sane or insane) within 12 months from the Entry Date 7. Discontinuance of the', 20, 25)
      doc.text('payment of premiums or part thereof in respect of a Principal Member.', 20, 30)
      doc.text('Full Exclusions available in your Policy Document which will be sent to you.', 20, 35)
      doc.text('Waiting Periods', 20, 40)
      doc.setFillColor('#FFEFED')
      doc.rect(15, 45, 166, 5, 'FD')
      doc.setFont('Helvetica', 'bold')
      doc.setFontSize(9)
      // additional information
      doc.text('Cover', 50, 48.5)
      doc.text('Waiting Period', 125, 48.5)
      doc.setFont('Helvetica', 'normal')
      doc.rect(15, 50, 83, 5, 'S')
      doc.rect(98, 50, 83, 5, 'S')
      doc.rect(15, 55, 83, 10, 'S')
      doc.rect(98, 55, 83, 10, 'S')
      doc.rect(15, 65, 83, 5, 'S')
      doc.rect(98, 65, 83, 5, 'S')
      // text
      doc.text('Life and Disability Cover:', 20, 54)
      doc.text('Funeral Cover (if no previous', 20, 59)
      doc.text('waiting periods have been completed)', 20, 63)
      doc.text('Accidental Death:', 20, 69)
      doc.setFontSize(7)
      doc.text('Natural Causes - 3 months from first successful premium collection', 100, 54)
      doc.text('Natural Causes - 6 months from first successful premium collection;', 100, 59)
      doc.text('Suicide - 12 months from first successful premium collection', 100, 63)
      doc.text('Immediately from first successful premium collection', 100, 69)
      doc.setFontSize(9)
      doc.setFillColor('#FFEFED')
      doc.rect(15, 75, 166, 5, 'FD')
      doc.setFont('Helvetica', 'bold')
      doc.setFontSize(9)
      doc.text('Compliance with the requirements of the FAIS Code of Conduct', 50, 78.5)
      doc.setFont('Helvetica', 'normal')
      doc.rect(15, 80, 166, 70, 'S')
      doc.text('I understand that only group scheme cover is provided.', 20, 85)
      doc.text('I know that the accuracy and completeness of the information provided remains my responsibility, and not', 20, 90)
      doc.text('disclosing the correct information could result in claims not being paid and loss of benefits and premiums.', 20, 95)
      doc.text('I have seen and read a copy of Maxi Forces Financial Services (Pty) Ltd Statutory disclosure.', 20, 100)
      doc.text('I confirm that only information, and not advice, has been provided to me.', 20, 105)
      doc.text('I understand that there is no refund of premiums in the event of cancellation, except when exercising', 20, 110)
      doc.text('the 31 days cooling off right. I have not been coerced or induced in any way in the exercising of any.', 20, 115)
      doc.text('of my rights. I confirm that the application form and/or any other documents (including this document)', 20, 120)
      doc.text('was fully completed before I signed it, and all information is to the best of my knowledge', 20, 125)
      doc.text('both true and correct. I confirm that I have received this record of transaction. I understand', 20, 130)
      doc.text('that if payment of my premium is unsuccessful, my policy benefits are at risk and it is my', 20, 135)
      doc.text("responsibility to notify the Insurer as to how to collect arrear premiums. If I don’t, my", 20, 140)
      doc.text('policy may lapse and I will lose my cover and all premiums. Less than 1% fees is received as remuneration.', 20, 145)
      doc.setFontSize(9)
      doc.setFillColor('#FFEFED')
      doc.rect(15, 150, 166, 5, 'FD')
      doc.setFont('Helvetica', 'bold')
      doc.setFontSize(9)
      doc.text('Declaration', 87, 153.5)
      doc.setFont('Helvetica', 'normal')
      doc.rect(15, 155, 166, 30, 'S')
      // text
      doc.text(`I, ${shieldData.fullNames} ${shieldData.surname} declare that the information in respect of the Group Scheme benefits has`, 20, 160)
      doc.text('provided to me, including the cover amounts, waiting periods, premiums and when claims will not be paid.', 20, 165)
      // signature
      doc.addImage(signature, 'JPEG', 25, 160, 35, 35, 'final', 'MEDIUM')
      doc.text('____________________________', 20, 175)
      doc.text('Signature of Main Member', 25, 180)
      doc.text(`Date: ${timeStamp}`, 72, 180)
      doc.addPage()
      //  // Statutory Notice
      // ******************************* doc.addImage(statNoticePage1, 'png',2,2,200,300)
      // ******************************* doc.addPage()
      // ******************************* doc.addImage(statNoticePage2, 'png',2,2,200,250)
      // ******************************* doc.addPage()
      doc.setFontSize(16)
      doc.setFont('Helvetica', 'bold')
      doc.text('Statutory Notice', 80, 15)
      doc.setFontSize(8)
      doc.text('This notice does not form part of the Insurance Contract nor any other document, but you have the right to the following information', 15, 25)
      doc.setFontSize(9)
      doc.text('Policyholder details:', 5, 35)
      doc.setFontSize(8)
      doc.text('Name:', 5, 40)
      doc.text('SA Army Foundation', 55, 40)
      doc.text('FSP Number:', 5, 45)
      doc.text('6995', 55, 45)
      doc.text('Registration Number:', 5, 50)
      doc.text('056-121-NPO', 55, 50)
      doc.text('Tel:', 5, 55)
      doc.text('(012) 675 9400', 55, 55)
      doc.text('Address:', 5, 60)
      doc.text('31 Superdrive Avenue, Clubview, 0157', 55, 60)
      doc.text('Website', 5, 65)
      doc.text('www.armyfoundation.co.za', 55, 65)
      doc.setFontSize(9)
      doc.text('Details about other parties involved:', 5, 75)
      doc.text('The Broker', 55, 80)
      doc.text('The Insurer', 135, 80)
      doc.setFontSize(8)
      doc.text('Name', 5, 85)
      doc.text('Maxi Forces Financial Services (Pty) Ltd', 55, 85)
      doc.text('Shield Life Ltd', 135, 85)
      doc.text('FSP Number', 5, 90)
      doc.text('1966', 55, 90)
      doc.text('47477', 135, 90)
      doc.text('Registration Number', 5, 95)
      doc.text('1995/004244/07', 55, 95)
      doc.text('2016/249174/06', 135, 95)
      doc.text('Tel:', 5, 100)
      doc.text('0861 222 303', 55, 100)
      doc.text('0861 777 353', 135, 100)
      doc.text('Address:', 5, 105)
      doc.text('1293 South Street,', 55, 105)
      doc.text('Maxi Building,', 55, 110)
      doc.text('Centurion,', 55, 115)
      doc.text('0157', 55, 120)
      doc.text('1293 South Street,', 135, 105)
      doc.text('Maxi Building,', 135, 110)
      doc.text('Centurion,', 135, 115)
      doc.text('0157', 135, 120)
      doc.text('Email:', 5, 130)
      doc.text('info@maxiforces.co.za', 55, 130)
      doc.text('css@shieldlife.co.za', 135, 130)
      doc.text('Website:', 5, 135)
      doc.text('', 55, 135)
      doc.text('www.shieldlife.co.za', 135, 135)
      doc.text('FSP Authorised Category I Products:', 5, 140)
      doc.text('Long-term Insurance: A,B1,B1-A,B2,B2-A', 55, 140)
      doc.text('Short-term Insurance: Personal Lines (PL), PL A1', 55, 145)
      doc.text('Long-term Insurance: A,B1,B1-A,B2,B2-A', 135, 140)
      doc.text('Short-term Insurance: Personal Lines (PL), PL A1', 135, 145)
      doc.text('Participatory interest in a collective investment scheme', 135, 150)
      //
      doc.text('Compliance Officer:', 5, 160)
      doc.text('Moonstone Compliance (Pty) Ltd', 55, 160)
      doc.text('The Compliance Department can be access through', 135, 160)
      doc.text('represented by Mr. Francois Meintjies', 55, 165)
      doc.text('e-mail compliance@shieldlife.co.za', 135, 165)
      doc.text('Tel: (021) 883 8000, Fax: (021) 880 0688', 55, 170)
      doc.text('E-mail: fm@moonstone.co.za', 55, 175)
      doc.text('PO Box 1221, Die Board, Stellenbosch,7673', 55, 180)
      /*

      */
      doc.text('Complaints:', 5, 190)
      doc.text('Must be in writing and will be attended to within 10 days', 55, 190)
      doc.text('We may request additional information, if needed and will', 55, 195)
      doc.text('keep the complainant up to date of the progress made. If', 55, 200)
      doc.text('the complaint is not resolved to the satisfaction of the', 55, 205)
      doc.text('complainant, they may approach the FAIS Ombud after', 55, 210)
      doc.text('expiry of the 90 day period. For further details,', 55, 215)
      doc.text('our Complaints Management Policy is available', 55, 220)
      doc.text('upon request: complaints@maxiforces.co.za', 55, 225)
      doc.text('The Complaints Policy can be access through our', 135, 190)
      doc.text('website www.shieldlife.co.za or e-mail', 135, 195)
      doc.text('complaints@shieldlife.co.za', 135, 200)
      doc.text('Legal & contractual relationship', 5, 235)
      doc.text('Intermediary Service Agreement', 55, 235)
      doc.text('Professional Indemnity Insurance', 5, 240)
      doc.text('Professional Indemnity Cover is in place.', 55, 240)
      doc.text('Professional Indemnity Cover & Fidelity', 135, 240)
      doc.text('Cover are in place.', 135, 245)
      doc.text('Conflict of Interest', 5, 250)
      doc.text('The Conflict of Interest Management Policy can be found', 55, 250)
      doc.text('upon written request from the compliance officer.', 55, 255)
      doc.text('The Conflict of Interest Management Policy can be ', 135, 250)
      doc.text('obtained upon written request: ', 135, 255)
      doc.text('compliance@maxiforces.co.za', 135, 260)
      doc.text('Claims Procedure:', 5, 270)
      doc.text('All claims enquiries should be address to:', 135, 270)
      doc.text('The Claims Department: Shield Life', 135, 275)
      doc.text('PO Box 7309, Centurion,0046', 135, 280)
      doc.text('Tel: 0861 277 873', 135, 285)
      doc.text('Email: claims@shieldlife.co.za', 135, 290)
      // doc.text('All claims enquiries should be address to:', 135, 270)
      // doc.text('The Claims Department: Shield Life', 135, 275)
      // doc.text('PO Box 7309, Centurion,0046', 135, 280)
      // doc.text('Tel: 0861 277 873', 135, 285)
      // doc.text('Email: claims@shieldlife.co.za', 135, 290)
      doc.addPage()
      doc.setFontSize(9)
      doc.text('Other important contact details:', 5, 15)
      doc.setFontSize(8)
      doc.text('The FAIS Ombud', 5, 20)
      doc.text('The Long-term insurance Ombud', 60, 20)
      doc.text('The Authority', 135, 20)
      doc.text('The New Address', 5, 70)
      doc.setFont('Helvetica', 'normal')
      doc.text('(For advice/policy related matters):', 5, 25)
      doc.text('The FAIS Ombud', 5, 30)
      doc.text('The FAIS Ombud New', 5, 75)
      doc.text('PO Box 74571, Lynnwood Ridge, 0040', 5, 35)
      doc.text('Third Floor, Sunclare Building, 21 Dreyer Street, Claremont, Cape Town 7700', 5, 80)
      doc.text('Tel: 086 066 3247 or Tel (012) 762 5000', 5, 40)
      doc.text('Tel: 021 657 5000 or Tel 021 674 0951', 5, 85)
      doc.text('Fax: (012) 348 3447', 5, 45)
      doc.text('E-mail: info@faisombud.co.za', 5, 50)
      doc.text('E-mail: info@amdub.co.za', 5, 90)
      doc.text('www.falsombud.co.za', 5, 55)
      doc.text('Share call: 0860 103 236', 5, 95)
      doc.text('(For Claims/service related matters):', 65, 25)
      doc.text('Ombudsman for Long-term Insurance', 65, 30)
      doc.text('Private Bag x45, Claremont, Cape Town,0102', 65, 35)
      doc.text('Tel: 0860 103 236 or (021) 657 5000', 65, 40)
      doc.text('Fax: (021) 674 0951', 65, 45)
      doc.text('E-mail: info@ombud.co.za', 65, 50)
      doc.text('www.ombud.co.za', 65, 55)
      doc.text('(For market conduct matters):', 135, 25)
      doc.text('For Complaints on the Insurer that are not resolved', 135, 30)
      doc.text('The Financial Sector Conduct Authority', 135, 35)
      doc.text('PO Box 35655, Menlo Park,0102', 135, 40)
      doc.text('Tel: (012) 428 8000 Fax (012) 347 0221', 135, 45)
      doc.text('E-mail: info@fsca.co.za', 135, 50)

      // important information
      doc.setFont('Helvetica', 'bold')
      doc.setFontSize(9)
      doc.text('Important information:', 5, 125)
      doc.setFontSize(8)
      doc.text('Premium obligations:', 5, 130)
      doc.text('Premium payments:', 5, 135)
      doc.text('Commencment of insurance:', 5, 145)
      doc.text('Expiry of insurance cover:', 5, 155)
      doc.text('Premium & Benefit increase:', 5, 165)
      doc.text('Incorrect information provided:', 5, 175)
      doc.text('Remuneration:', 5, 185)
      doc.text('Cooling off rights:', 5, 195)
      doc.text('Insurance Contract understanding:', 5, 215)
      doc.text('General', 5, 225)
      doc.setFont('Helvetica', 'normal')
      doc.text('The obligations that you assume as premium prayer is indicated in the insurance Contract.', 70, 130)
      doc.text('The due date of premiums and consequences of non-payment is', 70, 135)
      doc.text('set out in the conditions of the insurance Contract.', 70, 140)
      doc.text('Cover will be active from the first day of the month in which we receive the first premium.', 70, 145)
      doc.text('All benefits and premiums will cease to be payable upon the events as described in the policy wording.', 70, 155)
      doc.text('Policy & Benefit increase are subject to decisions made by the SA Foundation Board of Control.', 70, 165)
      doc.text('Decisions made by Shield Life Ltd as the insurer, respect of insurance or claim, may be influenced by', 70, 175)
      doc.text('the failure to provide correct or full information by the insured', 70, 180)
      doc.text('Commission will not exceed the statutory commission as defined by the Life Insurance Act.', 70, 185)
      doc.text('Cooling off rights may be exercised within 31 days after inception of the membership,', 70, 195)
      doc.text('where no benefit has been paid or claimed. A written cancellation form must be completed ', 70, 200)
      doc.text('and send to Shield Life® Ltd.  Cooling off rights can not be exercised if other legislation prohibit it.', 70, 205)
      doc.text('Ensure you understand what benefits and exclusions are applicable to your Insurance Contract. ', 70, 215)
      doc.text('Make sure you are acquainted with:', 70, 220)
      doc.text('You will be informed of any material changes to the above mentioned information.', 70, 225)
      doc.text('This disclosure notice serves to provide you with the information in writing if any information', 70, 230)
      doc.text('was given, The reason for your claim to be rejected will always be communicated to you in writing.', 70, 235)
      doc.text('In case of the insurer cancelling your policy, this will be done in writing to your last known', 70, 240)
      doc.text('address. You will always be entitled to a copy of your policy free of charge.', 70, 245)
      doc.text('For instructions to access, verify, or object to personal information processed by Shield Life,', 70, 250)
      doc.text('Please view. Should any of my dependants or I have any concerns about the processing', 70, 255)
      doc.text('of your personal information.', 70, 260)
      doc.addPage()
      doc.setFont('Helvetica', 'bold')
      doc.setFontSize(16)
      doc.text('Treating Custormers Fairly (TCF) Principles', 40, 15)
      doc.setFontSize(9)
      doc.text('Shield Life Ltd subscribe to the following 6 TCF principles during product creation to meet your requirements and expectation:', 5, 20)
      doc.text('Culture', 5, 30)
      doc.text('Right Product', 5, 35)
      doc.text('Informed', 5, 40)
      doc.text('Suitable Advice', 5, 45)
      doc.text('Meet Expectations', 5, 50)
      doc.text('Excellent Service', 5, 55)
      doc.setFont('Helvetica', 'normal')
      doc.text('Consumers are confident that fair treatment is central to our Company culture', 40, 30)
      doc.text('Consumers have Products & Services that meet their needs', 40, 35)
      doc.text('Consumers received communication that is clear and in understandable language', 40, 40)
      doc.text('Consumers receive suitable advice aimed at their circumstances', 40, 45)
      doc.text('Consumers receive Products & Services that will meet their expectations', 40, 50)
      doc.text('Consumers expierance no post-sale barriers', 40, 55)
      doc.addPage()
      // Army Foundation Membership Information
      doc.setFontSize(16)
      doc.setFont('Helvetica', 'bold')
      doc.text('Army Foundation Membership Information', 40, 15)
      doc.setFont('Helvetica', 'normal')
      doc.setFontSize(7)
      doc.text('We would like to take this opportunity to give you an update on all the benefits that are currently available to you as a member.', 25, 20)
      doc.setFontSize(9)
      doc.setFillColor('#FFEFED')
      doc.rect(15, 30, 166, 10, 'FD')
      doc.setFont('Helvetica', 'bold')
      doc.setFontSize(9)
      doc.text('Death, Disability, Accidental and Funeral cover', 20, 33.5)
      doc.text('underwritten by Shield Life® Ltd', 30, 38.5)
      doc.text('Rainbow Holiday Club', 123, 33.5)
      doc.text('administrated by Club Leisure', 115, 38.5)
      doc.setFont('Helvetica', 'normal')
      doc.rect(15, 30, 83, 65)
      doc.rect(98, 30, 83, 65)
      // text left col
      doc.setFontSize(7)
      doc.text('You are covered for up to R110 000 for the principal member and', 18, 45)
      doc.text('up to R70 000 for your spouse in the event of death Principal', 18, 50)
      doc.text('Member and Spouse cover includes: Death and disability cover of', 18, 55)
      doc.text('R40 000 and Funeral Cover of R30 000. Accidental Death cover for', 18, 60)
      doc.text('the Principal Member of R40 000. Funeral Cover for Children', 18, 65)
      doc.text('R10 000 – R20 000 age dependent.*Disability Cover up to', 18, 70)
      doc.text('R40 000* T&C Apply*If a claim was received and approved for', 18, 75)
      doc.text('disability, the Life Cover will cease for the Claimant of', 18, 80)
      doc.text('the Disability.The above amounts is Membership type dependent', 18, 85)
      doc.text('and T&C Apply', 18, 90)
      //text right col
      doc.text('The Club provides members with a unique monthly savings opportunity', 100, 45)
      doc.text('to ensure that members have enough funds available when they want', 100, 50)
      doc.text("to go on holiday. The member' monthly *savings accumulates in", 100, 55)
      doc.text('their own Vacation Fund until they are ready to go on holiday.', 100, 60)
      doc.text('The Club provides 3 options:Silver: R121.00 savings per month ', 100, 65)
      doc.text('Gold: R181.50 savings per month	Platinum: R302.51 savings per', 100, 70)
      doc.text('month As a member of Army Foundation, you can apply for membership', 100, 75)
      doc.text('at your nearest SA Army Foundation branch.', 100, 80)
      doc.setFontSize(9)
      doc.setFillColor('#FFEFED')
      doc.rect(15, 95, 166, 10, 'FD')
      doc.setFont('Helvetica', 'bold')
      doc.setFontSize(9)
      doc.text('Funeral Service Benefit Plan', 35, 98.5)
      doc.text('in partnership with AVBOB Funeral Services', 20, 103.5)
      doc.text('SA Army Foundation Resorts', 120, 100.5)
      doc.setFont('Helvetica', 'normal')
      doc.rect(15, 105, 83, 65)
      doc.rect(98, 105, 83, 65)
      // text left col
      doc.text('You have the following benefits:', 18, 110)
      doc.text('# Preferential prices for funeral products & services', 18, 115)
      doc.text('# Customised comprehensive AVBOB Funeral Service', 18, 120)
      doc.text('Offering.', 18, 125)
      doc.text('# Transport of deceased within SA borders', 18, 130)
      doc.text('# Assistance & support with funeral arrangements &', 18, 135)
      doc.text('funeral conducting', 18, 140)
      doc.text('# Free telephonic bereavement counselling', 18, 145)
      doc.text('# After hours service', 18, 150)
      doc.text('* T&C Apply', 18, 155)
      // text right col
      doc.text('You have all year access to discounted rates', 100, 110)
      doc.text('on holidays at the following Army Foundation.', 100, 115)
      doc.text('resorts', 100, 120)
      doc.text('# Kruger Park Lodge - Hazyview', 100, 125)
      doc.text('# Santana Resort – Margate (KZN)', 100, 130)
      doc.text('# Flamboyant – ST Lucia (KZN)', 100, 135)
      doc.text('# Rhemardo - Mokgopong', 100, 140)
      doc.text('* T&C Apply', 100, 145)
      // next row
      doc.setFontSize(9)
      doc.setFillColor('#FFEFED')
      doc.rect(15, 160, 166, 10, 'FD')
      doc.setFont('Helvetica', 'bold')
      doc.setFontSize(9)
      doc.text('Adopt A Student Bursary Program', 30, 166)
      doc.text('Housing', 132, 166)
      doc.setFont('Helvetica', 'normal')
      doc.rect(15, 170, 83, 25)
      doc.rect(98, 170, 83, 25)
      doc.text('Provides comprehensive financial aid ', 18, 175)
      doc.text('to the children of contributing members.', 18, 180)
      doc.text('* T&C Apply', 18, 185)
      doc.text('As a member of Army Foundation, you can apply', 100, 175)
      doc.text('for a rental property at a discounted price', 100, 180)
      doc.text('in Clubview, Centurion.	', 100, 185)
      doc.text('* T&C Apply', 100, 190)
      // next row
      doc.setFontSize(9)
      doc.setFillColor('#FFEFED')
      doc.rect(15, 200, 166, 10, 'FD')
      doc.setFont('Helvetica', 'bold')
      doc.setFontSize(9)
      doc.text('Mobile Products', 40, 206)
      doc.text('Financial Assistance', 125, 206)
      doc.setFont('Helvetica', 'normal')
      doc.rect(15, 210, 83, 40)
      doc.rect(98, 210, 83, 40)
      doc.text('Available Product Include:', 22, 215)
      doc.text('# Post-paid airtime from R50 to R300 p.m.', 18, 220)
      doc.text('and pay for it later.', 18, 225)
      doc.text('# SA Army Foundation cell phone app and', 18, 230)
      doc.text('email services is included in', 18, 235)
      doc.text('your membership.	', 18, 240)
      doc.text('* T&C Apply as per mobile criteria', 18, 245)
      doc.text('You may qualify for payday loans up to', 105, 215)
      doc.text('R2 500 and personal loans up to R30 000.', 105, 220)
      doc.text('You can also get access to your personal', 105, 225)
      doc.text('credit report.', 105, 230)
      doc.text('* T&C Apply as per loan criteria', 105, 235)
      // next row
      doc.setFontSize(9)
      doc.setFillColor('#FFEFED')
      doc.rect(15, 241, 166, 10, 'FD')
      doc.setFont('Helvetica', 'bold')
      doc.setFontSize(9)
      doc.text('Gratuity at Retirement', 40, 248)
      doc.text('SA Army Foundation Memorial Plaques', 110, 248)
      doc.setFont('Helvetica', 'normal')
      doc.rect(15, 251, 83, 20)
      doc.rect(98, 251, 83, 20)
      doc.text('Available to employees of the DOD and', 18, 255)
      doc.text('contributing member for at least 10 years.', 18, 260)
      doc.text('* T&C Apply ', 18, 265)
      doc.text('Available to the next of kin for those', 105, 255)
      doc.text('members who have passed during their term.', 105, 260)
      doc.text('of service.', 105, 265)
      doc.text('* T&C Apply', 105, 270)
      doc.addPage()
      doc.setFontSize(9)
      doc.setFillColor('#FFEFED')
      doc.rect(15, 15, 166, 5, 'FD')
      doc.setFont('Helvetica', 'bold')
      doc.setFontSize(9)
      doc.text('Important notice', 87, 18.5)
      doc.setFont('Helvetica', 'normal')
      doc.rect(15, 20, 166, 10)
      doc.text('When membership is cancelled, the wider Family Benefit and/or Rainbow Savings is automatically', 20, 24)
      doc.text('cancelled due to it being rider benefits of membership.', 60, 29)
      doc.setFillColor('#FFEFED')
      doc.rect(15, 30, 166, 5, 'FD')
      doc.setFont('Helvetica', 'bold')
      doc.setFontSize(9)
      doc.text('Declaration', 90, 33.5)
      doc.setFont('Helvetica', 'normal')
      doc.rect(15, 35, 166, 35)
      doc.text(`I ${shieldData.fullNames} ${shieldData.surname} have received and understand the information of the above products`, 17, 40)
      doc.text('forms part of the SA Army Foundation Membership', 17, 45)
      // signature
      doc.addImage(signature, 'JPEG', 25, 40, 35, 35, 'final', 'MEDIUM')
      doc.text('____________________________', 20, 60)
      doc.text('Signature of Main Member', 25, 65)
      doc.text(`Date: ${timeStamp}`, 72, 65)
    }
    // to download pdf
    if (type === 'download') {
      doc.save('Membership_Application.pdf')
      // view pdf
    } else if (type === 'view') {
      window.open(doc.output('bloburl'))
    } else if (type === 'save') {
      var output = doc.output('datauristring')
      setBase64Data(output)
    }
  }

  const postToApi = async (data1) => {
    let payload;
    if (isFuneralData) payload = funeralData;
    else payload = shieldData;

    var config = {
      method: 'post',
      url: 'https://shieldapp.msgportal.online/Rest.aspx',
      headers: {
        'content-type': 'application/json'
      },
      data: {
        ...payload,
        signature: signature,
        SourceSite: "validate.shieldapp.co.za"
      }
    };

    await axios(config)
      .then(function (response) {
        console.log(JSON.stringify(response.data));
        if (response.data === 'ok') setIsLoading(false);
      })
      .catch(function (error) {
        setIsLoading(false);
        navigate(-1);
      });
  }

  // if approved send state to backend
  useEffect(() => {
    if (approved) {
      postToApi();
    }
  }, [approved])

  useEffect(() => {
    if (!isFuneralData && shieldData) {
      if (shieldData.maritalStatus === 'Married') {
        setMemberType('Married')
        if (shieldData.childrenAmount > 0) {
          setMemberType('Married + Children')
        }
      }
      else if (shieldData.childrenAmount > 0) {
        setMemberType('Single + Children')
      }
    }

    if (!base64Data) {
      pdfPayrollDeductionScript('save');
    }

    if (base64Data !== '') {
      if (data && data.length === 0) {
        if (isFuneralData) setData(JSON.parse(localStorage.getItem('funeralUserData')));
        else setData(JSON.parse(localStorage.getItem('shieldUserData')));
      }
    }
  }, [base64Data, isFuneralData, shieldData])
  return (
    <div className="page">
      {isLoading && <div className="loaderOverlay">
        <Spinner animation="border" role="status" variant="light" />

        <div>Submitting Application, please wait...</div>
      </div>}
      {isFuneralData ? <FuneralHeader /> : <Header />}
      {approved && <div className='page'>
        <h6 style={{ fontWeight: '600', fontSize: "14px", marginTop: '25px', textAlign: 'center', color: isFuneralData && funeralData.sourceData === 'funeralApp' ? '#0b58a6' : "#BB1A1B", marginBottom: '25px' }}>Thank you very much. We will be in contact shortly</h6>

        <div style={{ textAlign: 'center' }}>
          <p style={{ marginBottom: '30px', textAlign: 'center', fontSize: "12px" }}>While you wait you are welcome to download the agreements</p>
          {isFuneralData && funeralData.sourceData === 'funeralApp' ?
            <Button variant='danger w-50' style={{ fontWeight: '600', color: "#ffffff", backgroundColor: "#0b58a6", border: "none" }} onClick={(e) => { pdfPayrollDeductionScript('download') }}>
              Download Agreement
            </Button> :
            <Button variant='danger w-50' style={{ fontWeight: '600', background: '#BB1A1B', border: 'none' }} onClick={(e) => { pdfPayrollDeductionScript('download') }}>
              Download Agreement
            </Button>}
        </div>
      </div>}
      {!approved && <div style={{ overflow: 'scroll', height: 600 }}>
        {/* <h6 style={{ fontSize: "14px", fontWeight: '600', marginTop: '25px', textAlign: 'center', color: sourceData === 'funeralApp' ? '#0b58a6' : "#BB1A1B", marginBottom: '25px' }}>You can view your application now by clicking the button below</h6> */}
        <p style={{ marginTop: '30px', marginBottom: '20px', textAlign: 'center', fontSize: "12px" }}>Please note that your application is not completed until you have clicked the "I Accept the Terms of this Agreement" button</p>
        {isFuneralData && funeralData.sourceData === 'funeralApp' ?
          <>
            {/* <Button variant='danger w-50' style={{ fontWeight: '600', color: "#ffffff", backgroundColor: "#0b58a6", border: "none" }} onClick={(e) => pdfPayrollDeductionScript('view')}>View Pdf and Terms and Conditions</Button> */}
            <Button variant='danger w-75' style={{ fontWeight: '600', color: "#ffffff", backgroundColor: "#0b58a6", border: "none", marginTop: "5vh" }} onClick={(e) => { setApproved(true); setIsLoading(true) }}>I Accept the Terms of this Agreement</Button>
          </>
          : <>
            {/* <Button variant='danger w-50' style={{ fontWeight: '600', background: '#BB1A1B', border: 'none' }} onClick={(e) => pdfPayrollDeductionScript('view')}>View Pdf and Terms and Conditions</Button> */}
            <Button variant='danger w-75' style={{ fontWeight: '600', background: '#BB1A1B', border: 'none', marginTop: "5vh" }} onClick={(e) => { setApproved(true); setIsLoading(true) }}>I Accept the Terms of this Agreement</Button>
          </>}
      </div>}
    </div>
  )
}
export default Document1
