import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router";
import Button from 'react-bootstrap/Button';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router';
import SignaturePad from 'react-signature-pad-wrapper';
import axios from 'axios';
import XMLParser from 'react-xml-parser';
import CryptoJS from 'crypto-js';
import Header from '../components/Header';
import FuneralHeader from '../components/funeralHeader';
import { Spinner } from "react-bootstrap";

const SignDocument = ({
  signature,
  setSignature,
}) => {

  const params = useParams();
  const history = useNavigate();
  const signaturePadRef = useRef(null);
  const [isLoading, setIsLoading] = useState(true);
  const [apiResult, setAPIResult] = useState();

  useEffect(() => {
    localStorage.clear();
    const key = CryptoJS.enc.Utf8.parse("364tfgry57rtfgd09s5263tehfytr1an");
    const iv1 = CryptoJS.enc.Utf8.parse("364tfgry57rtfgd09s5263tehfytr1an");
    const plainText = CryptoJS.AES.decrypt(params.id, key, {
      keySize: 16,
      iv: iv1,
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7
    });

    const xml = `<?xml version="1.0" encoding="utf-8"?>
  <soap:Envelope xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:xsd="http://www.w3.org/2001/XMLSchema" xmlns:soap="http://schemas.xmlsoap.org/soap/envelope/">
      <soap:Body>
          <ShieldPLGet xmlns="http://tempuri.org/">
              <token>lkhbnoyti76tbri7tfjyfcubyt75rtbfity</token>
              <id>${plainText.toString(CryptoJS.enc.Utf8)}</id>
          </ShieldPLGet>
      </soap:Body>
  </soap:Envelope>`

    var config = {
      method: 'post',
      url: 'https://shieldapp.msgportal.online/msgportal.asmx?op=ShieldPLGet',
      data: xml,
      headers: {
        'Content-Type': 'text/xml'
      }
    };

    axios(config)
      .then(response => {

        var xml = new XMLParser().parseFromString(response.data);
        let data = JSON.parse(xml.getElementsByTagName('ShieldPLGetResult')[0].value);
        if (data) {
          if (data.length && data.length > 0) data = { ...data[0] }
          setIsLoading(false);
          setAPIResult(data);
          if (data.page) {
            alert("Invalid Form Data");
            history('/');
          } else {
            if (data.signature) setSignature(data.signature);
            if (data.sourceData && data.sourceData === 'funeralApp') {
              localStorage.setItem('funeralUserData', JSON.stringify(data));
            } else localStorage.setItem('shieldUserData', JSON.stringify(data));

            console.log(data);
          }
        }
      })
      .catch(function (error) {
        alert("Please Enter Valid ID");
        history('/');
      });
  }, [history, params.id, setAPIResult, setSignature]);

  const saveCanvasToState = async () => {
    if (!signature) await setSignature(signaturePadRef.current.toDataURL());
    if (apiResult) {
      history('/document');
    }
  }

  const clearSignature = async () => {
    await signaturePadRef.current.clear()
  }

  const handleSave = (e) => {
    e.preventDefault();
    setSignature(signaturePadRef.current.toDataURL());
  };

  return (
    <div className="page">
      {isLoading && <div className="loaderOverlay">
        <Spinner animation="border" role="status" variant="light" />
        <div>Loading Application, please wait...</div>
      </div>}
      {apiResult && apiResult.sourceData && apiResult.sourceData === 'funeralApp' ? <FuneralHeader agentCode={apiResult.agentCode} /> : <Header />}
      <h6 style={{
        color: apiResult && apiResult.sourceData && apiResult.sourceData === 'funeralApp' ? '#0b58a6' : "#BB1A1B",
        fontWeight: '500',
        fontSize: "12px",
        marginTop: '25px',
        textAlign: 'center',
        marginBottom: '25px',
      }}>Do you agree? Sign below in agreement</h6>
      <p style={{
        margin: '0',
        textAlign: 'center',
      }}>*Please sign Here</p>
      <div style={{
        display: "grid",
        justifyContent: "center",
        textAlign: "center",
      }}>
        <SignaturePad ref={signaturePadRef} />
      </div>
      <div style={{}}>
        {apiResult && apiResult.sourceData && apiResult.sourceData === 'funeralApp' ?
          <>
            <div className="text-center">
              <Button variant='danger' onClick={clearSignature} style={{ textAlign: 'center', marginTop: '2vh', backgroundColor: "#ffffff", color: "#97a2ac", border: "2px solid" }} >Clear</Button>
              <Button variant='danger' onClick={handleSave} style={{ textAlign: 'center', marginTop: '2vh', marginLeft: '10px', backgroundColor: "#ffffff", color: "#0b58a6", border: "2px solid" }} >Save</Button>
            </div>
            <Link to='/' className="btn btn-secondary w-25" style={{ fontWeight: '600', backgroundColor: "#ffffff", color: "#97a2ac", border: "2px solid", float: 'left', marginTop: '5vw', marginLeft: '20vw' }}>BACK
            </Link>
            {signature && <Button variant='danger w-25' onClick={saveCanvasToState} style={{ fontWeight: '600', color: "#0b58a6", backgroundColor: "#ffffff", border: "2px solid", float: 'right', marginTop: '5vw', marginRight: '20vw' }} >Submit</Button>}
          </> : <>
            <div className="text-center">
              <Button variant='danger' onClick={clearSignature} style={{ marginTop: '2vh', backgroundColor: "#ffffff", color: "#97a2ac", border: "2px solid" }} >Clear</Button>
              <Button variant='danger' onClick={handleSave} style={{ textAlign: 'center', marginTop: '2vh', marginLeft: '10px', backgroundColor: "BB1A1B", border: "2px solid" }} >Save</Button>
            </div>
            <Link to='/' className="btn btn-secondary w-25" style={{ fontWeight: '600', background: '#D0D0D0', border: 'none', float: 'left', marginTop: '5vw', marginLeft: '20vw' }}>BACK
            </Link>
            {signature && <Button variant='danger w-25' onClick={saveCanvasToState} style={{ fontWeight: '600', background: '#BB1A1B', border: 'none', float: 'right', marginTop: '5vw', marginRight: '20vw' }} >Submit</Button>}
          </>}
      </div>
    </div>
  )
}
export default SignDocument