// imports
import { useEffect, useState } from 'react'
import MFLogo from '../assets/images/MF_Financial_Services.svg';
import ShieldLogo from '../assets/images/ShieldLife.svg'
// styles

export default function FuneralHeader(props) {
    const [code, setCode] = useState();
    const [funeralData, setFuneralData] = useState();

    useEffect(() => {
        if (props.agentCode) setCode(props.agentCode.toLowerCase());
        if (localStorage.getItem('funeralUserData')) setFuneralData(JSON.parse(localStorage.getItem('funeralUserData')));
    }, [props.agentCode])

    return (
        <div style={{ marginTop: '5vh' }} className='Header'>
            <img src={ShieldLogo} alt='MF Financial Services Logo' style={{ padding: '16px' }} />
            {code !== 'sll' && code !== 'tsll' &&
                <img src={MFLogo} alt='MF Financial Services Logo' style={{ padding: '16px' }} />
            }
            <h2 style={{ color: '#0b58a6', fontWeight: '700', marginTop: '25px' }}>My Family Funeral {funeralData && funeralData?.csFuneralApp === "Yes" ? 'Plan' : ''}</h2>
        </div>
    )
}